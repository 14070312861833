import { CELL_WIDTH, gridMiddleX, gridMiddleY } from "../Constants";
import { generateGenericGrid } from "../Helpers";

export default class RoomModel {
  constructor(props) {
    if (props) {
      this.forScreenShot = props.forScreenShot;
    }
  }

  generateRoomStart = (props) => {
    let bodyHeight = props.roomLength * CELL_WIDTH;
    let bodyWidth = props.roomWidth * CELL_WIDTH;

    let startX = gridMiddleX - CELL_WIDTH * Math.floor(props.roomWidth / 2);
    let startY = gridMiddleY - CELL_WIDTH * Math.floor(props.roomLength / 2);

    let roomWidthForExport;
    let roomHeightForExport;
    if (this.forScreenShot) {
      const padding = 1;

      roomWidthForExport = props.roomWidth * CELL_WIDTH;
      roomHeightForExport = props.roomLength * CELL_WIDTH;

      startX = padding * CELL_WIDTH;
      startY = padding * CELL_WIDTH;

      if (roomWidthForExport / roomHeightForExport !== 1) {
        if (roomWidthForExport > roomHeightForExport) {
          startY = (roomWidthForExport - roomHeightForExport) / 2;
          roomHeightForExport = roomWidthForExport;
        } else {
          startX = (roomHeightForExport - roomWidthForExport) / 2;
          roomWidthForExport = roomHeightForExport;
        }
      }
      roomWidthForExport += padding * CELL_WIDTH * 2;
      roomHeightForExport += padding * CELL_WIDTH * 2;
    }
    const roomGrid = props.forReadyOnly
      ? generateGenericGrid({
          roomStartX: startX,
          roomStartY: startY,
          cellCountX: props.roomWidth,
          cellCountY: props.roomLength,
        })
      : [];

    return {
      startX,
      startY,
      bodyHeight,
      bodyWidth,
      roomWidthForExport,
      roomHeightForExport,
      roomGrid,
    };
  };

  generateAux = (props, state) => {
    let topLine;
    let rightLine;
    const rectLineThick = 3;

    const textWidth = CELL_WIDTH * 2;

    if (Number(props.roomWidth)) {
      const topLineHalfWidth = Math.floor((state.bodyWidth - textWidth) / 2);

      const topLineY = state.startY - CELL_WIDTH * 1;

      topLine = {
        half1: {
          width: topLineHalfWidth,
          height: rectLineThick,
          x: state.startX,
          y: topLineY,
        },
        half2: {
          width: topLineHalfWidth,
          height: rectLineThick,
          x: state.startX + topLineHalfWidth + textWidth,
          y: topLineY,
        },
        text: {
          width: textWidth,
          height: CELL_WIDTH,
          x: state.startX + topLineHalfWidth,
          y: topLineY,
          label: `${props.roomWidthLabel}`,
          lineHeight: 0.2,
        },
        shadowBlur: 6,
        shadowOpacity: 0.16,
        shadowOffsetX: 0,
        shadowOffsetY: 3,
      };
    }
    if (Number(props.roomLength)) {
      const rightLineHeight = Math.floor((state.bodyHeight - textWidth) / 2);

      const rightLineX = state.startX + state.bodyWidth + CELL_WIDTH * 1;

      rightLine = {
        half1: {
          width: rectLineThick,
          height: rightLineHeight,
          x: rightLineX,
          y: state.startY,
        },
        half2: {
          width: rectLineThick,
          height: rightLineHeight,
          x: rightLineX,
          y: state.startY + rightLineHeight + textWidth,
        },
        text: {
          width: textWidth,
          height: textWidth,
          x: rightLineX - 50,
          y: state.startY + rightLineHeight + 50,
          label: `${props.roomLengthLabel}`,
          lineHeight: 0.2,
        },
      };
    }
    return {
      topLine,
      rightLine,
    };
  };

  canvasType = (pathName, platformDrainageDirection, growAreaId) => {    
    let allowTrays = Number(growAreaId) !== 1;
    const allowPlatform =
      !allowTrays &&
      platformDrainageDirection &&
      platformDrainageDirection !== "none" 
      //&&
      // /(\/kitbuilder\/platform-layout)|(\/kitbuilder\/zoning-style)/.test(
      //   pathName
      // );
    const platFormDraggable =
      allowPlatform 
      //&& /\/kitbuilder\/platform-layout/.test(pathName);

    return {
      allowTrays,
      allowPlatform,
      platFormDraggable,
    };
  };
}
