// @flow
import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { NavigationMenu } from "../../layout/NavigationMenu/NavigationMenu";
import KitRoom from "./KitRoom/KitRoomForm";
import WithNavigation from "../../components/WithNavigation/WithNavigation";
import GrowArea from "./KitRoom/KitGrowArea";
import TraySize from "./KitRoom/KitTraySize";
import ContainerOrMedium from "./KitRoom/KitContainer";
import Irrigation from "./KitRoom/KitIrrigation";
import Zones from "./KitRoom/KitRoomZones";
import PlatformLayout from "./KitRoom/KitPlatformLayout";
import RecommendedBuilds from "./KitRoom/KitRecommended";
import CartSummary from "./KitRoom/KitCart";
import "./KitBuilder.scss";
import _ from "lodash";

// import steps icons
import { ReactComponent as IconRoomDimensions } from "./../../../assets/svg/room-dimensions-new.svg";
import { ReactComponent as IconGrowAreaStyle } from "./../../../assets/svg/grow-area-style-new.svg";
import { ReactComponent as IconTraySize } from "./../../../assets/svg/tray-size-new.svg";
import { ReactComponent as IconContainerMedium } from "./../../../assets/svg/container-medium-new.svg";
import { ReactComponent as IconIrrigationStyle } from "./../../../assets/svg/irrigation-style-new.svg";
import { ReactComponent as IconRecommendedBuilds } from "./../../../assets/svg/recommended-builds-new.svg";

import KitRoomlayout from "./KitRoom/KitRoomLayout";
import * as roomFunctions from "./KitRoom/KitRoomFunctions";
import { layoutActions } from "../../redux/actionFunctions/layoutActions";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../layout/components/Header/Header";
import * as utilFunctions from "./../../utils/utilFunctions";

import SaveRoomBuildPopup from "./SaveRoomBuildPopup/SaveRoomBuildPopup";

import { authenticaionActions } from "../../redux/actionFunctions/authenticationActions";

import { useApolloClient } from "@apollo/client";
import cartFunctions from "./KitRoom/KitCartFunctions";
import Kit3DCanvas from "./KitRoom/KitCart/Kit3DCanvas";
import Loading3DPopup from "./Loading3DPopup/Loading3DPopup";

import imgDefaultPerArea from "./../../../assets/image/default-per-area.png";
import imgDefaultPot from "./../../../assets/image/default-pot.png";
import imgDefaultIrrigation from "./../../../assets/image/default-irrigation.jpg";
import Axios from "axios";

let roomIdInitial = "";

let centerCanvasTimeout = 0;
const STEPS = [
  {
    value: "Room Dimensions",
    label: "ROOM DIMENSIONS",
    key: "KitBuilder Room Dimensions",
    path: "/kitbuilder/room-dimensions",
    icon: <IconRoomDimensions />,
    info: "",
    belongsTo: [0, 1],
  },
  {
    value: "Grow Area Style",
    label: "GROW AREA STYLE",
    key: "KitBuilder Grow Area Style",
    path: "/kitbuilder/grow-area",
    icon: <IconGrowAreaStyle />,
    info: "",
    belongsTo: [0, 1],
  },
  {
    value: "Tray Size",
    label: "TRAY SIZE",
    key: "KitBuilder Tray Size",
    path: "/kitbuilder/tray-size",
    className: " tray-size-header",
    icon: <IconTraySize />,
    info: "",
    belongsTo: [0],
  },
  {
    value: "Container or Medium",
    label: "POT OR MEDIUM",
    key: "KitBuilder Container or Medium",
    path: "/kitbuilder/container-or-medium",
    className: " pot-or-medium-header",
    icon: <IconContainerMedium />,
    info: "",
    belongsTo: [0, 1],
  },
  {
    value: "Platform Layout",
    label: "PLATFORM LAYOUT",
    key: "KitBuilder Platform Layout",
    path: "/kitbuilder/platform-layout",
    icon: <IconTraySize />,
    info: "",
    belongsTo: [1],
  },
  {
    value: "Zoning Style",
    className: " zonning-style-header",
    label: "ZONING STYLE",
    key: "KitBuilder Zoning Style",
    path: "/kitbuilder/zoning-style",
    icon: <IconIrrigationStyle />,
    info: "",
    belongsTo: [1],
  },
  {
    value: "Irrigation Style",
    label: "IRRIGATION STYLE",
    className: " irigigation-nav-item-cl",
    key: "KitBuilder Irrigation Style",
    path: "/kitbuilder/irrigation-style",
    icon: <IconIrrigationStyle />,

    info: "",
    belongsTo: [0],
  },
  {
    value: "Recommended Builds",
    className: " recommended-builds-header",
    label: (
      <div className="recommended-build-label">
        <span>RECOMMENDED</span>
        <span>BUILDS</span>
      </div>
    ),
    key: "KitBuilder Recommended Builds",
    path: "/kitbuilder/recommended-builds",
    icon: <IconRecommendedBuilds />,
    info: "",
    belongsTo: [0, 1],
  },
  {
    value: "Cart Summary",
    label: "Cart Summary",
    key: "KitBuilder Cart Summary",
    path: "/kitbuilder/cart-summary",
    belongsTo: [0, 1],
  },
  {
    value: "Nutrient Calculator",
    label: "Nutrient Calculator",
    key: "KitBuilder Nutrient Calculator",
    path: "/kitbuilder/nutrient-calculator",
    belongsTo: [0, 1],
  },
];

const wCodeTrayDist = roomFunctions.trayWarnings.TRAY_DISTANCEING;

const emptyBuildComponentsState = {
  containers: [],
  irrigation_style: [],
  automation: [],
};
let buildComponentsOriginal = JSON.parse(
  JSON.stringify(emptyBuildComponentsState)
);

const emptyThirdPartyPartsState = {
  pump: [],
  controller: [],
  alternative_controller: [],
  expansion: [],
  regulator: [],
};
let thirdPartyPartsOriginal = JSON.parse(
  JSON.stringify(emptyThirdPartyPartsState)
);

let defaultRoomName = `Untitled1`;
let defaultRoomNameMax = 1;

const getQuery = () => {
  if (typeof window !== "undefined") {
    return new URLSearchParams(window.location.search);
  }
  return new URLSearchParams();
};

const getQueryStringVal = (key) => {
  return getQuery().get(key);
};

const useQueryParam = (key, defaultVal) => {
  const [query, setQuery] = React.useState(
    getQueryStringVal(key) || defaultVal
  );

  const updateUrl = (newVal) => {
    setQuery(newVal);

    const query = getQuery();

    if (newVal.trim() !== "") {
      query.set(key, newVal);
    } else {
      query.delete(key);
    }

    // This check is necessary if using the hook with Gatsby
    if (typeof window !== "undefined") {
      const { protocol, pathname, host } = window.location;
      const newUrl = `${protocol}//${host}${pathname}?${query.toString()}${
        window.location.hash
      }`;
      window.history.pushState({}, "", newUrl);
    }
  };

  return [query, updateUrl];
};

export function KitBuilder(props) {
  const dispatch = useDispatch();
  const client = useApolloClient();
  const { clientCartId, clientId } = useSelector(({ auth }) => ({
    clientCartId: auth?.user?.clientCartId,
    clientId: auth?.user?.clientId,
  }));

  //   const [search, setSearch] = useQueryParam("room_id", "");

  const [state, setState] = React.useState({
    ...roomFunctions.generateInitialState(),
    ...(props.location.state?.detail?.selectedRoom?.id ||
    props.location.state?.detail?.selectedRoom?.needsSaving
      ? props.location.state.detail.state
      : roomFunctions.defaultStateKitBuilder_localStorage()),
  });

  const [steps, setSteps] = React.useState(
    _.filter(STEPS, (step) => {
      return step.belongsTo.includes(state.growArea.id || 0);
    })
  );

  const [selectedRoom, setSelectedRoom] = React.useState({
    roomData: {},
    ...roomFunctions.defaultStateSelectedRoom_DEV(),
    ...(props.location.state?.detail?.selectedRoom?.id ||
    props.location.state?.detail?.selectedRoom?.needsSaving
      ? props.location.state.detail.selectedRoom
      : {}),
  });

  const [_routingState, _setRoutingState] = React.useState({
    selectedIndex: 0,
    hideCanvas: false,
    hideNavBar: false,
  });

  const routingRef = React.useRef(_routingState);
  const setRoutingState = (_new) => {
    routingRef.current = _new;
    _setRoutingState({
      ...routingRef.current,
      ..._new,
    });
  };
  const routingState = routingRef.current;

  const [disabledWarnings, setDisabledWarnings] = React.useState({
    [wCodeTrayDist]: false,
  });
  const [buildComponents, setBuildComponents] = React.useState({
    containers: [],
    irrigation_style: [],
    automation: [],
  });
  const [thirdPartyParts, setThirdPartyParts] = React.useState({
    pump: [],
    controller: [],
    alternative_controller: [],
    expansion: [],
    regulator: [],
  });
  const [config, setConfig] = React.useState({
    configData: null,
    configDataLoadedKey: null,
  });

  function setCurrentStep(stepValue) {
    const selectedIndex = steps.findIndex((item) => item.value === stepValue);
    const hideNavBar = selectedIndex > 5;
    const hideCanvas = selectedIndex > 5;

    setRoutingState({
      ...routingState,
      hideNavBar,
      hideCanvas,
      load3DHidden: false,
      selectedIndex,
    });
    if (hideCanvas) {
      getBuildComponents();
      getThirdPartyComponents();
    }
    /**
     * when showing 3D canvas that was hidden display a popup if loading wasn't finished in background
     */
    if (hideCanvas && window.KitBuilder3D_loaded !== true) {
      openPopupLoading3D();
    } else {
      layoutActions(dispatch).popPopupStack({
        key: "LOADING_3D_POPUP",
      });
    }
  }

  function setInfoStep(stepValue, infoValue, infoExtra = "") {
    const selectedIndex = steps.findIndex((item) => item.value === stepValue);
    if (selectedIndex != "" || selectedIndex != null) {
      steps[selectedIndex].info = infoValue;
      if (infoExtra != "") {
        steps[selectedIndex].extra = infoExtra;
      }
    }
  }

  function openPopup(payload) {
    let key;
    if (payload.error_code) {
      key = payload.error_code;
    } else {
      key = Date.now();
    }
    layoutActions(dispatch).pushPopupStack({
      key,
      title: "",
      buttoOkText: "Ok",
      callbackNo: (closePopup) => {
        closePopup();
      },
      ...payload,
    });
  }

  function goToPage(path) {
    const roomSizeError = roomFunctions.roomSizeError(state.roomSize);
    if (roomSizeError) {
      if (
        props.history.location.pathname.indexOf(
          "/kitbuilder/room-dimensions"
        ) === -1
      ) {
        props.history.push({
          pathname: "/kitbuilder/room-dimensions",
          search: props.history.location.search,
        });
      } else {
        openPopup({
          content: roomSizeError.message,
        });
      }
    } else {
      props.history.push({
        pathname: path,
        search: props.history.location.search,
      });
    }
  }

  function ignoreTraySpacing() {
    setDisabledWarnings({
      [wCodeTrayDist]: true,
    });
  }

  function setRoomSize(roomSize) {
    if (roomSize.measureSystem === "FT") {
      roomSize.roomWidth = roomSize.roomWidthVal;
      roomSize.roomLength = roomSize.roomLengthVal;
    } else {
      roomSize.roomWidth = roomFunctions.mToFt(roomSize.roomWidthVal);
      roomSize.roomLength = roomFunctions.mToFt(roomSize.roomLengthVal);
    }
    roomSize.roomWidth = utilFunctions.number2Digits(roomSize.roomWidth);
    roomSize.roomLength = utilFunctions.number2Digits(roomSize.roomLength);
    setState({
      ...state,
      roomSize: roomSize,
      roomSizeKey: Date.now(),
    });
  }

  function changeMeasureSystem(type) {
    if (type == state.roomSize.measureSystem) {
      return false;
    }

    let newRoomWidthVal = state.roomSize.roomWidthVal;
    let newRoomLengthVal = state.roomSize.roomLengthVal;

    if (type == "M") {
      if (state.roomSize.roomWidthVal != "") {
        newRoomWidthVal = roomFunctions.ftToM(state.roomSize.roomWidthVal);
      }

      if (state.roomSize.roomLengthVal != "") {
        newRoomLengthVal = roomFunctions.ftToM(state.roomSize.roomLengthVal);
      }
    } else if (type == "FT") {
      if (state.roomSize.roomWidthVal != "") {
        newRoomWidthVal = state.roomSize.roomWidth;
      }

      if (state.roomSize.roomLengthVal != "") {
        newRoomLengthVal = state.roomSize.roomLength;
      }
    }
    newRoomWidthVal = utilFunctions.number2Digits(newRoomWidthVal);
    newRoomLengthVal = utilFunctions.number2Digits(newRoomLengthVal);

    setState({
      ...state,
      roomSize: {
        ...state.roomSize,
        measureSystem: type,
        roomWidthVal: newRoomWidthVal,
        roomLengthVal: newRoomLengthVal,
      },
    });
  }

  function getBuildComponents() {
    fetch(
      `${window.BASE_URL}rbapi/getBuildComponents.php?customer_id=${clientId}&room_id=${selectedRoom?.id}`
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        const value = {
          ...buildComponents,
          ...data,
        };
        buildComponentsOriginal = JSON.parse(JSON.stringify(value));
        setBuildComponents(value);
      })
      .catch((err) => {
        console.log("Error while fetching build components", err);
      });
  }

  function getThirdPartyComponents() {
    fetch(`${window.BASE_URL}rbapi/get3rdParty.php?room_id=${selectedRoom?.id}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        const value = {
          ...thirdPartyParts,
          ...data,
        };
        thirdPartyPartsOriginal = JSON.parse(JSON.stringify(value));
        setThirdPartyParts(value);
      })
      .catch((err) => {
        console.log("Error while fetching third party parts");
        console.log(err);
      });
  }

  function getBuildComponents2(id_room) {
    return fetch(
      `${window.BASE_URL}rbapi/getBuildComponents.php?customer_id=${clientId}&room_id=${id_room}`
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return new Promise((resolve, reject) => reject({}));
      })
      .then((data) => {
        const value = {
          ...data,
        };
        return new Promise((resolve) => resolve(value));
      })
      .catch((err) => {
        console.log("Error while fetching build components", err);
        return new Promise((resolve, reject) => reject({}));
      });
  }

  function getThirdPartyComponents2(id_room) {
    return fetch(`${window.BASE_URL}rbapi/get3rdParty.php?room_id=${id_room}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        return new Promise((resolve, reject) => reject({}));
      })
      .then((data) => {
        return new Promise((resolve) => resolve(data));
      })
      .catch((err) => {
        console.log("Error while fetching third party parts");
        console.log(err);
        return new Promise((resolve, reject) => reject({}));
      });
  }

  function resetBuildComponents() {
    setBuildComponents(JSON.parse(JSON.stringify(buildComponentsOriginal)));
  }

  function resetThirdPartyParts() {
    setThirdPartyParts(JSON.parse(JSON.stringify(thirdPartyPartsOriginal)));
  }

  function setBuildLine(property, index, value) {
    if (!Array.isArray(buildComponents[property])) {
      return;
    }
    const bComponent = buildComponents[property];
    if (bComponent.length <= index) {
      return;
    }
    bComponent[index].qty = value;
    setBuildComponents({
      ...buildComponents,
      changed: true,
      [property]: bComponent,
    });
  }

  function calculateNumOfPlants() {
    let fourAreas = 0;
    state.trays.forEach((item) => {
      const val1 = Number(item.widthVal) || 0;
      const val2 = Number(item.lengthVal) || 0;
      const maxDimension = val1 > val2 ? val1 : val2;
      if (maxDimension >= 4) {
        fourAreas += Math.floor(maxDimension / 4);
      }
    });

    let plant = Number(state.calculatorState.plant) || 0;
    /**
     * retrieve number from label (fix cases where value column was omited from db fsr and room was wrongly saved)
     */
    if (plant <= 0) {
      if (typeof state.calculatorState.plantLabel === "string") {
        const labelMatch = state.calculatorState.plantLabel.match(/([\d]+)/);
        if (
          Array.isArray(labelMatch) &&
          labelMatch.length > 0 &&
          labelMatch[0] &&
          Number(labelMatch[0])
        ) {
          plant = Number(labelMatch[0]);
        } else {
          plant = 1;
        }
      } else {
        plant = 1;
      }
    }

    return fourAreas * (Number(plant) || 1);
  }

  function saveRoomAs() {
    // return;

    // layoutActions(dispatch).pushPopupStack({
    //   key: "SAVE_ROOM_POPUP",
    //   title: "",
    //   callbackNo: (closePopup) => {
    //     closePopup();
    //   },
    //   content: {
    //     title: "",
    //     text: "",
    //     node: (
    //       <SaveRoomPopup
    //         closePopup={() =>
    //           layoutActions(dispatch).popPopupStack({ key: "SAVE_ROOM_POPUP" })
    //         }
    //         rooms={state.rooms}
    //         saveRoom={(room_description) => saveRoom(room_description)}
    //       />
    //     ),
    //   },
    // });
    saveYourBuild();
  }

  function saveYourBuild(multiStep) {
    if (multiStep) {
      setRoutingState({ ...routingState, load3DHidden: true });
    }

    const growAreaId = Number(state?.growArea?.id) || 0;
    /**
     * @typedef {Object} IPlatform
     * @property {String} platformDrainageDirection
     * @property {Number} platformLinkLength
     * @property {Number} platformNumRows
     * @property {Number} platformPlatformsPerRow
     * @property {Number} platformSubzoneWidth
     * @property {Number} platformWalkwayWidth
     * @property {Number} platformXcoord
     * @property {Number} platformYcoord
     */
    const platformDataAux = state.platformData;
    const platformData = {
      platformLinkLength: `${Number(platformDataAux.platformLinkLength) || 1} ${
        state.roomSize.measureSystem || "FT"
      }`,
      platformNumRows: Number(platformDataAux.platformNumRows) || 1,
      platformPlatformsPerRow:
        Number(platformDataAux.platformPlatformsPerRow) || 1,
      platformSubzoneWidth: `${
        Number(platformDataAux.platformSubzoneWidth) || 1
      } ${state.roomSize.measureSystem || "FT"}`,
      platformWalkwayWidth: Number(platformDataAux.platformWalkwayWidth) || 1,
      platformXcoord: Number(platformDataAux.platformXcoord) || 1,
      platformYcoord: Number(platformDataAux.platformYcoord) || 1,
    };

    const totalPlantFlow2 =
      platformData.platformPlatformsPerRow * platformData.platformNumRows;

    const roomDetails = {
      roomDimensionLabel: `${state.roomSize.roomWidthVal || 1} X ${
        state.roomSize.roomLength || 1
      } ${state.roomSize.measureSystem || "FT"}`,
      totalPlants: calculateNumOfPlants(),
      trayListLabel: `${state.trays.reduce((final, current) => {
        if (typeof current.label === "string") {
          const label = current.label.replace(/^(tray\s)/i, "");
          return `${final}${final ? "," : ""} ${label}: ${current.widthVal} X ${
            current.lengthVal
          }`;
        }
        return final;
      }, "")}`,
      trayListCount: state.trays.length || 1,
      plantPerAreaLabel: roomFunctions.generateDisplayDensity({
        density: state.calculatorState.plantLabel || "",
      }),
      plantPerAreaImage:
        state.calculatorState.plantItemImage || imgDefaultPerArea,
      irrigationLabel: state.irrigationStyle.label,
      irrigationImage: state.irrigationStyle.image || imgDefaultIrrigation,
      potLabel: state.chosenPot.label || "",
      potImage: state.chosenPot.image || imgDefaultPot,
      growAreaId,
      platformData,
      totalPlantFlow2,
    };
    layoutActions(dispatch).pushPopupStack({
      key: "SAVE_ROOM_BUILD_POPUP",
      title: "",
      callbackNo: (closePopup) => {
        closePopup();
      },
      content: {
        title: "",
        text: "",
        node: (
          <SaveRoomBuildPopup
            closePopup={() => {
              layoutActions(dispatch).popPopupStack({
                key: "SAVE_ROOM_BUILD_POPUP",
              });
            }}
            editPopup={(payload) => {
              layoutActions(dispatch).editPopupStack({
                key: "SAVE_ROOM_BUILD_POPUP",
                ...payload,
              });
            }}
            stop3DHidden={() => {
              window.KitBuilder3D_loaded = true;
              utilFunctions.triggerCustomEvent("kit-builder-c-e-3d-loaded", {});
              setRoutingState({
                ...routingState,
                load3DHidden: false,
              });
            }}
            goToNextPage={() => {
              props.history.push({
                pathname: "/kitbuilder/cart-summary",
                search: props.history.location.search,
              });
            }}
            selectedRoom={selectedRoom}
            rooms={state.rooms}
            clientId={clientId}
            saveRoom={saveRoom}
            roomDetails={roomDetails}
            multiStep={multiStep}
            defaultRoomName={defaultRoomName}
          />
        ),
      },
      className: "wrapper-save-room-popup-rm-your-build",
    });
  }

  function openPopupLoading3D() {
    if (window.KitBuilder3D_loaded !== true) {
      layoutActions(dispatch).pushPopupStack({
        key: "LOADING_3D_POPUP",
        title: "",
        priority: -2,
        callbackNo: (closePopup) => {
          closePopup();
        },
        content: {
          title: "",
          text: "",
          node: (
            <Loading3DPopup
              closePopup={() => {
                layoutActions(dispatch).popPopupStack({
                  key: "LOADING_3D_POPUP",
                });
              }}
            />
          ),
        },
        className: "wrapper-loading-3d-popup-rm",
      });
    }
  }

  function saveRoom(room_description = "", room_name = "", newRoomSelected) {
    let room_id = "";
    let isNew = true;

    if (!newRoomSelected) {
     
    }

    if (newRoomSelected) {
      room_id = newRoomSelected.id;
      isNew = false;
    }
    const numOfPlants = calculateNumOfPlants();
    const nutrientResults = config.configData
      ? roomFunctions.culculateNutrientResults({
          flush: 1,
          plant: numOfPlants,
          volume: state.calculatorState.volume,
          week: 0,
          addCycles: 1,
          products: config.configData?.products,
          customQuantities: {},
          ignoreQuantity: false,
        })
      : {
          allTotal: 0,
          allProductForCart: [],
        };
    const nutrients = Object.entries(nutrientResults.allProductForCart).reduce(
      (previous, [type, cItems]) => {
        cItems.forEach((line) => {
          if (line.quantity) {
            previous.push({
              sku: line.sku,
              quantity: line.quantity,
              bag: line.type,
              productType: type,
            });
          }
        });
        return previous;
      },
      []
    );

    const stateCopy = JSON.parse(JSON.stringify(state));
    const bCmpResults = roomFunctions.calculateComponentsResults({
      containers: buildComponentsOriginal.containers || [],
      irrigation_style: buildComponentsOriginal.irrigation_style || [],
      automation: buildComponentsOriginal.automation || [],
      plant: numOfPlants,
    });
    const saveRoomParams = {
      trays: state.trays,
      chosenPot: state.chosenPot,
      irrigationStyle: state.irrigationStyle,
      density: state.calculatorState.plantLabel,
      irrigationImage: state.irrigationStyle.image,
      densityImage: state.calculatorState.plantItemImage,
      numOfPlants,
      plumbing: state.plumbing,
      mainLinePipe: state.mainLinePipe,
      dripperStyle: state.dripperStyle,
      roomSize: state.roomSize,
      cart_id: clientCartId,
      room_description: room_description.replace(/\r\n/g, ""),
      room_name,
      nutrients,
      room_id,
      build_components: bCmpResults.allProducts,
      thirdPartyParts: thirdPartyParts,
      /**
       * platform - start
       */
      platformDrainageDirection:
        stateCopy.platformData.platformDrainageDirection,
      platformXcoord: stateCopy.platformData.platformXcoord,
      platformYcoord: stateCopy.platformData.platformYcoord,
      platformNumRows: stateCopy.platformData.platformNumRows,
      platformPlatformsPerRow: stateCopy.platformData.platformPlatformsPerRow,
      platformLinkLength: stateCopy.platformData.platformLinkLength,
      platformWalkwayWidth: stateCopy.platformData.platformWalkwayWidth,
      platformSubzoneWidth: stateCopy.platformData.platformSubzoneWidth,
      original_owner_id: selectedRoom?.original_owner_id,
      /**
       * platform - start
       */
      _reactObjs: {
        roomSize: stateCopy.roomSize,
        trays: stateCopy.trays,
        calculatorState: { ...stateCopy.calculatorState },
        plumbing: stateCopy.plumbing,
        mainLinePipe: stateCopy.mainLinePipe,
        dripperStyle: stateCopy.dripperStyle,
        dripperPerPlant: stateCopy.dripperPerPlant,
        growArea: stateCopy.growArea,
        chosenPot: stateCopy.chosenPot,
        irrigationStyle: stateCopy.irrigationStyle,
        platformData: stateCopy.platformData,
        wateredZone: stateCopy.wateredZone,
      },
    };
    return cartFunctions(client)
      .saveRoom(saveRoomParams)
      .then(({ data }) => {
        const id_room = data?.[0]?.id;
        if (id_room) {
          setSelectedRoom({ ...data[0] });
          if (typeof data[0].room_name === "string") {
            const element = data[0];
            let numberMatch =
              Number(element.room_name.replace(/[^\d]/g, "")) || 0;
            numberMatch = ["", Number(element.id)];

            if (Array.isArray(numberMatch) && numberMatch.length) {
              let num = Number(numberMatch[numberMatch.length - 1]) || 0;
              if (num >= defaultRoomNameMax) {
                defaultRoomNameMax = num + 1;
              }
            }
            defaultRoomName = `Untitled${defaultRoomNameMax}`;
            defaultRoomNameMax = defaultRoomNameMax;
          }
          if (isNew) {
            let roomcomm = null;
            let thirdPartyData = null;
            getThirdPartyComponents2(id_room)
              .then((data) => {
                thirdPartyData = data;
                getBuildComponents2(id_room)
                  .then((data) => {
                    roomcomm = data;
                    const bCmpResults = roomFunctions.calculateComponentsResults(
                      {
                        containers: roomcomm.containers || [],
                        irrigation_style: roomcomm.irrigation_style || [],
                        automation: roomcomm.automation || [],
                        plant: calculateNumOfPlants(),
                      }
                    );
                    cartFunctions(client).saveRoom({
                      ...saveRoomParams,
                      room_id: id_room,
                      build_components: bCmpResults.allProducts,
                      thirdPartyParts: thirdPartyData,
                    });
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          }
          return new Promise((resolve) => resolve(data[0]));
        }
        return new Promise((resolve, reject) => reject(data));
      })
      .catch((error) => {
        console.log(error);
        if (
          Array.isArray(error?.graphQLErrors) &&
          error.graphQLErrors.length &&
          error.graphQLErrors.some(
            (item) => item?.extensions?.category === "graphql-authorization"
          ) &&
          window.isLoggedWithUserNameAndPasswordRb !== true //avoid a unauthenticated call done too early after login
        ) {
          authenticaionActions(dispatch).logout(client);
        }
        return new Promise((resolve, reject) => reject(error));
      });
  }

  function setUpRoomDefaultName() {
    roomFunctions
      .getRooms(clientId)
      .then((data) => {
        defaultRoomNameMax = 1;
        data.forEach((element) => {
          if (typeof element.room_name === "string") {
            let numberMatch =
              Number(element.room_name.replace(/[^\d]/g, "")) || 0;
            numberMatch = ["", Number(element.id)];

            if (Array.isArray(numberMatch) && numberMatch.length) {
              let num = Number(numberMatch[numberMatch.length - 1]) || 0;
              if (num >= defaultRoomNameMax) {
                defaultRoomNameMax = num + 1;
              }
            }
          }
        });
        defaultRoomName = `Untitled${defaultRoomNameMax}`;
        defaultRoomNameMax = defaultRoomNameMax;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function getMatrix() {
    Axios(`${window.BASE_URL}rbapi/getMatrix.php`)
      .then((response) => {
        if (response.status == 200 && response.data.grow_areas) {
          window.kitBuilderMatrix = response.data;
        } else {
          window.kitBuilderMatrix = null;
        }
      })
      .catch(() => {
        window.kitBuilderMatrix = null;
      });
  }

  function onChangeGrowArea(growArea) {
    let otherProperties = {};

    if (state.growArea.id !== growArea.id) {
      const initialState = roomFunctions.generateInitialState();
      otherProperties = {
        plumbing: initialState.plumbing,
        mainLinePipe: initialState.mainLinePipe,
        dripperStyle: initialState.dripperStyle,
        dripperPerPlant: initialState.dripperPerPlant,
        growArea: initialState.growArea,
        trays: initialState.trays,
        zones: initialState.zones,
        chosenPot: initialState.chosenPot,
      };
    }

    setState({
      ...state,
      ...otherProperties,
      growArea: growArea,
    });
  }

  function shareRoom() {
    layoutActions(dispatch).openShareRoomPopup(JSON.stringify(selectedRoom));
  }

  React.useEffect(() => {
    clearTimeout(centerCanvasTimeout);
    if (state.roomSizeKey) {
      centerCanvasTimeout = setTimeout(() => {
        const centerBtn = document.getElementById("center-canvas-btn-id");
        if (centerBtn) {
          centerBtn.click();
        }
      }, 200);
    }
  }, [state.roomSizeKey]);
  React.useEffect(() => {
    const roomSizeError = roomFunctions.roomSizeError(state.roomSize);
    if (
      roomSizeError &&
      props.history.location.pathname.indexOf("/kitbuilder/room-dimensions") ===
        -1
    ) {
      props.history.push({
        pathname: "/kitbuilder/room-dimensions",
        search: props.history.location.search,
      });
    }

    cartFunctions()
      .getConfig()
      .then((configData) => {
        setConfig({ configData, configDataLoadedKey: Date.now() });
      })
      .catch(() => {});
    return () => {
      /**
       * clean some of the popups when loging out unexpected
       */
      layoutActions(dispatch).popPopupStack({
        key: "LOADING_3D_POPUP",
      });
      layoutActions(dispatch).popPopupStack({
        key: "SAVE_ROOM_BUILD_POPUP",
      });
    };
  }, []);

  React.useLayoutEffect(() => {
    window.kitBuilderMatrix = null;

    if (props.location.state?.detail?.stepPath === "cart-summary") {
      props.history.replace({
        pathname: "/kitbuilder/cart-summary",
        search: props.history.location.search,
      });
    } else {
      /**
       * client is not the owner of the room
       */
      if (props.location.state?.detail?.needs_saving) {
        saveYourBuild(true);
      }
    }
    setUpRoomDefaultName();

    window.addEventListener("open-loader-3d-canvas", openPopupLoading3D);
    return () => {
      window.removeEventListener("open-loader-3d-canvas", openPopupLoading3D);
    };
  }, []);

  React.useEffect(() => {
    getMatrix();
  }, [props.location.pathname]);

  const errorOverlaping = roomFunctions.getErrorTrayOverlapping(state.trays);
  const warningTrayDist = disabledWarnings[wCodeTrayDist]
    ? undefined
    : roomFunctions.getWarningTrayDistancing(
        state.trays,
        state.roomSize.roomWidth,
        state.roomSize.roomLength
      );

  /**
   * update progress header for tray vs platform
   */
  React.useEffect(() => {
    setSteps(
      _.filter(STEPS, (step) => {
        return step.belongsTo.includes(state.growArea.id || 0);
      })
    );
  }, [state.growArea]);

  const updateWalkwayWidth = (walkwayWidth) => {
    setState({ ...state, walkwayWidth });
  };

  /**
   * this makes sure when "state" is updated header is as well (for ex. if room size is changed trays goes empty and header as well)
   */
  const headerSteps = steps.slice(0, 6).map((item) => {
    switch (item.value) {
      case "Grow Area Style":
        return {
          ...item,
          info: state.growArea.label || "",
        };
      case "Container or Medium":
        return {
          ...item,
          info: state.chosenPot.label || "",
        };
      case "Irrigation Style":
        return {
          ...item,
          info: state.irrigationStyle.label || "",
        };
      case "Tray Size":
        return {
          ...item,
          info:
            state.trays.length > 0 ? (
              <div className="flex-all tray-text-header">
                {state.trays.length > 1 && (
                  <div className="flex-all rounder-div-green">
                    <div>{state.trays.length}</div>
                  </div>
                )}
                <div className="main-text-tray-header">
                  {`${state.trays.reduce((final, current) => {
                    if (typeof current.label === "string") {
                      const label = current.label.replace(/^(tray\s)/i, "");
                      return `${final}${final ? "," : ""} ${label}: ${
                        current.widthVal
                      } X ${current.lengthVal}`;
                    }
                    return final;
                  }, "")}`}
                </div>
              </div>
            ) : (
              ""
            ),
        };
      case "Platform Layout":
        return {
          ...item,
        };
      case "Room Dimensions":
        return {
          ...item,
          info:
            state.roomSize.roomWidth && state.roomSize.roomLength
              ? `${state.roomSize.roomWidth} X ${state.roomSize.roomLength} ${state.roomSize.measureSystem}`
              : "",
        };
    }
    return item;
  });

  /**
   * go along with the implementation
   */
  function reinitWindowVars() {
    window.inputsDisabled = true;
    window.maxLinkLength = undefined;
    window.maxPlatforms = undefined;
    window.maxRows = undefined;
    window.maxWalkwayWidth = undefined;
    window.maxZoneWidth = undefined;
    window.offsetX = -1.702;
    window.offsetY = -1.6;
    window.platFormSize = 1.2;
    window.rotationAngle = 0;
    window.rowCount = 1;
    window.wasCentered = false;
    window.zoneMaxWidth = undefined;
  }

  React.useLayoutEffect(() => {
    reinitWindowVars();
    return () => {
      reinitWindowVars();
    };
  }, []);

  React.useEffect(() => {
    let urlParams = new URL(
      `https://dummy-url.ro/${props.history.location.search}`
    ).searchParams;
    const id_room = urlParams.get("room_id");
    if (id_room != selectedRoom.id) {
      let newSearch;
      if (selectedRoom.id) {
        newSearch = `?room_id=${selectedRoom.id}`;
      } else {
        newSearch = "";
      }
      props.history.replace({
        pathname: props.history.location.pathname,
        search: newSearch,
      });
    }
  }, [selectedRoom.id]);

  React.useEffect(() => {
    return () => {};
  }, []);

  localStorage.setItem("rb-last-room-loaded", JSON.stringify(state));

  const _stepIndexing = steps.reduce((final, current) => {
    return {
      ...final,
      [current.path]: true,
    };
  }, {});

  return (
    <>
      <Header saveRoom={saveRoomAs} />
      <div className="kitbuilder">
        <div className="kitbuilder-main-wrapper">
          {!routingState.hideNavBar && (
            <NavigationMenu
              steps={headerSteps}
              growId={state.growArea.id || 0}
              selectedIndex={steps.findIndex(
                (item) => item.path === props.history.location.pathname
              )}
              goToPage={goToPage}
            />
          )}

          <div
            className={`step-main-container ${
              routingState.hideNavBar ? `step-cart` : ``
            }`}
          >
            <Switch>
              <Route
                path="/kitbuilder/room-dimensions"
                render={(routerProps) => (
                  <WithNavigation
                    history={routerProps.history}
                    onChangeCurrentPath={(value) => setCurrentStep(value)}
                    value="Room Dimensions"
                  >
                    <KitRoom
                      {...routerProps}
                      roomSize={state.roomSize}
                      errorOverlaping={errorOverlaping}
                      warningTrayDist={warningTrayDist}
                      ignoreTraySpacing={ignoreTraySpacing}
                      openPopup={openPopup}
                      setRoomSize={setRoomSize}
                      changeMeasureSystem={changeMeasureSystem}
                      traysAreEmpty={state.trays.length === 0}
                      emptyTrays={() => {
                        setState({
                          ...state,
                          trays: [],
                        });
                      }}
                    />
                  </WithNavigation>
                )}
              />
              <Route
                path="/kitbuilder/grow-area"
                render={(routerProps) => (
                  <WithNavigation
                    history={routerProps.history}
                    onChangeCurrentPath={(value) => setCurrentStep(value)}
                    value="Grow Area Style"
                  >
                    {" "}
                    <GrowArea
                      {...routerProps}
                      roomSize={state.roomSize}
                      growArea={state.growArea}
                      setGrowArea={onChangeGrowArea}
                      openPopup={openPopup}
                      errorOverlaping={errorOverlaping}
                      warningTrayDist={warningTrayDist}
                      ignoreTraySpacing={ignoreTraySpacing}
                    />
                  </WithNavigation>
                )}
              />
              {_stepIndexing["/kitbuilder/tray-size"] && (
                <Route
                  path="/kitbuilder/tray-size"
                  render={(routerProps) => (
                    <WithNavigation
                      history={routerProps.history}
                      onChangeCurrentPath={(value) => setCurrentStep(value)}
                      value="Tray Size"
                    >
                      {" "}
                      <TraySize
                        {...routerProps}
                        roomSize={state.roomSize}
                        openPopup={openPopup}
                        errorOverlaping={errorOverlaping}
                        warningTrayDist={warningTrayDist}
                        ignoreTraySpacing={ignoreTraySpacing}
                        trays={state.trays}
                        setTrays={(trays) => {
                          setState({ ...state, trays });
                        }}
                      />
                    </WithNavigation>
                  )}
                />
              )}
              <Route
                path="/kitbuilder/container-or-medium"
                render={(routerProps) => (
                  <WithNavigation
                    history={routerProps.history}
                    onChangeCurrentPath={(value) => setCurrentStep(value)}
                    value="Container or Medium"
                  >
                    {" "}
                    <ContainerOrMedium
                      {...routerProps}
                      roomSize={state.roomSize}
                      chosenPot={state.chosenPot}
                      growArea={state.growArea}
                      setPot={(pot) => {
                        setState({
                          ...state,
                          chosenPot: pot,
                          irrigationStyle: roomFunctions.generateInitialState()[
                            "irrigationStyle"
                          ], // reset irrigation style
                        });
                      }}
                      openPopup={openPopup}
                      errorOverlaping={errorOverlaping}
                      warningTrayDist={warningTrayDist}
                      ignoreTraySpacing={ignoreTraySpacing}
                    />
                  </WithNavigation>
                )}
              />

              {_stepIndexing["/kitbuilder/platform-layout"] && (
                <Route
                  path="/kitbuilder/platform-layout"
                  render={(routerProps) => (
                    <WithNavigation
                      history={routerProps.history}
                      onChangeCurrentPath={(value) => setCurrentStep(value)}
                      value="Platform Layout"
                    >
                      <PlatformLayout
                        {...routerProps}
                        roomSize={state.roomSize}
                        zones={state.zones}
                        setPlatForm={(platformData) => {
                          setState({
                            ...state,
                            platformData: {
                              ...state.platformData,
                              ...platformData,
                            },
                          });
                        }}
                        updateZones={(zones, platformData) => {
                          setState({
                            ...state,
                            zones,
                            platformData: {
                              ...state.platformData,
                              ...platformData,
                            },
                          });
                        }}
                        platformNumRows={state.platformData.platformNumRows}
                        platformLinkLength={
                          state.platformData.platformLinkLength
                        }
                        platformWalkwayWidth={
                          state.platformData.platformWalkwayWidth
                        }
                        platFormSize={state.platformData.platFormSize}
                        platformDrainageDirection={
                          state.platformData.platformDrainageDirection
                        }
                        platformNumRows={state.platformData.platformNumRows}
                        platformPlatformsPerRow={
                          state.platformData.platformPlatformsPerRow
                        }
                        platformSubzoneWidth={
                          state.platformData.platformSubzoneWidth
                        }
                      />
                    </WithNavigation>
                  )}
                />
              )}
              {_stepIndexing["/kitbuilder/zoning-style"] && (
                <Route
                  path="/kitbuilder/zoning-style"
                  render={(routerProps) => (
                    <WithNavigation
                      roomSize={state.roomSize}
                      history={routerProps.history}
                      onChangeCurrentPath={(value) => setCurrentStep(value)}
                      value="Zoning Style"
                    >
                      {" "}
                      <Zones
                        {...routerProps}
                        wateredZone={state.wateredZone}
                        setWateredZone={(wateredZone) => {
                          setState({ ...state, wateredZone });
                        }}
                        openPopup={openPopup}
                      />
                    </WithNavigation>
                  )}
                />
              )}
              {_stepIndexing["/kitbuilder/irrigation-style"] && (
                <Route
                  path="/kitbuilder/irrigation-style"
                  render={(routerProps) => (
                    <WithNavigation
                      roomSize={state.roomSize}
                      history={routerProps.history}
                      onChangeCurrentPath={(value) => setCurrentStep(value)}
                      value="Irrigation Style"
                    >
                      {" "}
                      <Irrigation
                        {...routerProps}
                        roomSize={state.roomSize}
                        style={state.irrigationStyle}
                        chosenPot={state.chosenPot}
                        growArea={state.growArea}
                        setStyle={(style) => {
                          setState({
                            ...state,
                            irrigationStyle: style,
                            calculatorState: roomFunctions.generateInitialState()[
                              "calculatorState"
                            ],
                            plumbing: roomFunctions.generateInitialState()[
                              "plumbing"
                            ],
                            mainLinePipe: roomFunctions.generateInitialState()[
                              "mainLinePipe"
                            ],
                            dripperStyle: roomFunctions.generateInitialState()[
                              "dripperStyle"
                            ],
                            dripperPerPlant: roomFunctions.generateInitialState()[
                              "dripperPerPlant"
                            ],
                          });
                        }}
                        openPopup={openPopup}
                        errorOverlaping={errorOverlaping}
                        warningTrayDist={warningTrayDist}
                        ignoreTraySpacing={ignoreTraySpacing}
                      />
                    </WithNavigation>
                  )}
                />
              )}
              <Route
                path="/kitbuilder/recommended-builds"
                render={(routerProps) => (
                  <WithNavigation
                    history={routerProps.history}
                    onChangeCurrentPath={(value) => setCurrentStep(value)}
                    value="Recommended Builds"
                  >
                    {" "}
                    <RecommendedBuilds
                      {...routerProps}
                      roomSize={state.roomSize}
                      calculatorState={state.calculatorState}
                      plumbing={state.plumbing}
                      mainLinePipe={state.mainLinePipe}
                      dripperStyle={state.dripperStyle}
                      dripperPerPlant={state.dripperPerPlant}
                      errorOverlaping={errorOverlaping}
                      warningTrayDist={warningTrayDist}
                      irrigationStyle={state.irrigationStyle}
                      chosenPot={state.chosenPot}
                      growArea={state.growArea}
                      growAreaId={state.growArea.id}
                      ignoreTraySpacing={ignoreTraySpacing}
                      trays={state.trays}
                      density={state.calculatorState.plantLabel}
                      setState={(payload) => {
                        setState({
                          ...state,
                          ...payload,
                        });
                      }}
                      setCalculatorState={(calculatorState) => {
                        setState({
                          ...state,
                          calculatorState,
                        });
                      }}
                      setPlumbing={(plumbing) => {
                        setState({
                          ...state,
                          plumbing,
                        });
                      }}
                      setMainLinePipe={(mainLinePipe) => {
                        setState({
                          ...state,
                          mainLinePipe,
                        });
                      }}
                      setDripperStyle={(dripperStyle) => {
                        setState({
                          ...state,
                          dripperStyle,
                        });
                      }}
                      setDripperPerPlant={(dripperPerPlant) => {
                        setState({
                          ...state,
                          dripperPerPlant,
                        });
                      }}
                      openPopup={openPopup}
                      saveRoom={() => saveYourBuild(true)}
                    />
                  </WithNavigation>
                )}
              />

              <Route
                path="/kitbuilder/cart-summary"
                render={(routerProps) => (
                  <WithNavigation
                    history={routerProps.history}
                    onChangeCurrentPath={(value) => setCurrentStep(value)}
                    value="Cart Summary"
                  >
                    <CartSummary
                      {...routerProps}
                      trayType={state.chosenPot.label || ""}
                      grow={state.growArea.label || ""}
                      irrigation={
                        state.irrigationStyle != null
                          ? state.irrigationStyle.label
                          : ""
                      }
                      setlectedPlantLabel={
                        state.calculatorState.plantLabel || ""
                      }
                      calculatorState={{
                        flush: state.calculatorState.flush,
                        plant: state.calculatorState.plant,
                        volume: state.chosenPot.value || 2,
                        week: state.calculatorState.week,
                        addCycles: state.calculatorState.addCycles,
                      }}
                      setCalculatorState={(calculatorState) => {
                        setState({
                          ...state,
                          calculatorState,
                        });
                      }}
                      buildComponents={buildComponents}
                      plants={state.calculatorState.plant || 12}
                      openPopup={openPopup}
                      setBuildLine={setBuildLine}
                      calculateNumOfPlants={calculateNumOfPlants}
                      trays={state.trays}
                      zones={state.zones}
                      roomSize={state.roomSize}
                      growarea={state.growArea.label}
                      container={state.chosenPot.label}
                      irrigation={state.irrigationStyle.label}
                      density={state.calculatorState.plantLabel}
                      selectedRoom={selectedRoom}
                      resetBuildComponents={resetBuildComponents}
                      thirdPartyParts={thirdPartyParts}
                      resetThirdPartyParts={resetThirdPartyParts}
                    />
                  </WithNavigation>
                )}
              />

              <Route
                path="/kitbuilder"
                render={() => {
                  props.history.replace({
                    pathname: "/kitbuilder/room-dimensions",
                    search: props.history.location.search,
                  });
                  return "";
                }}
              />
            </Switch>

            <KitRoomlayout
              roomWidth={state.roomSize.roomWidth}
              roomLength={state.roomSize.roomLength}
              measureSystem={state.roomSize.measureSystem}
              trays={state.trays}
              zones={state.zones}
              setTrays={(trays) => {
                setState({
                  ...state,
                  trays,
                });
              }}
              hideCanvas={routingState.hideCanvas}
              roomSize={{
                ...state.roomSize,
                roomWidth: state.roomSize.roomWidth,
                roomLength: state.roomSize.roomLength,
              }}
              roomSizeKey={state.roomSizeKey}
              openPopup={openPopup}
              platformData={state.platformData}
              pathName={props.history.location.pathname}
              growAreaId={state.growArea.id}
              updateZones={(zones, platformData) => {
                if (platformData && typeof platformData === "object") {
                  setState({
                    ...state,
                    zones,
                    platformData: {
                      ...state.platformData,
                      ...platformData,
                    },
                  });
                } else {
                  setState({
                    ...state,
                    zones,
                  });
                }
              }}
              clientId={clientId}
            />
            {(routingState.hideCanvas || routingState.load3DHidden) && (
              <div
                id="step-content-3d-rm"
                load3dhidden={routingState.load3DHidden ? "true" : "false"}
                className="step-content kit-room-layout kit-cart-layout"
              >
                <div className="kit-cart-layout-content">
                  {localStorage.getItem("no-3d---") !== "y" && (
                    <Kit3DCanvas
                      {...{
                        growarea: state.growArea.label,
                        container: state.chosenPot.label,
                        irrigation: state.irrigationStyle.label,
                        density: state.calculatorState.plantLabel,
                        dripperStyle: state.dripperStyle,
                        dripperPerPlant: state.dripperPerPlant,
                        platformData: state.platformData,
                        growAreId: state.growArea.id,
                      }}
                      plumbing={state.plumbing}
                      trays={state.trays}
                      roomSize={state.roomSize}
                      selectedRoom={selectedRoom}
                      calculateNumOfPlants={calculateNumOfPlants}
                      canvas2dProps={{
                        roomSize: {
                          ...state.roomSize,
                          roomWidth: state.roomSize.roomWidth,
                          roomLength: state.roomSize.roomLength,
                        },

                        trays: state.trays,
                        zones: state.zones,
                        measureSystem: state.roomSize.measureSystem,
                        roomSizeKey: state.roomSizeKey,
                        openPopup,
                      }}
                      shareRoom={shareRoom}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
