import React, { Component } from "react";
import { Group, Rect, Text, Transformer } from "react-konva";

export default class WalkwayCanvas extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.groupRef = React.createRef();
  }

  componentDidMount = () => {
    window._info2 = () => {
      console.log(this.props, this.groupRef);
    };
  };

  render() {
    return (
      <>
        <Rect
          width={this.props.bodyWidth}
          height={this.props.bodyHeight}
          x={this.props.x}
          y={this.props.y}
          fill={'rgba(255,255,255,0.5'}
          strokeEnabled={true}
          strokeScaleEnabled={true}
          stroke="#707070"
          strokeWidth={1}
          id={this.props.id}
        />
      </>
    );
  }
}
