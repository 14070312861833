// @flow
import * as React from "react";
import "./Header.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router-dom";

import floraImg from "./../../../../assets/image/Logo_Flora-White.png";

import { ReactComponent as LeftBackIcon } from "./../../../../assets/svg/left-back.svg";
import imgBanner from "./../../../../assets/image/header-photo.jpg";

const arrowDown = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23.405"
    height="13.117"
    viewBox="0 0 23.405 13.117"
  >
    <g id="down-arrow" transform="translate(-2.586 -12.586)">
      <path
        id="Path_1427"
        data-name="Path 1427"
        d="M4,14,14.288,24.288,24.577,14"
        fill="none"
        stroke="#487629"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </g>
  </svg>
);

function Header(props) {
  const [state, setState] = React.useState({
    saveMenu: false,
  });

  function toggleSaveMenu() {
    setState({
      ...state,
      saveMenu: !state.saveMenu,
    });
  }

  return (
    <div>
      <div className="header-new room-builder-header">
        <div className="header-button-group">
          <div
            className="header-button">
            <a className="header-base-url" href={`${window.BASE_URL}`}>FLORAFLEX.COM</a>
          </div>
          <div
            className="header-button"
            id="header-save"
            active={state.saveMenu ? "1" : "0"}
            onClick={() => toggleSaveMenu()}
          >
            <span>
              SAVE<span>{arrowDown}</span>
            </span>
            {state.saveMenu && (
              <div className="save-pop" onClick={props.saveRoom}>
                <div  className="item-save-pop">SAVE AS...</div>
              </div>
            )}
          </div>
        </div>
        <div className="header-banner">
          <img src={imgBanner} alt="banner_image"></img>
        </div>
        <div className="header-button my-account" onClick={() => props.history.push("/dashboard")}>MY ACCOUNT</div>
      </div>
      {/* <header className="header">
      <div className="header-content">
        <div className="back-button" onClick={props.history.goBack}>
          <div>
            <LeftBackIcon color="white" />
          </div>
        </div>
        <div className="header-logo">
          <img src={floraImg} />
        </div>
        <div className="flex-start">
          <div className="header-text">ROOM BUILDER</div>
        </div>
        <div className="flex-end p-right-50 header-right cursor-pointer">
          <div className="header-text">MY ACCOUNT</div>
        </div>
      </div>
    </header>
    */}
    </div>
  );
}

export default withRouter(Header);
