import React from "react";
import { createPortal } from "react-dom";
import "./PortalContainer.scss";

const modalRoot = document.getElementById("modal");

class PortalContainer extends React.Component {
  constructor(props) {
    super(props);
    this.element = document.createElement("div");
  }
  componentDidMount() {
    modalRoot.appendChild(this.element);
  }
  componentWillUnmount() {
    modalRoot.removeChild(this.element);
  }
  render() {
    return createPortal(
      <>{this.props.children}</>,
      this.element
    );
  }
}
export default PortalContainer;
