import React, { Component } from "react";
import RoomModel from "../Models/Room";
import Decimal from "decimal.js";
import PropTypes from "prop-types";

import {
  CELL_WIDTH,
  gridMiddleX,
  gridMiddleY,
  STROKE_WIDTH,
} from "../Constants";
import { trayErrors } from "../../KitRoom/KitRoomFunctions";
import { Group, Rect, Text } from "react-konva";
import { number2Digits } from "../../../../utils/utilFunctions";
import TrayCanvas from "./TrayCanvas";
import DrainageCanvas from "./DrainageCanvas";
import ZoneCanvas from "./ZoneCanvas";
import PlatformCanvas from "./PlatformCanvas";
import WalkwayCanvas from "./WalkwayCanvas";

class Room extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startX: 0,
      startY: 0,
      bodyWidth: 0,
      bodyHeight: 0,
    };
    this.roomModel = new RoomModel();
  }

  componentDidMount = () => {
    this.onRoomLengthChanged();
  };
  componentDidUpdate = (prevProps) => {
    if (
      this.props.roomWidth !== prevProps.roomWidth ||
      this.props.roomLength !== prevProps.roomLength
    ) {
      this.onRoomLengthChanged();
    }
  };

  onRoomLengthChanged = () => {
    this.setState({
      ...this.roomModel.generateRoomStart(this.props),
    });
  };

  draggedItem;

  onDragStart = (event, item, index) => {
    this.draggedItem = { item, index };
  };

  onDragEnd = (event, item, index, oX, oY, isZone = false) => {
    const _lastPos = {
      x: new Decimal(event.target.attrs.x),
      y: new Decimal(event.target.attrs.y),
    };

    const _oPos = {
      x: new Decimal(oX),
      y: new Decimal(oY),
    };
    const _roomPos = {
      x: new Decimal(this.state.startX),
      y: new Decimal(this.state.startY),
    };

    const realX = _oPos.x.plus(_lastPos.x);
    const realY = _oPos.y.plus(_lastPos.y);

    const distOffsetX = realX.minus(_roomPos.x);
    const distOffsetY = realY.minus(_roomPos.y);

    let offsetX = Number(distOffsetX.dividedBy(CELL_WIDTH));
    let offsetY = Number(distOffsetY.dividedBy(CELL_WIDTH));

    //
    if (offsetX < 0) {
      offsetX = 0;
    }
    if (offsetY < 0) {
      offsetY = 0;
    }
    //_|
    if (item) {
      if (offsetX > this.props.roomWidth - item.widthVal_RBased) {
        offsetX = this.props.roomWidth - 0 - item.widthVal_RBased;
      }
      if (offsetY > this.props.roomLength - item.lengthVal_RBased) {
        offsetY = this.props.roomLength - 0 - item.lengthVal_RBased;
      }
    }

    // Check if we're updating the platform canvas or not
    if (isZone) {
      if (
        offsetX >
        this.props.roomWidth - this.getPlatformWidth(this.props.zones)
      ) {
        offsetX =
          this.props.roomWidth - 0 - this.getPlatformWidth(this.props.zones);
      }
      if (
        offsetY >
        this.props.roomLength - this.getPlatformHeight(this.props.zones)
      ) {
        offsetY =
          this.props.roomLength - 0 - this.getPlatformHeight(this.props.zones);
      }
      // Keep the group of shapes inside the room if the user try to drag it outside
      this.updateZonesAfterDragging(offsetX, offsetY);
    } else {
      this.props.setTrayItem({ ...item, offsetX, offsetY }, index);
    }
  };

  updateZonesAfterDragging = (newX, newY) => {
    const oldWalkwayOffsetX = this.getPlatformOffset(this.props.zones).offsetX;
    const oldWalkwayOffsetY = this.getPlatformOffset(this.props.zones).offsetY;

    const deltaX = newX - oldWalkwayOffsetX;
    const deltaY = newY - oldWalkwayOffsetY;

    let newZones = [];

    for (let i = 0; i < this.props.zones.length; i++) {
      let zone = { ...this.props.zones[i] };
      zone.offsetX += deltaX;
      zone.offsetY += deltaY;
      newZones.push(zone);
    }

    this.props.updateZones(newZones, {
      platformXcoord: newX,
      platformYcoord: newY
    });
  };

  onZoneDragEnd = (event) => {};

  onTrayRotation = (item, index) => {
    const newBodyWidth = item.bodyHeight;
    const newBodyHeight = item.bodyWidth;

    let error;
    if (newBodyWidth > this.state.bodyWidth) {
      error = trayErrors.UNABLE_TO_ROTATE;
    }
    if (newBodyHeight > this.state.bodyHeight) {
      error = trayErrors.UNABLE_TO_ROTATE;
    }

    if (error) {
      this.props.openPopup({
        content: error.message,
      });
      return;
    }
    //check if can fit on X and Y axes
    let { offsetX, offsetY } = item;
    if (offsetX < 0) {
      offsetX = 0;
    }
    if (offsetY < 0) {
      offsetY = 0;
    }

    //check position
    const widthVal_RBased = item.lengthVal_RBased;
    const lengthVal_RBased = item.widthVal_RBased;
    if (offsetX > this.props.roomWidth - 0 - widthVal_RBased) {
      offsetX = this.props.roomWidth - 0 - widthVal_RBased;
    }
    if (offsetY > this.props.roomLength - lengthVal_RBased) {
      offsetY = this.props.roomLength - lengthVal_RBased;
    }

    this.props.setTrayItem(
      {
        ...item,
        offsetY,
        offsetX,
        isRotated: !item.isRotated,
        xPosition: !item.xPosition,
      },
      index
    );
  };

  getPlatformOffset = (zones) => {
    const platform = zones.filter((item) => item.label === "PLATFORM")[0];
    return { offsetX: platform.offsetX, offsetY: platform.offsetY };
  };

  getPlatformWidth = (zones) => {
    return zones.filter((item) => item.label === "PLATFORM")[0].widthVal;
  };

  getPlatformHeight = (zones) => {
    return zones.filter((item) => item.label === "PLATFORM")[0].lengthVal;
  };

  getDrainageWidth = (zones) => {
    return zones.filter((item) => item.label === "DRAINAGE")[0].widthVal;
  };

  getWalkwayOffset = (zones) => {
    let platformOffset = this.getPlatformOffset(zones);
    return {
      offsetX: platformOffset.offsetX - this.props.platformWalkwayWidth,
      offsetY: platformOffset.offsetY - this.props.platformWalkwayWidth,
    };
  };

  getWalkawayWidth = (zones) => {
    return zones.props.filter((item) => item.label === "WALKWAY")[0].widthVal;
  };

  getWalkawayHeight = (zones) => {
    return zones.props.filter((item) => item.label === "WALKWAY")[0].lengthVal;
  };

  render() {
    const { topLine, rightLine } = this.roomModel.generateAux(
      this.props,
      this.state
    );

    const { allowTrays, allowPlatform, platFormDraggable } = this.roomModel.canvasType(
      this.props.pathName,
      this.props.platformDrainageDirection,
      this.props.growAreaId
    );

    return (
      <Group>
        {topLine && (
          <Group>
            <Rect
              width={topLine.half1.width}
              height={topLine.half1.height}
              x={topLine.half1.x}
              y={topLine.half1.y}
              fill="#A7A8A9"
              strokeEnabled={true}
              strokeScaleEnabled={true}
              stroke=""
              strokeWidth={1}
              shadowColor="black"
              shadowBlur={topLine.shadowBlur}
              shadowOpacity={topLine.shadowOpacity}
              shadowOffsetX={topLine.shadowOffsetX}
              shadowOffsetY={topLine.shadowOffsetY}
            />
            <Rect
              width={topLine.half2.width}
              height={topLine.half2.height}
              x={topLine.half2.x}
              y={topLine.half2.y}
              fill="#A7A8A9"
              strokeEnabled={true}
              strokeScaleEnabled={true}
              stroke=""
              strokeWidth={1}
              shadowBlur={topLine.shadowBlur}
              shadowOpacity={topLine.shadowOpacity}
              shadowOffsetX={topLine.shadowOffsetX}
              shadowOffsetY={topLine.shadowOffsetY}
            />
            <Text
              x={topLine.text.x}
              y={topLine.text.y}
              width={topLine.text.width}
              height={topLine.text.height}
              fillEnabled={true}
              fill="#A7A8A9"
              text={topLine.text.label}
              fontSize={21}
              lineHeight={topLine.text.lineHeight}
              fontFamily="Lato Bold"
              fontVariant=""
              align="center"
              verticalAlign="center"
              shadowBlur={topLine.shadowBlur}
              shadowOpacity={topLine.shadowOpacity}
              shadowOffsetX={topLine.shadowOffsetX}
              shadowOffsetY={topLine.shadowOffsetY}
            />
          </Group>
        )}
        {rightLine && topLine && (
          <Group>
            <Rect
              width={rightLine.half1.width}
              height={rightLine.half1.height}
              x={rightLine.half1.x}
              y={rightLine.half1.y}
              fill="#A7A8A9"
              strokeEnabled={true}
              strokeScaleEnabled={true}
              stroke=""
              strokeWidth={1}
              shadowBlur={topLine.shadowBlur}
              shadowOpacity={topLine.shadowOpacity}
              shadowOffsetX={topLine.shadowOffsetX}
              shadowOffsetY={topLine.shadowOffsetY}
            />
            <Rect
              width={rightLine.half2.width}
              height={rightLine.half2.height}
              x={rightLine.half2.x}
              y={rightLine.half2.y}
              fill="#A7A8A9"
              strokeEnabled={true}
              strokeScaleEnabled={true}
              stroke=""
              strokeWidth={1}
              shadowBlur={topLine.shadowBlur}
              shadowOpacity={topLine.shadowOpacity}
              shadowOffsetX={topLine.shadowOffsetX}
              shadowOffsetY={topLine.shadowOffsetY}
            />
            <Text
              x={rightLine.text.x}
              y={rightLine.text.y}
              width={rightLine.text.width}
              height={rightLine.text.height}
              fillEnabled={true}
              fill="#A7A8A9"
              text={rightLine.text.label}
              fontSize={21}
              lineHeight={rightLine.text.lineHeight}
              fontFamily="Lato Bold"
              fontVariant=""
              align="center"
              verticalAlign="center"
              shadowBlur={topLine.shadowBlur}
              shadowOpacity={topLine.shadowOpacity}
              shadowOffsetX={topLine.shadowOffsetX}
              shadowOffsetY={topLine.shadowOffsetY}
            />
          </Group>
        )}

        <Rect
          width={this.state.bodyWidth}
          height={this.state.bodyHeight}
          x={this.state.startX}
          y={this.state.startY}
          fill="rgba(167, 168, 169, 0.16)"
          strokeEnabled={true}
          strokeScaleEnabled={true}
          stroke="#54565A"
          strokeWidth={6}
        />
        {
          this.props.trays.map((item, index) => {
            if(!allowTrays) {
              return;
            }
            const { offsetX, offsetY } = item;
            const x = number2Digits(this.state.startX + offsetX * CELL_WIDTH);
            const y = number2Digits(this.state.startY + offsetY * CELL_WIDTH);

            return (
              <TrayCanvas
                key={item.key || `tray-form-canvas-2d---${index}`}
                bodyWidth={item.bodyWidth}
                bodyHeight={item.bodyHeight}
                x={x}
                y={y}
                variant={item.bodyWidth > item.bodyHeight ? "right" : ""}
                label={item.label}
                subLabel={item.subLabel}
                rotate={() => this.onTrayRotation(item, index)}
                onDragStart={(event) => this.onDragStart(event, item, index)}
                onDragEnd={(event) => this.onDragEnd(event, item, index, x, y)}
                roomStartX={this.state.startX}
              />
            );
          })}

        {!allowPlatform ? null : (
          <Group
            draggable={
              platFormDraggable
                ? true
                : false
            }
            x={0}
            y={0}
            _useStrictMode
            onDragEnd={(event) =>
              this.onDragEnd(
                event,
                null,
                null,
                number2Digits(
                  this.state.startX +
                    this.getPlatformOffset(this.props.zones).offsetX *
                      CELL_WIDTH
                ),
                number2Digits(
                  this.state.startY +
                    this.getPlatformOffset(this.props.zones).offsetY *
                      CELL_WIDTH
                ),
                true
              )
            }
            ref={this.groupRef}
          >
            <Group>
              {this.props.zones.map((item, index) => {
                const { offsetX, offsetY } = item;
                const x = number2Digits(
                  this.state.startX + offsetX * CELL_WIDTH
                );
                const y = number2Digits(
                  this.state.startY + offsetY * CELL_WIDTH
                );

                return (
                  <Group>
                    {item.label === "WALKWAY" ? (
                      <WalkwayCanvas
                        key={item.key || `tray-form-canvas-2d---${index}`}
                        bodyWidth={item.bodyWidth}
                        bodyHeight={item.bodyHeight}
                        x={x}
                        y={y}
                        type={item.label}
                        roomStartX={this.state.startX}
                        id={"zone" + index}
                      />
                    ) : (
                      <></>
                    )}

                    {item.label === "DRAINAGE" ? (
                      <DrainageCanvas
                        key={item.key || `tray-form-canvas-2d---${index}`}
                        bodyWidth={
                          this.getDrainageWidth(this.props.zones) * CELL_WIDTH
                        }
                        bodyHeight={item.bodyHeight}
                        x={x}
                        y={y}
                        variant={
                          item.bodyWidth > item.bodyHeight ? "right" : ""
                        }
                        label={item.label}
                        roomStartX={this.state.startX}
                        id={"zone" + index}
                        platformDrainageDirection={
                          this.props.platformDrainageDirection
                        }
                      />
                    ) : (
                      <></>
                    )}

                    {item.label === "ROW" || item.label === "SUBZONE" ? (
                      <ZoneCanvas
                        key={item.key || `tray-form-canvas-2d---${index}`}
                        bodyWidth={item.bodyWidth}
                        bodyHeight={item.bodyHeight}
                        x={x}
                        y={y}
                        type={item.label}
                        variant={
                          item.bodyWidth > item.bodyHeight ? "right" : ""
                        }
                        platformDrainageDirection={
                          this.props.platformDrainageDirection
                        }
                        roomStartX={this.state.startX}
                        id={"zone" + index}
                      />
                    ) : (
                      <></>
                    )}

                    {item.label === "PLATFORM" ? (
                      <PlatformCanvas
                        key={item.key || `tray-form-canvas-2d---${index}`}
                        bodyWidth={item.bodyWidth}
                        bodyHeight={item.bodyHeight}
                        x={x}
                        y={y}
                        type={item.label}
                        roomStartX={this.state.startX}
                        id={"zone" + index}
                      />
                    ) : (
                      <></>
                    )}
                  </Group>
                );
              })}
            </Group>
          </Group>
        )}

        {/**
         * test......................................below
         */}
        <Rect
          width={CELL_WIDTH}
          height={CELL_WIDTH}
          x={gridMiddleX}
          y={gridMiddleY}
          fill="rgba(0, 0, 0, 0.01)"
          strokeEnabled={true}
          strokeScaleEnabled={true}
          strokeWidth={STROKE_WIDTH}
        />
        {/* <Rect
          width={CELL_WIDTH}
          height={CELL_WIDTH}
          x={0}
          y={0}
          fill="red"
          strokeEnabled={true}
          strokeScaleEnabled={true}
          stroke="#54565A"
          strokeWidth={STROKE_WIDTH}
        /> */}
      </Group>
    );
  }
}

// Room.propTypes = {
//   pathName: PropTypes.string,
//   roomWidth: PropTypes.number,
//   roomLength: PropTypes.number,
//   setTrayItem: PropTypes.func,
//   openPopup: PropTypes.func,
//   trays: PropTypes.shape({
//     id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
//     label: PropTypes.string,
//     widthVal: PropTypes.number,
//     lengthVal: PropTypes.number,
//     boxStatus: PropTypes.bool,
//     xPosition: PropTypes.bool,
//     isRotated: PropTypes.bool,
//     offsetX: PropTypes.number,
//     offsetY: PropTypes.number,
//     widthVal: PropTypes.number,
//     lengthVal: PropTypes.number,
//     widthVal_RBased: PropTypes.number,
//     lengthVal_RBased: PropTypes.number,
//     subLabel: PropTypes.string,
//   }),
//   /**
//    * platform - start
//    */
//   updateZoneItem: PropTypes.func,
//   zones: PropTypes.shape({
//     id: PropTypes.number,
//     label: PropTypes.string,

//     widthVal: PropTypes.number,
//     lengthVal: PropTypes.number,
//     widthVal_RBased: PropTypes.number,
//     lengthVal_RBased: PropTypes.number,

//     boxStatus: PropTypes.bool,
//     xPosition: PropTypes.bool,
//     isRotated: PropTypes.bool,
//     offsetX: PropTypes.number,
//     offsetY: PropTypes.number,
//     physical: PropTypes.bool,
//   }),
//   platformDrainageDirection: PropTypes.string,
//   platformXcoord: PropTypes.number,
//   platformYcoord: PropTypes.number,
//   platformNumRows: PropTypes.number,
//   platformPlatformsPerRow: PropTypes.number,
//   platformLinkLength: PropTypes.number,
//   platformWalkwayWidth: PropTypes.number,
//   platformSubzoneWidth: PropTypes.number,
// };

export default Room;
