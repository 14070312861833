// @flow
import * as React from "react";
import buildComponents from "./../../../../../assets/image/builds.png";
/* component images */
import potComponentIcon from "./../../../../../assets/image/potComponent.png";
import irrigationComponentIcon from "./../../../../../assets/image/irrigationComponent.png";
import automationComponentIcon from "./../../../../../assets/image/automationComponent.png";
/* products image */

import galBag from "./../../../../../assets/image/buildComponents/1Gal_Bag_Hero.png";
import matrixSquareTop from "./../../../../../assets/image/buildComponents/Matrix_Square_Stack_Top.png";
import matrixSquarePad from "./../../../../../assets/image/buildComponents/Matrix_SquareandPad1.png";
import circulator from "./../../../../../assets/image/buildComponents/Circulator_2.25in.png";
import pot from "./../../../../../assets/image/buildComponents/PotProPot.png";
import barbedTS from "./../../../../../assets/image/buildComponents/Barbed-T-2.png";
import tube from "./../../../../../assets/image/buildComponents/FLORATUBING.png";
import nylonValve from "./../../../../../assets/image/buildComponents/NylonValve_1inch_side.png";

const potComponent = [
    {
        id: 1,
        image: galBag,
        label: "QUICKFILL BAG™ 1 GAL",
        quantity: 36,
        price: 2.79,
    },
];

const irrigationComponent = [
    {
        id: 1,
        image: matrixSquareTop,
        label: "12 PACK 6IN SQUARE MATRIX",
        quantity: 3,
        price: 13.99,
    },
    {
        id: 2,
        image: matrixSquarePad,
        label: "12PACK 6IN SQUARE MATRIX PAD",
        quantity: 3,
        price: 14.99,
    },
    {
        id: 3,
        image: circulator,
        label: "12PACK 2.25IN MATRIX CIRCULATOR",
        quantity: 3,
        price: 10.99,
    },
    {
        id: 4,
        image: pot,
        label: "QDPS TS, 1IN",
        quantity: 5,
        price: 2.99,
    },
    {
        id: 5,
        image: barbedTS,
        label: "12 PACK BARBED TS",
        quantity: 3,
        price: 13.99,
    },
    {
        id: 6,
        image: tube,
        label: "100' FLORA TUBE",
        quantity: 2,
        price: 18.99,
    },
];

const automationComponent = [
    {
        id: 1,
        image: nylonValve,
        label: "NYLON VALVE 2.0",
        quantity: 2,
        price: 39.99,
    },
];
// img:
// "https =>,
// name: "Quickfill Bag 1GAL",
// unit_price: 2.79,
// qty: 36,
// containers={props.buildComponents.containers}
// irrigation_style={props.buildComponents.irrigation_style}
// automation={props.buildComponents.automation}

const BuildLine = ({ element, bottom, setBuildLine, property, index }) => {
    const img = element.img;
    const componentLabel = element.name.replace("®", "<sup>®</sup>");

    return (
        <div className={`component-line ${bottom ? `bbottom` : ``}`}>
            <div className="flex flex-start">
                <img src={img} alt="i" />
                <div className="label" dangerouslySetInnerHTML={{
                    __html: componentLabel,
                }}></div>
            </div>
            <div className="flex">
                <input
                    type="number"
                    onWheel={(event) => {
                        event.target.blur();
                    }}
                    className="quantity-b-line-n-c-rm"
                    value={element.qty}
                    onChange={(event) => {
                        setBuildLine(property, index, event.target.value);
                    }}
                    onBlur={() => {
                        const value =
                            Math.floor((Number(element.qty) || 0) * 100) / 100;
                        setBuildLine(property, index, value);
                    }}
                />
                <div className="price">
                    Price/Unit
                    <br />
                    <span>
                        $
                        {(Number(element.unit_price) || 0).toLocaleString(
                            "en",
                            {
                                minimumFractionDigits: 2,
                            }
                        )}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default function BuildComponents(props) {
    return (
        <div className="build-components-popup">
            <div className="build-logo-wrapper">
                <img src={buildComponents} alt={"builds_image.png"} />
                <h1>BUILD COMPONENTS</h1>
            </div>
            <div className="build-description">
                Components listed below are predetermined based on previous
                selections you have made about your room size,
                <br />
                zone count, pot/cube type, and number of plants per zone.
            </div>
            <div className="components-wrapper">
                <div className="component component-pot">
                    <div className="component-title">
                        <img src={potComponentIcon} alt={"img_medium.png"} />
                        <div className="text">POT/MEDIUM</div>
                    </div>
                    <div className="components-list">
                        {props.containers.map((element, index) => (
                            <BuildLine
                                property="containers"
                                index={index}
                                setBuildLine={props.setBuildLine}
                                key={`containers-build-line${index}`}
                                element={element}
                                bottom={index < props.containers.length - 1}
                            />
                        ))}
                    </div>
                </div>
                <div className="component component-irrigation">
                    <div className="component-title">
                        <img
                            src={irrigationComponentIcon}
                            alt={"img_irrigation.png"}
                        />
                        <div className="text">IRRIGATION STYLE</div>
                    </div>
                    <div className="components-list">
                        {props.irrigation_style.map((element, index) => (
                            <BuildLine
                                property="irrigation_style"
                                index={index}
                                setBuildLine={props.setBuildLine}
                                key={`irrigation_style-build-line${index}`}
                                element={element}
                                bottom={
                                    index < props.irrigation_style.length - 1
                                }
                            />
                        ))}
                    </div>
                </div>
                <div className="component component-automation">
                    <div className="component-title">
                        <img
                            src={automationComponentIcon}
                            alt={"img_automation.png"}
                        />
                        <div className="text">AUTOMATION</div>
                    </div>
                    <div className="components-list">
                        {props.automation.map((element, index) => (
                            <BuildLine
                                property="automation"
                                index={index}
                                setBuildLine={props.setBuildLine}
                                key={`automation-build-line${index}`}
                                element={element}
                                bottom={index < props.automation.length - 1}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <div className="components-price">
                <div className="price-title per-plant">
                    PRICE PER PLANT
                    <br />
                    <span>
                        $
                        {(
                            Number(props.componentPerPlantPrice) || 0
                        ).toLocaleString("en", {
                            minimumFractionDigits: 2,
                        })}
                    </span>
                </div>
                <div className="price-title">
                    SUBTOTAL
                    <br />
                    <span>
                        $
                        {(Number(props.componentTotal) || 0).toLocaleString(
                            "en",
                            {
                                minimumFractionDigits: 2,
                            }
                        )}
                    </span>
                </div>
            </div>
        </div>
    );
}
