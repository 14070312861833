import React, { useEffect, useLayoutEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { gql, ApolloConsumer, useApolloClient } from "@apollo/client";
import "./PurchaseKits.scss";

import headerBanner from "./../../../assets/image/purchase-kits/heroKits.jpg";
import kit1GalQuickFill from "./../../../assets/image/purchase-kits/1GalQuickFillKit_8_main.png";
import kit2GalQuickFill from "./../../../assets/image/purchase-kits/2GalQuickFillKit_8_main.png";
import potProKit6in8 from "./../../../assets/image/purchase-kits/PotProKit_PickYourOwn_6in_8_main.png";
import potProKit8in8 from "./../../../assets/image/purchase-kits/PotProKit_PickYourOwn_8in_8_main.png";
import Axios from "axios";
import { Spinner } from "react-bootstrap";
import { purchaseErrors } from "./purchaseKitFunctions";
import Button from "../../components/Button/Button";

const plants = [
  {
    id: 1,
    label: 4,
    sku: "",
  },
  {
    id: 2,
    label: 6,
    sku: "",
  },
  {
    id: 3,
    label: 8,
    sku: "",
  },
];

const kits = [
  {
    id: 1,
    label: '6" POTPRO™ KIT',
    image: potProKit6in8,
    sku: "",
  },
  {
    id: 2,
    label: '8" POTPRO™ KIT',
    image: potProKit8in8,
    sku: "",
  },
  {
    id: 3,
    label: "1 GAL QUICKFILL™ KIT",
    image: kit1GalQuickFill,
    sku: "",
  },
  {
    id: 4,
    label: "2 GAL QUICKFILL™ KIT",
    image: kit2GalQuickFill,
    sku: "",
  },
];
/**
 *
 * @param {object} props
 * @param {function}  props.setPurchaseKit
 * @param {string}  props.plantNumber
 * @param {object}  props.growPot
 * @param {Array} props.growItems
 * @param {Array} props.allNumbers
 * @param {boolean}  props.loading
 */
export default function PreMadeKits(props) {
  function selectPlantNumber(value) {
    if (value !== props.plantNumber) {
      props.setPurchaseKit({
        plantNumber: value,
        growPot: undefined,
      });
    }
  }

  function selectGrowPotItem(growPot) {
    props.setPurchaseKit({
      plantNumber: props.plantNumber,
      growPot,
    });
  }

  function nextPage() {
    props.history.push({
      pathname: "/purchase-kits/extra",
      search: props.history.location.search,
    });
  }

  const errorToShow =
    props.loading || !props.plantNumber || !props.growPot
      ? purchaseErrors.NO_POT_SELECTED
      : undefined;
  const continueDisabled = Boolean(errorToShow);

  return (
    <>
      <div className="purchase-kits-simple-page">
        <div className="header-purchase-kits">
          <div className="header-button-group singular">
            <div className="header-button">
              <a
                className="header-base-url"
                href={`${window.BASE_URL}`}
                target="_blank"
              >
                FLORAFLEX.COM
              </a>
            </div>
          </div>
        </div>
        <div className="simple-page-content">
          <div className="top-purchase-kits">
            <div className="img-top-pk">
              <img src={headerBanner} alt="PurchaseKits" />
            </div>
            <div className="title-pk">
              <span>
                FloraFlex<sup>®</sup> has a series of pre-made kits for the
                at-home grower.
              </span>
            </div>
          </div>
          <div className="content-pk">
            <div className="container-pk-items">
              <div className="boxes-pk-items">
                <div className="box-pk-item plants">
                  <div className="title-box-pk">
                    HOW MANY PLANTS WILL YOU BE GROWING?
                  </div>
                  {!props.loading && props.allNumbers.length === 0 && (
                    <div className="content-box-pk">
                      <div className="generic-text-list">No item.</div>
                    </div>
                  )}
                  {(props.loading || props.allNumbers.length > 0) && <div className="content-box-pk flex-evenly">
                    {!props.loading &&
                      props.allNumbers.map((item, index) => {
                        return (
                          <div
                            key={`purchase-kit-plant-${index}`}
                            className={`item-content-box-pk ${
                              item.value === props.plantNumber ? `selected` : ``
                            }`}
                            onClick={() => selectPlantNumber(item.value)}
                          >
                            {item.label}
                          </div>
                        );
                      })}

                    {props.loading && (
                      <div
                        className="flex-all width-100 page-spinner"
                        style={{ flex: 1 }}
                      >
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      </div>
                    )}
                  </div>}
                </div>
                <div className="box-pk-item pots">
                  <div className="title-box-pk">
                    WHAT POT WOULD YOU LIKE TO GROW IN?
                  </div>
                  {props.growItems.length === 0 && (
                    <div className="content-box-pk">
                      <div className="generic-text-list">No item.</div>
                    </div>
                  )}
                  {props.growItems.length > 0 && ( <div className="content-box-pk flex-evenly">
                    {props.growItems.map((item, index) => {
                      return (
                        <div
                          key={`purchase-kit-kit-${index}`}
                          className={`item-content-box-pk ${
                            props.growPot && props.growPot.sku === item.sku
                              ? `selected`
                              : ``
                          }`}
                          onClick={() => selectGrowPotItem(item)}
                        >
                          <div className="img-content-box-pk">
                            <img src={item.image} alt="" />
                          </div>
                          <div className="label-item-content-box-pk">
                            {item.label}
                          </div>
                        </div>
                      );
                    })}
                  </div>)}
                </div>
              </div>
            </div>
          </div>
          <div className="pk-buttons">
            <Button
              text="Back"
              variant="secondary"
              className="pk-button back"
              onClick={() => props.history.replace("/")}
            />
            <Button
              text="CONTINUE"
              className={`pk-button checkout ${
                continueDisabled ? "disabled" : ""
              }`}
              variant="primary-new"
              onClick={() => {
                if (errorToShow) {
                  props.openPopup({
                    content: errorToShow.message,
                  });
                  return;
                }
                if (continueDisabled) {
                  return;
                }
                nextPage();
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
