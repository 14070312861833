import * as React from "react";
import "./Checkbox.scss";

export default function Checkbox(props) {
    return(
        <label className={`checkbox-container${props.customClass ? ` ${props.customClass}` : ''}`}  onClick={typeof props.onCheck === "function" ? props.onCheck : () => {}}>
            <input type="checkbox" checked={props.checked} onChange={(e) => {}} onClick={(e) => {e.stopPropagation()}}/>
            <span className="checkmark"></span>
        </label>
    );
}