import React from "react";

import "./Counter.scss";

export default class Counter extends React.Component {
  constructor(props) {
    super(props);
  }

  increment() {
    this.props.onChange(this.props.value + 1);
  }

  decrement() {
    if(this.props.value - 1 >= 0) {
      this.props.onChange(this.props.value - 1);
    }
  }

  render() {
    return (
      <div className="nuterient-calculator-counter flex-all flex-wrap">
        <h5 className="nuterient-calculator-label">Additional grow<br></br> cycles </h5>
        <div className="flex-start flex-wrap">
          <button
            className="btn btn-outline-secondary btn-minus btn-calc-plus flex-all"
            id="cycles"
            onClick={(e) => this.decrement(e)}
          >
            <div>-</div>
          </button>

          <input
            type="number"
            className="form-control quantity"
            value={this.props.value}
            onChange={(event) => {
              if(this.props.value >= 0) {
                this.props.onChange(this.props.value);
              }
            }}
            readOnly
          ></input>

          <button
            className="btn btn-outline-secondary btn-plus btn-calc-plus flex-all"
            
            onClick={(e) => this.increment(e)}
          >
            <div>+</div>
          </button>
        </div>
      </div>
    );
  }
}
