// @flow
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { ButtonGroup, Dropdown, Spinner } from "react-bootstrap";

import { ReactComponent as CrtDownIcon } from "./../../../assets/svg/ctrl-down.svg";

import "./Dropdown.scss";

/**
 * @param {object} props Component props
 * @param {Array} props.items
 * @param {function} props.onChange
 * @param {function} props.onChangeInput - for readonly event (get the state.value)
 * @param {Object[]} props.items
 * @param {object} props.items[].key
 * @param {object} props.items[].value
 * @param {object} props.items[].label
 * @param {object} props.selected
 * @param {object} props.selected.value
 * @param {object} props.selected.label
 */
export default function DropdownItem(props) {
  const [state, setState] = useState({
    items: [],
    value: props.intialValueInput || "",
  });
  const [show, setShow] = useState(false);
  const dropdownMenuRef = React.useRef();

  function onChangeValue(value, fromSelection) {
    value = typeof value === "string" ? value : "";
    const valueCompare = value.toLowerCase();

    let itemsSorted = props.noInput
      ? props.items
      : (() => {
          return props.useSort
            ? props.items.sort((item1, item2) => {
                const label1 =
                  typeof item1.label === "string" ? item1.label : "";
                const label2 =
                  typeof item2.label === "string" ? item2.label : "";
                const index1 = label1.toLowerCase().indexOf(valueCompare);
                const index2 = label2.toLowerCase().indexOf(valueCompare);

                if (index1 === index2) {
                  return 0;
                }
                return index1 < index2 ? -1 : 1;
              })
            : props.items.filter((item1) => {
                if (props.noFilter) {
                  return true;
                }
                const label1 =
                  typeof item1.label === "string" ? item1.label : "";
                const index1 = label1.toLowerCase().indexOf(valueCompare);
                return index1 > -1;
              });
        })();
    setState({
      ...state,
      value,
      items: itemsSorted.length ? itemsSorted : props.items,
    });
    props.onChangeInput && props.onChangeInput(value, fromSelection);
    setShow(false);
  }
  function onClickOutside(event) {
    const target = event.target;
    if (dropdownMenuRef.current) {
      const node = dropdownMenuRef.current.parentNode;
      if (node.contains(target)) {
        // setShow(true);
        return;
      }
    }
    setShow(false);
  }
  useEffect(() => {
    onChangeValue(props.value, true);
  }, [props.items.length]);
  useEffect(() => {
    onChangeValue(props.selected?.label || props.intialValueInput || "", true);
  }, [props.selected?.label]);

  useEffect(() => {
    window.addEventListener("click", onClickOutside);
    return () => {
      window.removeEventListener("click", onClickOutside);
    };
  }, []);
  return (
    <Dropdown as={ButtonGroup} show={show}>
      <div
        className={`my-dropdown${props.className ? " " + props.className : ""}`}
      >
        <div ref={dropdownMenuRef} className="my-dropdown-inner">
          <Dropdown.Toggle split variant="success" className="dropdown-right">
            <div className="dropdown-left">
              <input
                type="text"
                value={state.value}
                onChange={(event) => {
                  props.onChangeText && props.onChangeText(event);
                  onChangeValue(event.target.value);
                }}
                onFocus={() => {}}
                readOnly={Boolean(props.noInput)}
                placeholder={props.displayValue}
              />
            </div>

            <div
              className="dropdown-right-inner"
              onClick={() => {
                setShow(!show);
              }}
            >
              {props.loading && (
                <Spinner as="span" animation="border" variant="light" />
              )}{" "}
              {!props.loading && <CrtDownIcon />}{" "}
            </div>
          </Dropdown.Toggle>
        </div>
      </div>

      {Boolean(state.items.length) && (
        <Dropdown.Menu className="my-app-dropdown-options">
          {state.items.map((item, index) => {
            return (
              <Dropdown.Item
                onClick={() => {
                  props.onChange && props.onChange(item, index);
                }}
                className="flex-all"
                eventKey="1"
                key={`${item.key}-${index}-dropdown`}
              >
                {item.label}
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      )}
    </Dropdown>
  );
}
