import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import axios from "axios";
import { BASE_URL } from "./app/utils/constants";

axios.defaults.baseURL = BASE_URL;
window.BASE_URL = BASE_URL;


window.__dev__ = /^(http:\/\/localhost)/.test(window.location.href) ? 1 : 0;

ReactDOM.render(
  
    <React.Fragment>
      <App />
    </React.Fragment>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
