import * as React from "react";

import recommendedParts from "./../../../../../assets/image/recommended_parts.png";
import chainImg from "./../../../../../assets/image/chainImg.png";

export default function RecommendedParts(props) {
  const [state, setState] = React.useState({
    user: 2,
  });

  return (
    <div className="recommended-3rd-party-parts-popup">
      <div className="build-logo-wrapper">
        <img src={recommendedParts} alt={"recomm_parts_image.png"} />
        <h1>
          RECOMMENDED 3RD
          <br />
          PARTY PARTS
        </h1>
      </div>
      <div className="build-description">
        To complete your build FloraFlex recommends the addition of the
        following components, or a similarly rated alternative. <br />
        These components are not available directly through FloraFlex.com.
      </div>
      <div className="components-wrapper">
        {props?.thirdPartyParts?.pump?.title !== "" && (
          <div className="component">
            <div className="component-title">
              <div className="text">PUMP</div>
            </div>
            <div className="components-list">
              <a href={props?.thirdPartyParts?.pump?.url} target="_blank">
                <div className="component-line main-comp bbottom">
                  <div className="flex flex-start flex-1">
                    <img src={chainImg} alt="chain-img.png" />
                    <div className="main-comp-info">
                      <p className="text">
                        {props?.thirdPartyParts?.pump?.title}
                      </p>
                      <p className="subtext">
                        {props?.thirdPartyParts?.pump?.subtitle}
                      </p>
                    </div>
                  </div>
                  <div className="main-comp-value">
                    {props?.thirdPartyParts?.pump?.qty}
                  </div>
                </div>
              </a>
              {props?.thirdPartyParts?.pump?.min_psi && (
                <div className="component-line desc-comp bbottom">
                  <div className="comp-label">MINIMUM PSI FOR YOUR BUILD</div>
                  <div className="comp-value">
                    {props?.thirdPartyParts?.pump?.min_psi}
                  </div>
                </div>
              )}
              {props?.thirdPartyParts?.pump?.min_bpm && (
                <div className="component-line desc-comp">
                  <div className="comp-label">MINIMUM GPM FOR YOUR BUILD</div>
                  <div className="comp-value">
                    {props?.thirdPartyParts?.pump?.min_bpm}
                  </div>
                </div>
              )}
              {props?.thirdPartyParts?.pump?.help_note && (
                <div className="component-line desc-comp">
                  <div className="comp-label">{props?.thirdPartyParts?.pump?.help_note}</div>
                </div>
              )}
            </div>
          </div>
        )}

        {props?.thirdPartyParts?.controller?.title !== "" && (
          <div className="component">
            <div className="component-title">
              <div className="text">CONTROLLER</div>
            </div>
            <div className="components-list">
              <a href={props?.thirdPartyParts?.controller?.url} target="_blank">
                <div className="component-line main-comp bbottom">
                  <div className="flex flex-start">
                    <img src={chainImg} alt="chain-img.png" />
                    <div className="main-comp-info">
                      <p className="text">
                        {props?.thirdPartyParts?.controller?.title}
                      </p>
                      <p className="subtext">
                        {props?.thirdPartyParts?.controller?.subtitle}
                      </p>
                    </div>
                  </div>
                  <div className="main-comp-value">
                    {props?.thirdPartyParts?.controller?.qty}
                  </div>

                </div>
	            <div className="component-line desc-comp">
                  <div className="comp-label">ZONES WITHIN YOUR BUILD</div>
                  <div className="comp-value">
                    {props?.thirdPartyParts?.controller?.zones}
                  </div>
                </div>
              </a>
            </div>
            {props?.thirdPartyParts?.expansion?.name !== "" && (
	            <div>
	            	<br /><br /><br />
					<div className="component-title">
						<div className="text">EXPANSION MODULE</div>
					</div>
		            <div className="components-list">
		              <a href={props?.thirdPartyParts?.expansion?.url} target="_blank">
		                <div className="component-line main-comp bbottom">
		                  <div className="flex flex-start">
		                    <img src={chainImg} alt="chain-img.png" />
		                    <div className="main-comp-info">
		                      <p className="text">
		                        {props?.thirdPartyParts?.expansion?.name}
		                      </p>
		                    </div>
		                  </div>
		                  <div className="main-comp-value">
		                    {props?.thirdPartyParts?.expansion?.qty}
		                  </div>
		                </div>
		              </a>
		            </div>
		         </div>
	           )}
          </div>
        )}
        {props?.thirdPartyParts?.regulator?.title !== "" && (
          <div className="component">
            <div className="component-title">
              <div className="text">30 PSI REGULATOR</div>
            </div>
            <div className="components-list">
              <a href={props?.thirdPartyParts?.regulator?.url} target="_blank">
                <div className="component-line main-comp bbottom">
                  <div className="flex flex-start">
                    <img src={chainImg} alt="chain-img.png" />
                    <div className="main-comp-info">
                      <p className="text">
                        {props?.thirdPartyParts?.regulator?.title}
                      </p>
                      <p className="subtext">
                        {props?.thirdPartyParts?.regulator?.subtitle}
                      </p>
                    </div>
                  </div>
                  <div className="main-comp-value">
                    {props?.thirdPartyParts?.regulator?.qty}
                  </div>
                </div>
              </a>
              <div className="component-line desc-comp">
                <div className="comp-label">ZONES WITHIN YOUR BUILD</div>
                <div className="comp-value">
                  {props?.thirdPartyParts?.regulator?.zones}
                </div>
              </div>
              {props?.thirdPartyParts?.regulator?.help_note && (
                <div className="component-line desc-comp">
                  <div className="comp-label">{props?.thirdPartyParts?.regulator?.help_note}</div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
