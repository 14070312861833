import * as React from "react";
import { Spinner } from "react-bootstrap";
import "./Button.scss";

/**
 *
 * @param {object} props
 * @param {string|undefined} variant
 * @param {string|undefined} className
 * @param {string} text
 * @param {boolean} disabled
 * @param {boolean} loading
 */
export default function Button(props) {
  return (
    <button
      id={props.id || ""}
      onClick={typeof props.onClick === "function" ? props.onClick : () => {}}
      disabled={props.disabled || props.loading}
      className={
        `custom-button` +
        (props.variant ? " " + props.variant : "") +
        (props.className ? " " + props.className : "")
      }
      onMouseOver={
        typeof props.onMouseOver === "function" ? props.onMouseOver : () => {}
      }
      onMouseOut={
        typeof props.onMouseOut === "function" ? props.onMouseOut : () => {}
      }
      style={props.style ? props.style : {}}
    >
      {props.loading && (
        <div className="flex-all width-100">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
      {props.loading ? "" : props.text}
    </button>
  );
}
