// @flow
import * as React from "react";

import layout_logo from "./../../../../../assets/image/RoomBuilderLogoGreen.png";
import layout_logo_new from "./../../../../../assets/image/RoomBuilderLogo_Small_Glow_NEW.png";
// import controls3d from "./../../../../../assets/image/three-d/controls-3d.png";
import controls2d from "./../../../../../assets/image/tooltips/tooltip2D-complex.png";
import controls3d from "./../../../../../assets/image/tooltips/tooltip3D-simple.png";

import cameraSvg from "./../../../../../assets/svg/camera.svg";
import Switch from "./../../../../components/Switch/Switch";
import Button from "../../../../components/Button/Button";
import { triggerCustomEvent } from "../../../../utils/utilFunctions";
import axios from "axios";
import imgPlaceholder from "./../../../../../assets/image/imgPlaceholder-3d.jpg";
import Room2dCanvas from "../../Canvas/Room2dCanvas";
import plusSvg from "../../../../../assets/svg/plus.svg";
import minusSvg from "../../../../../assets/svg/minus.svg";
import shareSvg from "../../../../../assets/svg/share.svg";

import { useRef } from "react";

/**
 * xeoglRenderObj - renderjs functions
 * window.xeoglRenderObj = {
	initXeoglSetup,
	buildRoom,
	diff,
	getPotCoordiantes,
	i2m,
	m2i,
	buildRoom
}
 *
*/

const maxTries = 20;
const adjustTries = 2;
let timeout_ = 0;
let wait2dTimeout = 0;
let ss3DTimeout = 0;
/**
 *
 * @param {array} trays
 * @param {object} roomSize
 * @param {number|string} roomSize.roomLength
 * @param {number|string} roomSize.roomWidth
 * @param {string} growarea
 * @param {string} container
 * @param {string} irrigation
 * @param {string} density
 */
export default function Kit3DCanvas(props) {
  const [state, setState] = React.useState({ use3D: false });
  const [imgUrl, _setImgUrl] = React.useState({
    tries: 0,
    img: "",
    img2: "",
  });
  const [canvasConfig, _setCanvasConfig] = React.useState({
    width: window.innerWidth - 397, // 395px from left side bar
    height: window.innerHeight - 55, // 119px for header + navbar + 43px for the canvas header
    zoomDir: 1,
    zoomKey: "",
    key: Date.now() + "canvas",
  });
  const [showControls, setShowControls] = React.useState();

  const containerRef = useRef();

  const [screenShotState, setScreenShotState] = React.useState({
    triggerKey: undefined,
    isFetching: false,
  });

  const stateRef = React.useRef(canvasConfig);
  function setCanvasConfig(_config) {
    stateRef.current = _config;
    _setCanvasConfig(_config);
  }

  function switchDisplays(_use3D) {
    setState({
      ...state,
      use3D: _use3D,
    });
  }

  function init() {
    window.KitBuilder3D_loaded = false;

    const growarea = props.growarea.toUpperCase() || "STANDARD TRAY";
    const container = props.container || "8IN POTPRO POT";
    const irrigation = props.irrigation || "BUBBLER & FLORA CAP";
    const density = props.density || "8 PLANT PER 4X4 AREA";
    const trayType = props.plumbing.value || "";

    // Set up some varibles to be used by platforms
    let setDripperStyleLabel;

    if (!props.dripperStyle) {
      setDripperStyleLabel = "none";
    } else {
      setDripperStyleLabel = props.dripperStyle.label;
    }

    let dripperPerPlantLabel;
    if (!props.dripperPerPlant) {
      dripperPerPlantLabel = "none";
    } else {
      dripperPerPlantLabel = props.dripperPerPlant.label;
    }

    const dripperStyle = setDripperStyleLabel || "1 WAY";
    const dripperPerPlant = dripperPerPlantLabel || "2 DRIPPER PER PLANT";

    const room_width = props.roomSize.roomWidth;
    const room_length = props.roomSize.roomLength;
    const trays = props.trays.reduce((final, currentTray) => {
      final.push({
        tray_name: currentTray.label,
        tray_width: currentTray.widthVal,
        tray_length: currentTray.lengthVal,
        pos_x: currentTray.offsetX,
        pos_y: currentTray.offsetY,
        rotation: currentTray.isRotated ? "1" : "0", //for 2d we used the opposite
      });
      return final;
    }, []);
    const canvasName = "3D_Canvas";
    const image2D = "2Dimage";

    if (/^(http:\/\/localhost)/.test(window.location.href) && 0) {
      trigger3DLoaded();
    } else {
      if (typeof window.xeoglRenderObj?.initXeoglSetup === "function") {
        // try {
        window.KitBuilder3D_loaded = false;
        triggerCustomEvent("open-loader-3d-canvas");

        if (Number(props.growAreId) !== 1) {
          const payload = {
            growarea_: growarea,
            container_: container,
            irrigation_: irrigation,
            density_: density,
            room_width_: room_width,
            room_length_: room_length,
            trays_: trays,
            canvasName_: canvasName,
            image2D_: image2D,
            totalPots_: props.calculateNumOfPlants() || 8,
            trayType_: trayType,
            dripperStyle_: dripperStyle,
            dripperPerPlant_: dripperPerPlant,
          };
          window.init3dDev = payload;
          window.xeoglRenderObj.initXeoglSetup(payload);
        } else {
          const payload = {
            growarea_: growarea,
            container_: container,
            irrigation_: irrigation,
            density_: density,
            room_width_: room_width,
            room_length_: room_length,
            trays_: trays,
            canvasName_: canvasName,
            image2D_: image2D,
            totalPots_: props.calculateNumOfPlants() || 8,
            trayType_: trayType,
            dripperStyle_: dripperStyle,
            dripperPerPlant_: dripperPerPlant,
            //new - start
            platformXcoord_: props.platformData.platformXcoord,
            platformYcoord_: props.platformData.platformYcoord,
            platformNumRows_: props.platformData.platformNumRows,
            platformPlatformsPerRow_:
              props.platformData.platformPlatformsPerRow,
            platformLinkLength_: props.platformData.platformLinkLength,
            platformWalkwayWidth_: props.platformData.platformWalkwayWidth,
            platformSubzoneWidth_: props.platformData.platformSubzoneWidth,
            platformDrainageDirection_:
              props.platformData.platformDrainageDirection,
            //new - end
          };
          window.xeoglRenderObj.initXeoglSetupSystem5(payload);
          window.init3dDev = payload;
        }

        // } catch (err) {
        //   console.log("ERROR FROM xeogl SCRIPT - 3D NOT LOADED", err);
        //   trigger3DLoaded();
        // }
      } else {
        trigger3DLoaded();
      }
    }

    /**
     * get image from 2d canvas
     */
    // wait2dTimeout = setTimeout(() => {
    //   triggerCanvas2DExport();
    // }, 1000);
  }
  const imgRef = React.useRef(imgUrl);
  function setImgUrl(imgSt) {
    _setImgUrl(imgSt);
    imgRef.current = imgSt;
  }
  function take3dScreenShot() {
    ss3DTimeout = setTimeout(() => {
      const canvasNode = document.getElementById("3D_Canvas");
      if (canvasNode) {
        const dataURL = canvasNode.toDataURL();
        export3DFile(dataURL);
        setState({
          ...state,
          loaded3D: true,
        });
        trigger3DLoaded();
      }
    }, 1000);
  }
  function takeSnapShot() {
    const canvasNode = document.getElementById("3D_Canvas");
    if (
      imgRef.current.tries <= maxTries &&
      canvasNode &&
      canvasNode.toDataURL
    ) {
      if (
        imgRef.current.tries === adjustTries &&
        typeof window.xeoglRenderObj?.hideWalls === "function"
      ) {
        window.xeoglRenderObj?.hideWalls();
        if (typeof window.xeoglRenderObj.zoom_camera === "function") {
          window.xeoglRenderObj.zoom_camera(-9);
          setTimeout(() => {
            // new window.xeogl.CameraFlightAnimation(window._camera).flyTo(window.theFloor);
          }, 100);
        }
      }
      const dataURL = canvasNode.toDataURL();
      setImgUrl({
        img: dataURL,
        img2: "",
        tries: imgRef.current.tries + 1,
      });
      if (
        imgRef.current.tries >= maxTries &&
        typeof window.scrollIntoView_ === "function"
      ) {
        clearTimeout(timeout_);
        timeout_ = setTimeout(() => {
          if (typeof window.xeoglRenderObj?.showWalls === "function") {
            window.xeoglRenderObj?.showWalls();
          }
          window.xeoglRenderObj?.scrollIntoView_();
          take3dScreenShot();
        }, 1000);
      }
    }
  }
  function trigger3DLoaded() {
    window.KitBuilder3D_loaded = true;
    triggerCustomEvent("kit-builder-c-e-3d-loaded", {});
  }

  function export2DFile(encodedFile) {
    //SAVE IMG ON SERVER
    let room_id = props?.selectedRoom?.id;
    let room_width = props?.selectedRoom?.room_data?.room_size?.width;
    let room_length = props?.selectedRoom?.room_data?.room_size?.length;

    if (room_id) {
      axios
        .post(`${window.BASE_URL}rbapi/save2DFile.php`, {
          roomID: room_id,
          encodedFile,
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .post(`${window.BASE_URL}rbapi/save2DFileWatermark.php`, {
          roomID: room_id,
          encodedFile,
          room_width,
          room_length,
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  function export3DFile(dataURL) {
    //SAVE IMG ON SERVER
    let room_id = props?.selectedRoom?.id;
    let room_width = props?.selectedRoom?.room_data?.room_size?.width;
    let room_length = props?.selectedRoom?.room_data?.room_size?.length;

    if (room_id && room_width && room_length) {
      room_width = parseInt(room_width) * 16.8;
      room_length = parseInt(room_length) * 16.8;
      axios
        .post(`${window.BASE_URL}rbapi/save3DFile.php`, {
          roomID: room_id,
          room_width: room_width,
          room_length: room_length,
          encodedFile: dataURL,
        })
        .then((res) => {})
        .catch((error) => {
          console.log(error);
        });
    }
  }

  /**
   * 2D CANVAS FUNCTIONS
   */
  function resetZoom() {
    if(window.canvasView == 1){
      window.xeoglRenderObj.switchCanvasTo2D();
    }
    else {
      window.xeoglRenderObj.setInitial3Dview();
    }
  }

  function zoomIn() {
    setCanvasConfig({
      ...canvasConfig,
      zoomKey: Date.now(),
      zoomType: "IN",
    });
    window.scene.camera.project.scale = window.scene.camera.project.scale - 2;
  }

  function zoomOut() {
    setCanvasConfig({
      ...canvasConfig,
      zoomKey: Date.now(),
      zoomType: "OUT",
    });
    window.scene.camera.project.scale = window.scene.camera.project.scale + 2;
  }

  function triggerCanvas2DExport() {
    setCanvasConfig({
      ...canvasConfig,
      zoomKey: Date.now(),
      zoomType: "EXPORT",
    });
  }

  function onClickCamera(skipLoader) {
    if (!skipLoader && !screenShotState.isFetching) {
      setScreenShotState({
        ...screenShotState,
        isFetching: true,
        triggerKey: Date.now(),
      });
    } else {
      const canvasNode = state.use3D
        ? document.getElementById("3D_Canvas")
        : document.querySelector("#Canvas2D_Parent canvas");
      if (canvasNode) {
        canvasNode.click();
        if (state.use3D) {
          try {
            window._camera.eye = window._camera.eye;
          } catch (err) {
            console.log(err);
          }
        }
        /**
         * avoid crash from cross-origin error (when in localhost) with the canvas 2d (which loads images from another place)
         *
         */
        let dataURL = "";
        try {
          dataURL = canvasNode.toDataURL();
        } catch (err) {
          alert("image cross domain error: ".err?.message);
        }
        const formData = new FormData();
        formData.append("encodedFile", dataURL);
        formData.append("roomId", props?.selectedRoom?.id);

        axios
          .post(`${window.BASE_URL}rbapi/saveScreenshot.php`, formData)
          .then(({ data }) => {
            if (data?.success === 1) {
              const cartAnchorNode = document.getElementById(
                "camera-screenshot-canvas"
              );
              if (cartAnchorNode) {
                cartAnchorNode.setAttribute(
                  "href",
                  `${window.BASE_URL}${data.url}`
                );
                cartAnchorNode.click();
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setScreenShotState({
              ...screenShotState,
              isFetching: false,
            });
          });
      } else {
        setScreenShotState({
          ...screenShotState,
          isFetching: false,
        });
      }
    }
  }
  function onResize(event) {
    // const _newConfig = {
    //   ...stateRef.current,
    //   width: containerRef.current.clientWidth,
    //   height: containerRef.current.clientHeight,
    // };
    // setCanvasConfig(_newConfig);
  }

  React.useEffect(() => {
    window.addEventListener("resize", onResize);
    window.addEventListener("xeogl-3d-loaded-room-builder", takeSnapShot);
    window.devTrigger3DLoaded = trigger3DLoaded;
    init();

    return () => {
      window.removeEventListener("xeogl-3d-loaded-room-builder", takeSnapShot);
      if (typeof window.xeoglRenderObj?.destroy_xeogl_Scene === "function") {
        // window.xeoglRenderObj?.destroy_xeogl_Scene();
      }
      window.KitBuilder3D_loaded = false;
      clearTimeout(timeout_);
      clearTimeout(wait2dTimeout);
      clearTimeout(ss3DTimeout);
      window.removeEventListener("resize", onResize);
    };
  }, []);

  function switchCanvasTo2D() {
    if (imgRef.current.img2) {
      return;
    }
    window.xeoglRenderObj.switchCanvasTo2D();
  }


    //   ss3DTimeout = setTimeout(() => {
    //
	// 	let room_id = props?.selectedRoom?.id;
	// 	let room_width = props?.selectedRoom?.room_data?.room_size?.width;
	// 	let room_length = props?.selectedRoom?.room_data?.room_size?.length;
    //
    //
    //     const canvasNode = document.getElementById("3D_Canvas");
    //     if (canvasNode) {
    //       var dataURL = canvasNode.toDataURL();
    //
	// 	  var croppedURL = "";
    //
	//       axios
	//         .post(`${window.BASE_URL}rbapi/crop2D.php`, {
	// 	      roomID: room_id,
	//           encodedFile: dataURL,
	//         })
	//         .then((res) => {
	// 	        setImgUrl({
	// 				...imgRef.current,
	// 				img2: res.data,
	// 				img2Key: `img-2-${Date.now()}`
	// 			});
	//         })
	//         .catch((error) => {
	//           console.log(error);
	//         });
    //     }
    //   }, 1000);
    // } catch (err) {
    //   console.log("Error while 2d switching", err);
    // }


  function switchCanvasTo3D() {
    try {
      window.xeoglRenderObj.switchCanvasTo3D();
      //window.xeoglRenderObj.switchCanvasTo3D();
    } catch (err) {
      console.log("Error while 3d switching", err);
    }
  }

  React.useEffect(() => {
    if (state.loaded3D === true) {
      if (state.use3D) {
        switchCanvasTo3D();
      } else {
        switchCanvasTo2D();
      }
    }
  }, [state.use3D, state.loaded3D]);

  React.useEffect(() => {
    if (screenShotState.triggerKey > Date.now() - 3000) {
      onClickCamera(true);
    }
  }, [screenShotState.triggerKey]);

  return (
    <>
      <div className="canvas-3d-rm-">
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
          }}
        >
          <canvas
            id="3D_Canvas"
            style={{
              width: "100%",
              height: "100%",
              backgroundColor: "white",
            }}
          ></canvas>
        </div>

      </div>
      <div className="position-absolute --left-pos --top-pos width-100 flex-all canvas-3d-r-c-switch">
        <Switch
          listValues={[
            { value: "2d", label: "2D" },
            { value: "3d", label: "3D" },
          ]}
          value={state.use3D ? "3d" : "2d"}
          onSwitch={() => {
            switchDisplays(!state.use3D);
          }}
        />{" "}
      </div>
      <div
        className="layout-control canvas-3d-layout-control"
        ref={containerRef}
      >
        <div className="kit-layout-control">
          {!state.use3D ?
            <>
              <Button
                text={<img src={plusSvg}/>}
                variant="control control-sign"
                onClick={() => zoomIn()}
              />
              <Button
                text={<img src={minusSvg}/>}
                variant="control control-sign"
                onClick={() => zoomOut()}
              />
            </>
            : <></>
          }
          <Button
            text="CENTER"
            id="center-canvas-btn-id"
            variant="control control-text"
            onClick={resetZoom}
          />

          <a
            href={`${window.BASE_URL}roombuilder_pdf/index.php?room_id=${props?.selectedRoom?.id}`}
            target="_blank"
          >
            <Button text="EXPORT" variant="control control-text" />
          </a>
          {/* <a
            href={`${window.BASE_URL}rooms/${props?.selectedRoom?.id}/2D/image.png`}
            target="_blank"
            onClick={() => {
              triggerCanvas2DExport();
            }}
          > */}
          <Button
            text={<img src={cameraSvg} />}
            variant="control control-camera"
            onClick={() => onClickCamera()}
            loading={screenShotState.isFetching}
          />
          {/* </a> */}
          {/* <OverlayTrigger
            placement="top"
            delay={{ show: 0, hide: 40 }}
            overlay={(thisprops) => (
              <Tooltip id="generic-tooltip-id" {...thisprops}>
                <div>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Aliquam sit amet purus diam. Vestibulum enim lectus, finibus
                  eget neque ultrices, rutrum mollis lorem. Sed suscipit, eros
                  ut pretium tincidunt, tortor elit maximus augue, at porta nibh
                  felis sed turpis. Nunc cursus dictum ultrices. Nullam semper,
                  massa sed euismod faucibus, nulla lectus fermentum est, vitae
                  vestibulum risus elit in tellus. Aliquam ex diam, semper porta
                  quam quis, rutrum dapibus turpis. Sed nisi magna, venenatis in
                  magna id, sagittis ultricies felis. Vestibulum sit amet leo
                  urna. Interdum et malesuada fames ac ante ipsum primis in
                  faucibus. Integer turpis purus, malesuada sit amet maximus ut,
                  tempus at orci. Donec faucibus ipsum eu erat commodo molestie.
                  Pellentesque est tellus, congue iaculis malesuada in, pharetra
                  in augue.
                </div>
              </Tooltip>
            )}
          > */}
          <div id="tooltip-control-question-button">
            <Button
              text="?"
              variant="control control-question"
              onMouseOver={() => setShowControls(true)}
              onMouseOut={() => setShowControls(false)}
            />
            {showControls && (
              <div className="abs-tooltip-image">
                <img src={state.use3D ? controls3d : controls2d} />
              </div>
            )}
          </div>
          <Button
            text={<img src={shareSvg} />}
            variant="control control-sign"
            className="flex-all control-3d-share-btn"
            onClick={() => props.shareRoom()}
          />
        </div>
        <div className="kit-layout-logo">
          <img src={layout_logo_new} alt="layout-logo"></img>
        </div>
        {/* {showControls && (
                    <div className="position-absolute --right-pos kit-layout-control-img">
                        <img src={controls3d} />
                    </div>
                )} */}
      </div>
    </>
  );
}
