import React, { Component } from "react";
import Counter from "./components/Counter";

import SliderCounter from "./components/SliderCounter/SliderCounter";
import Switch from "./../../../components/Switch/Switch";

import "./styles/index.scss";
import { CardWallet } from "./components/CardWallet/CardWallet";
import { gql, ApolloConsumer } from "@apollo/client";
import nutrientIcon from "./../../../../assets/image/nutrients_logo_black.png";

import * as constants from "./../../../utils/constants";
import { Spinner } from "react-bootstrap";

import vegetativeFoliar from "./styles/media/VegFoliar.png";
import v1logo from "./styles/media/V1.png";
import v2logo from "./styles/media/V2.png";
import bloomFoliarLogo from "./styles/media/BloomFoliar.png";
import b1logo from "./styles/media/B1.png";
import b2logo from "./styles/media/B2.png";
import fullTiltLogo from "./styles/media/FullTilt.png";
import arrow from "../../../../assets/image/arrowTop.png";
import footerGreenLogo from "../../../../assets/image/footerGreenLogo.png";

const v1Nv2Tooltip = (
  <>
    <p>
      V1™ and V2™ combine as a two part water soluble formula, providing
      essential nutrients needed for vigorous plant growth throughout the entire
      vegetative stage.
    </p>
    <p>
      DIRECTIONS FOR USE:<br></br>
      Add V1™ and then V2™ to your water source throughout the entire vegetative
      stage in the grams per galllon amounts, for each week, listed in the Full
      Tilt Schedule™.
    </p>{" "}
    <p>See floraflex.com/nutrients for more detailed mixing instructions.</p>
  </>
);
const b1Nb2Tooltip = (
  <>
    <p>
      B1™ and B2™ combine as a two part water soluble formula, providing
      essential nutrients needed for vigorous plant growth throughout the entire
      flowering stage.
    </p>
    <p>
      DIRECTIONS FOR USE:<br></br>
      Add B1™ and then B2™ to your water source during the flowering stage in
      the grams per galllon amounts, for each week, listed in the Full Tilt
      Schedule™.
    </p>{" "}
    <p>See floraflex.com/nutrients for more detailed mixing instructions.</p>
  </>
);
const productToolTips = {
  vegetativeFoliar: (
    <>
      <p>
        FloraFlex<sup>®</sup> Veg Foliar Fertilizer promotes vigorous plant
        growth by providing essential elements directly to the plants exterior
        through the stomata and epidermis throughout the vegetative stage.
      </p>
      <p>
        DIRECTIONS FOR USE:<br></br>
        Mix 4-5 grams per gallon (4 Liters) of water and spray during only days
        1, 7, 14, and 21 of Veg.
      </p>{" "}
    </>
  ),
  v1logo: v1Nv2Tooltip,
  v2logo: v1Nv2Tooltip,
  bloomFoliarLogo: (
    <>
      <p>
        FloraFlex<sup>®</sup> Bloom Foliar Fertilizer is an early Bloom Foliar
        that promotes quick setting flower sites by providing essential elements
        directly to the plants exterior through the stomata and epidermis during
        the first few weeks of the flowering stage.
      </p>
      <p>
        DIRECTIONS FOR USE:<br></br>
        Mix 4-5 grams per gallon (4 Liters) of water and during only days 1, 7,
        and 14 of Bloom.
      </p>{" "}
    </>
  ),
  b1logo: b1Nb2Tooltip,
  b2logo: b1Nb2Tooltip,
  fullTiltLogo: (
    <>
      <p>
        Full Tilt™ is a water soluble formula, it helps with ripening and plant
        growth in last weeks prior to flush and increases sugar or Brix levels.
      </p>
      <p>
        DIRECTIONS FOR USE:<br></br>
        Add Full Tilt™ to your water source after other inputs (B1™ and/or B2™)
        are completely dissolved. Add to your water source in the grams per
        galllon amounts, for each week, listed in the Full Tilt Schedule™.
      </p>{" "}
    </>
  ),
};

//import all pdfs
let noMods = `/pdf/FullTilt_Schedule_Export_NoMods.pdf`;
let singleBloom = `/pdf/FullTilt_Schedule_Export_1xBloom.pdf`;
let doubleBloom = `/pdf/FullTilt_Schedule_Export_2xBloom.pdf`;
let tripleBloom = `/pdf/FullTilt_Schedule_Export_3xBloom.pdf`;
let doubleFlash = `/pdf/FullTilt_Schedule_Export_2xFlush.pdf`;
let doubleFlashBloom = `/pdf/assets/pdf/FullTilt_Schedule_Export_2xFlush_1xBloom.pdf`;
let doubleFlashdoubleBloom = `/pdf/FullTilt_Schedule_Export_2xFlush_2xBloom.pdf`;
let doubleFlashtripleBloom = `/pdf/FullTilt_Schedule_Export_2xFlush_3xBloom.pdf`;

class NutrientCalculator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customer_id: "",
      client_cart_id: "",

      config: {
        products: [],
        how_many_plants_range: {
          min: 0,
          max: 5000,
        },
        volume_of_pot_range: {
          min: 0.5,
          max: 25,
        },
        additional_bloom_weeks_range: {
          min: 0,
          max: 3,
        },
      },
      configDataLoaded: false,

      cartItemsClient: [],
      productsDisabled: {},
      productsFetching: {},
      calculationExpand: false,
    };
    /**
     *  window.BASE_URL is not assign when this class is defined, so we make this assignment in the constructor
     */
    noMods = `${window.BASE_URL}pdf/FullTilt_Schedule_Export_NoMods.pdf`;
    singleBloom = `${window.BASE_URL}pdf/FullTilt_Schedule_Export_1xBloom.pdf`;
    doubleBloom = `${window.BASE_URL}pdf/FullTilt_Schedule_Export_2xBloom.pdf`;
    tripleBloom = `${window.BASE_URL}pdf/FullTilt_Schedule_Export_3xBloom.pdf`;
    doubleFlash = `${window.BASE_URL}pdf/FullTilt_Schedule_Export_2xFlush.pdf`;
    doubleFlashBloom = `${window.BASE_URL}pdf/assets/pdf/FullTilt_Schedule_Export_2xFlush_1xBloom.pdf`;
    doubleFlashdoubleBloom = `${window.BASE_URL}pdf/FullTilt_Schedule_Export_2xFlush_2xBloom.pdf`;
    doubleFlashtripleBloom = `${window.BASE_URL}pdf/FullTilt_Schedule_Export_2xFlush_3xBloom.pdf`;
  }

  formatNumber = (input) => {
    return (Number(input) || 0).toLocaleString("en", {
      minimumFractionDigits: 2,
    });
  };

  init = () => {
    const jsonNode = document.getElementById(
      "nutrient-calculator-customer-data-json"
    );
    if (jsonNode) {
      let detail = null;
      try {
        detail = JSON.parse(jsonNode.innerText);
      } catch (err) {
        console.log({ err });
      }
      if (detail && detail.customer_id) {
        this.setState({
          customer_id: detail.customer_id,
        });

        const onResolve = (result) => {
          const client_cart_id = result?.data?.customerCart?.id;
          const cartItemsClient = result?.data?.customerCart?.items || [];

          this.setState({
            client_cart_id,
            cartItemsClient,
            ...this.onCartItemsClientChange(cartItemsClient),
          });
        };
        if (window.__dev__ === 1) {
          const result = {
            data: {
              customerCart: {
                id: "rX2Gjc9RPHERGIbbe9P0MwrYl5tLnTx4",
                items: [
                  {
                    id: "62",
                    product: {
                      name: "Strive Shoulder Pack",
                      sku: "24-MB04",
                      __typename: "SimpleProduct",
                    },
                    quantity: 3,
                    __typename: "SimpleCartItem",
                  },
                  {
                    id: "63",
                    product: {
                      name: "Crown Summit Backpack",
                      sku: "24-MB03",
                      __typename: "SimpleProduct",
                    },
                    quantity: 1,
                    __typename: "SimpleCartItem",
                  },
                  {
                    id: "64",
                    product: {
                      name: "Savvy Shoulder Tote",
                      sku: "24-WB05",
                      __typename: "SimpleProduct",
                    },
                    quantity: 2,
                    __typename: "SimpleCartItem",
                  },
                  {
                    id: "65",
                    product: {
                      name: "Endeavor Daytrip Backpack",
                      sku: "24-WB06",
                      __typename: "SimpleProduct",
                    },
                    quantity: 1,
                    __typename: "SimpleCartItem",
                  },
                  {
                    id: "66",
                    product: {
                      name: "Quest Lumaflex&trade; Band",
                      sku: "24-UG01",
                      __typename: "SimpleProduct",
                    },
                    quantity: 1,
                    __typename: "SimpleCartItem",
                  },
                ],
                __typename: "Cart",
              },
            },
          };
          onResolve(result);
        } else {
          this.props.client
            .query({
              query: gql`
                {
                  customerCart {
                    id
                    items {
                      id
                      product {
                        name
                        sku
                      }
                      quantity
                    }
                  }
                }
              `,
            })
            .then((loggedUserCard) => {
              onResolve(loggedUserCard);
            })
            .catch((error) =>
              console.log("ERROR WHILE CREATING EMPTY CART", { error })
            );
        }
      } else {
        setTimeout(() => {
          this.createEmptyCart();
        }, 2000);
      }
    } else {
      setTimeout(() => {
        this.createEmptyCart();
      }, 2000);
    }
  };
  createEmptyCart = () => {
    this.props.client
      .mutate({
        mutation: gql`
          mutation {
            createEmptyCart
          }
        `,
      })
      .then((result) => {
        const client_cart_id = result?.data?.createEmptyCart;
        this.setState({
          client_cart_id,
        });
      })
      .catch((error) =>
        console.log("ERROR WHILE CREATING EMPTY CART", { error })
      );
  };
  componentDidMount = () => {
    if (this.props.configDataLoadedKey) {
      this.assignConfig();
    }
  };
  componentDidUpdate = (prevProps) => {
    if (
      prevProps.configDataLoadedKey !== this.props.configDataLoadedKey &&
      this.props.configDataLoadedKey
    ) {
      this.assignConfig();
    }
  };

  assignConfig = () => {
    let configData = this.props.configData;
    this.setState(
      {
        config: {
          ...configData,
          below_question: configData.below_question
            ? configData.below_question.replace(/Note\:/, "")
            : configData.below_question,
        },
        configDataLoaded: true,
      },
      () => {
        this.init();
      }
    );
  };

  onCartItemsClientChange = (cartItemsClient) => {
    const types = Object.entries(constants.productTypes).map(
      ([property, value]) => value
    );
    let availableItems = {};
    types.forEach((type) => {
      availableItems = {
        ...availableItems,
        [type]: this.generateCartItemsForType(type, true),
      };
    });

    const productsDisabled = {};
    for (let type in availableItems) {
      const items = availableItems[type];
      productsDisabled[type] = cartItemsClient.some((_item) =>
        items.some((_typeItem) => _item.product.sku == _typeItem.sku)
      );
    }

    return {
      productsDisabled,
    };
  };
  addProductsToCart = (items, types = [], all = false) => {
    this.setState(
      {
        productsFetching: {
          ...this.state.productsFetching,

          ...types.reduce(
            (final, current) => ({ ...final, [current]: true }),
            {}
          ),
          ...(all ? { addAllFetching: true } : {}),
        },
      },
      () => {
        this.props.client
          .mutate({
            mutation: gql`
        mutation {
          addProductsToCart(
            cartId: "${this.state.client_cart_id}"
            cartItems: [
              ${items.reduce(
                (final, current) =>
                  final +
                  " " +
                  `
                      {
                        quantity: ${current.quantity}
                        sku: "${current.sku}"
                      }`,
                ""
              )}
        
            ]
          ) {
            cart {
              items {
                id
                product {
                  name
                  sku
                }
                quantity
              }
            }
          }
        }
        `,
          })
          .then((result) => {
            if (window.__dev__ === 1) {
              this.setState({
                productsFetching: {
                  ...this.state.productsFetching,
                  ...(all ? { addAllFetching: false } : {}),
                  ...types.reduce(
                    (final, current) => ({ ...final, [current]: false }),
                    {}
                  ),
                },
              });
            }
            // console.log(
            //   JSON.stringify({
            //     result,
            //   })
            // );

            if (this.state.customer_id != "") {
              if (window.__dev__ !== 1)
                window.location.href = `${window.BASE_URL}checkout/cart`;
            } else {
              setTimeout(() => {
                if (window.__dev__ !== 1)
                  window.location.href = `${window.BASE_URL}checkout/cart`;
              }, 1000);
            }
          })
          .catch((error) => {
            this.setState({
              productsFetching: {
                ...this.state.productsFetching,
                ...(all ? { addAllFetching: false } : {}),
                ...types.reduce(
                  (final, current) => ({ ...final, [current]: false }),
                  {}
                ),
              },
            });
            console.log({ error });
          });
      }
    );
  };

  calculateResults() {
    /**
     * WRONG----------- TO BE ADDED
     */
    const { plant, volume, week, flush } = this.props;
    const weekCalc = week;
    const addCyclesCalc = this.props.addCycles + 1;
    // VEG STAGE
    let vegGallons = volume * 0.15 * plant * 7;

    // veg foliar for 4 weeks converted from grams --> lbs.
    let totalVegFoliar = ((vegGallons * 4) / 7) * 4;
    let totalVegFoliartoLbs = totalVegFoliar / 436.59;
    let vegFoliar = totalVegFoliartoLbs.toFixed(2);
    // v1 needed for 4 weeks converted from grams --> lbs.
    let totalV1 =
      vegGallons * 2 + vegGallons * 2 + vegGallons * 2.5 + vegGallons * 3;
    let v1toLbs = totalV1 / 453.59;
    let v1 = v1toLbs.toFixed(2);
    // v2 needed ""
    let totalV2 =
      vegGallons * 2 + vegGallons * 2 + vegGallons * 2.5 + vegGallons * 3;
    let v2toLbs = totalV2 / 453.59;
    let v2 = v2toLbs.toFixed(2);

    // Veg Foliar Bag by Gallon output
    var weight = vegFoliar;
    var bag25,
      bag10,
      bag5,
      bag1 = 0;

    bag25 = Math.floor(weight / 25);
    weight = weight % 25;

    bag10 = Math.floor(weight / 10);
    weight = weight % 10;

    bag5 = Math.floor(weight / 5);
    weight = weight % 5;

    bag1 = Math.ceil(weight);

    // V1 & V2 Bag by Gallon output
    let weightOfV1 = v1;
    var vBag25,
      vBag10,
      vBag5,
      vBag1 = 0;

    vBag25 = Math.floor(weightOfV1 / 25);
    weightOfV1 = weightOfV1 % 25;

    vBag10 = Math.floor(weightOfV1 / 10);
    weightOfV1 = weightOfV1 % 10;

    vBag5 = Math.floor(weightOfV1 / 5);
    weightOfV1 = weightOfV1 % 5;

    vBag1 = Math.ceil(weightOfV1);

    // BLOOM STAGE
    let bloomGallons = volume * 0.35 * plant * 7;

    let initialBloomFoliar = (bloomGallons * 4) / 7;
    let totalBloomFoliar = initialBloomFoliar * 3;
    let bloomFoliartoLbs = totalBloomFoliar / 453.59;
    let bloomFoliar = bloomFoliartoLbs.toFixed(2);

    let initialB1 =
      bloomGallons * 3 * 4 + bloomGallons * 3 * weekCalc + bloomGallons * 2;

    let b1toLbs = initialB1 / 453.59;
    let b1 = b1toLbs.toFixed(2);

    let initialB2 =
      bloomGallons * 3 * 3 +
      bloomGallons * 4 +
      bloomGallons * 2 * weekCalc +
      bloomGallons * 2;
    let b2toLbs = initialB2 / 453.59;
    let b2 = b2toLbs.toFixed(2);

    let initialFT =
      bloomGallons * 3 * weekCalc + bloomGallons * 4 + bloomGallons * 8;
    let initialFTtoLbs = initialFT / 453.59;
    let fullTilt = initialFTtoLbs.toFixed(2);

    // Bloom Foliar Bag by Gallon output
    let weightOfBloomFoliar = bloomFoliar;
    var bfBag25,
      bfBag10,
      bfBag5,
      bfBag1 = 0;
    bfBag25 = Math.floor(weightOfBloomFoliar / 25);
    weightOfBloomFoliar = weightOfBloomFoliar % 25;

    bfBag10 = Math.floor(weightOfBloomFoliar / 10);
    weightOfBloomFoliar = weightOfBloomFoliar % 10;

    bfBag5 = Math.floor(weightOfBloomFoliar / 5);
    weightOfBloomFoliar = weightOfBloomFoliar % 5;

    bfBag1 = Math.ceil(weightOfBloomFoliar);

    // B1 & B2 Bag by Gallon output
    let weightOfB1 = b1;
    var bBag25,
      bBag10,
      bBag5,
      bBag1 = 0;
    bBag25 = Math.floor(weightOfB1 / 25);
    weightOfB1 = weightOfB1 % 25;

    bBag10 = Math.floor(weightOfB1 / 10);
    weightOfB1 = weightOfB1 % 10;

    bBag5 = Math.floor(weightOfB1 / 5);
    weightOfB1 = weightOfB1 % 5;

    bBag1 = Math.ceil(weightOfB1);

    // Full Tilt Bag by Gallon output
    let weightOfFT = fullTilt;
    var ftBag25,
      ftBag10,
      ftBag5,
      ftBag1 = 0;
    ftBag25 = Math.floor(weightOfFT / 25);
    weightOfFT = weightOfFT % 25;

    ftBag10 = Math.floor(weightOfFT / 10);
    weightOfFT = weightOfFT % 10;

    ftBag5 = Math.floor(weightOfFT / 5);
    weightOfFT = Math.floor(weightOfFT % 5);

    ftBag1 = Math.ceil(weightOfFT);

    let vf_1lb = bag1;
    let vf_5lb = bag5;
    let vf_10lb = bag10;
    let vf_25lb = bag25;
    let v1_1lb = vBag1;
    let v1_5lb = vBag5;
    let v1_10lb = vBag10;
    let v1_25lb = vBag25;
    let v2_1lb = vBag1;
    let v2_5lb = vBag5;
    let v2_10lb = vBag10;
    let v2_25lb = vBag25;
    let bf_1lb = bfBag1;
    let bf_5lb = bfBag5;
    let bf_10lb = bfBag10;
    let bf_25lb = bfBag25;
    let b1_1lb = bBag1;
    let b1_5lb = bBag5;
    let b1_10lb = bBag10;
    let b1_25lb = bBag25;
    let b2_1lb = bBag1;
    let b2_5lb = bBag5;
    let b2_10lb = bBag10;
    let b2_25lb = bBag25;
    let ft_1lb = ftBag1;
    let ft_5lb = ftBag5;
    let ft_10lb = ftBag10;
    let ft_25lb = ftBag25;

    return {
      vf_1lb: vf_1lb,
      vf_5lb: vf_5lb,
      vf_10lb: vf_10lb,
      vf_25lb: vf_25lb,
      v1_1lb: v1_1lb,
      v1_5lb: v1_5lb,
      v1_10lb: v1_10lb,
      v1_25lb: v1_25lb,
      v2_1lb: v2_1lb,
      v2_5lb: v2_5lb,
      v2_10lb: v2_10lb,
      v2_25lb: v2_25lb,
      bf_1lb: bf_1lb,
      bf_5lb: bf_5lb,
      bf_10lb: bf_10lb,
      bf_25lb: bf_25lb,
      b1_1lb: b1_1lb,
      b1_5lb: b1_5lb,
      b1_10lb: b1_10lb,
      b1_25lb: b1_25lb,
      b2_1lb: b2_1lb,
      b2_5lb: b2_5lb,
      b2_10lb: b2_10lb,
      b2_25lb: b2_25lb,
      ft_1lb: ft_1lb,
      ft_5lb: ft_5lb,
      ft_10lb: ft_10lb,
      ft_25lb: ft_25lb,
    };
  }

  getProductItemsByType = (itemTypes) => {
    const result = {};
    let resultLb = this.calculateResults();
    const item = this.state.config.products[0];
    itemTypes.forEach((type) => {
      let cartItems = [];
      if (resultLb[`${type}_1lb`] > 0) {
        cartItems.push({
          sku: item["1lb_sku"],
          quantity: resultLb[`${type}_1lb`],
        });
      }
      if (resultLb[`${type}_5lb`] > 0) {
        cartItems.push({
          sku: item["5lb_sku"],
          quantity: resultLb[`${type}_5lb`],
        });
      }
      if (resultLb[`${type}_10lb`] > 0) {
        cartItems.push({
          sku: item["10lb_sku"],
          quantity: resultLb[`${type}_10lb`],
        });
      }
      if (resultLb[`${type}_25lb`] > 0) {
        cartItems.push({
          sku: item["25lb_sku"],
          quantity: resultLb[`${type}_25lb`],
        });
      }
      result[type] = cartItems;
    });
    return result;
  };

  generateCartItemsForType = (type, ignoreQuantity) => {
    let cartItems = [];
    let _allLb = this.calculateResults();

    const item = this.state.config.products.find((_item) => _item.key === type);

    if (item) {
      const value_1lb = _allLb[`${type}_1lb`];
      const value_5lb = _allLb[`${type}_5lb`];
      const value_10lb = _allLb[`${type}_10lb`];
      const value_25lb = _allLb[`${type}_25lb`];

      if (value_1lb > 0 || ignoreQuantity) {
        cartItems.push({ sku: item["1lb_sku"], quantity: value_1lb });
      }
      if (value_5lb > 0 || ignoreQuantity) {
        cartItems.push({ sku: item["5lb_sku"], quantity: value_5lb });
      }
      if (value_10lb > 0 || ignoreQuantity) {
        cartItems.push({ sku: item["10lb_sku"], quantity: value_10lb });
      }
      if (value_25lb > 0 || ignoreQuantity) {
        cartItems.push({ sku: item["25lb_sku"], quantity: value_25lb });
      }
    }

    return cartItems;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let cartItems = this.generateCartItemsForType(constants.productTypes.vf);
    this.addProductsToCart(cartItems, [constants.productTypes.vf]);
  };

  handleSubmitOfV1 = (event) => {
    event.preventDefault();
    let cartItems = this.generateCartItemsForType(constants.productTypes.v1);
    this.addProductsToCart(cartItems, [constants.productTypes.v1]);
  };

  handleSubmitOfV2 = (event) => {
    event.preventDefault();
    let cartItems = this.generateCartItemsForType(constants.productTypes.v2);
    this.addProductsToCart(cartItems, [constants.productTypes.v2]);
  };

  handleSubmitOfBF = (event) => {
    event.preventDefault();
    let cartItems = this.generateCartItemsForType(constants.productTypes.bf);
    this.addProductsToCart(cartItems, [constants.productTypes.bf]);
  };

  handleSubmitOfB1 = (event) => {
    event.preventDefault();
    let cartItems = this.generateCartItemsForType(constants.productTypes.b1);
    this.addProductsToCart(cartItems, [constants.productTypes.b1]);
  };

  handleSubmitOfB2 = (event) => {
    event.preventDefault();
    let cartItems = this.generateCartItemsForType(constants.productTypes.b2);
    this.addProductsToCart(cartItems, [constants.productTypes.b2]);
  };

  handleSubmitOfFT = (event) => {
    event.preventDefault();
    let cartItems = this.generateCartItemsForType(constants.productTypes.ft);
    this.addProductsToCart(cartItems, [constants.productTypes.ft]);
  };

  removeItemFromCartAsync = async (cart_item_id, cart_id) => {
    return await this.props.client
      .mutate({
        mutation: gql`
        mutation {
          removeItemFromCart(
            input: {
              cart_id: "${cart_id}",
              cart_item_id: ${cart_item_id}
            }
          )
         {
          cart {
            items {
              id
              product {
                name
              }
              quantity
            }
            prices {
              grand_total{
                value
                currency
              }
            }
          }
         }
        }
        
        `,
      })
      .then((result_cart_remove) => {
        // console.log(JSON.stringify({ result_cart_remove, cart_item_id }));
        return result_cart_remove;
      });
  };
  getAllCartItemsAsync = async (cart_id) => {
    return await this.props.client
      .query({
        query: gql`
          {
            cart(cart_id: "${cart_id}") {
              items {
                id
                product {
                  name
                  sku
                }
                quantity
              }
            }
          }
        `,
      })
      .then((result) => {
        return result;
      })
      .catch((error) => null);
  };
  addAllToCart = () => {
    const types = Object.entries(constants.productTypes).map(
      ([property, value]) => value
    );
    let availableItems = [];
    types.forEach((type) => {
      availableItems = [
        ...availableItems,
        ...this.generateCartItemsForType(type),
      ];
    });
    this.addProductsToCart(availableItems, types, true);
  };
  onAddAllToCart = async () => {
    this.setState(
      {
        addAllFetching: true,
      },
      async () => {
        // const cart_id = this.state.client_cart_id;
        // const result = await this.getAllCartItemsAsync(cart_id);
        // const items = result?.data?.cart?.items || [];

        // for (let i = 0; i < items.length; i++) {
        //   await this.removeItemFromCartAsync(items[i].id, cart_id);
        // }
        this.addAllToCart();
      }
    );
  };

  clickHandler1 = () => {
    this.props.setNutrientData("flush", "1");
  };

  clickHandler2 = () => {
    this.props.setNutrientData("flush", "2");
  };

  handlePlantChange = (value) => {
    if (value > 5000) return;
    this.props.setNutrientData("plant", value);
  };

  handleWeekChange = (value) => {
    this.props.setNutrientData("week", value);
  };

  toggleCalculationExpand = () => {
    this.setState({ calculationExpand: !this.state.calculationExpand });
  };

  changeWeekFlushes = (selectedValue) => {
    this.props.setNutrientData("flush", selectedValue);
  };

  render() {
    const {
      allProductForCart,
      _allLb,

      vegFoliar,
      totalVegFoliar,

      v1,
      totalV1,

      v2,
      totalV2,

      bloomFoliar,
      totalBloomFoliar,

      b1,
      initialB1,

      b2,
      initialB2,

      fullTilt,
      initialFT,
    } = this.props.nutrientResults; // this.calculateRenderResults();

    const fulltiltPdfOk = (() => {
      let fulltiltPdf = noMods;
      if (this.props.flush == 1) {
        if (this.props.week == 0) {
          fulltiltPdf = noMods;
        } else if (this.props.week == 1) {
          fulltiltPdf = singleBloom;
        } else if (this.props.week == 2) {
          fulltiltPdf = doubleBloom;
        } else if (this.props.week == 3) {
          fulltiltPdf = tripleBloom;
        }
      } else if (this.props.flush == 2) {
        if (this.props.week == 0) {
          fulltiltPdf = doubleFlash;
        } else if (this.props.week == 1) {
          fulltiltPdf = doubleFlashBloom;
        } else if (this.props.week == 2) {
          fulltiltPdf = doubleFlashdoubleBloom;
        } else if (this.props.week == 3) {
          fulltiltPdf = doubleFlashtripleBloom;
        }
      }
      return fulltiltPdf;
    })();

    return (
      <div className="react-app-room-builder-nutrient-calculator-container">
        <div className="flex-all nutrient-separator-group">
          <div className="n-s-first-item">
            <img src={nutrientIcon} alt={"img_medium.png"} />
          </div>
          <div className="n-s-second-item n-c-title-page">
            FULL TILT™ <br></br>
            CALCULATOR
          </div>
        </div>
        <hr></hr>
        <p className="center-paragraph">
          All our FloraFlex Nutrients Schedules are designed to yield excellent
          result with an 8 week Bloom Stage.
        </p>
        <hr></hr>
        <div
          className="extend-section-nc flex-all"
          active={this.state.calculationExpand == true ? "1" : "0"}
        >
          <div
            className="paragram-extend-nc"
            onClick={this.toggleCalculationExpand}
          >
            CUSTOMIZE YOUR CALCULATIONS (FOR ADVANCED GROWERS ONLY)
            <div
              className="expand-calculations"
              onClick={this.toggleCalculationExpand}
            >
              <img src={arrow} alt="arrow" />
            </div>
          </div>
          <div className="content-section-nc">
            <div className="txt-section-nc">
              Advanced growers know their strains may be improved by extending
              their Bloom Stage, by repeating <br />
              Week 5, or adding an additional week of Flush
            </div>
            <div className="manipulation-data-section-nc">
              <SliderCounter
                className="height-var-1"
                label={this.state.config.additional_bloom_weeks_question_text}
                onChange={this.handleWeekChange}
                value={this.props.week}
                min={this.state.config.additional_bloom_weeks_range.min}
                max={this.state.config.additional_bloom_weeks_range.max}
                step="1"
              />
              <div className="switch-volume-ctn">
                <span>How many weeks of Flush do you intend?</span>

                <Switch
                  listValues={[
                    { key: 1, value: "1", label: "1" },
                    { key: 2, value: "2", label: "2" },
                  ]}
                  value={this.props.flush}
                  onSwitch={(selectedValue) => {
                    this.changeWeekFlushes(selectedValue);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <hr></hr>
        <div className="spacing-10"></div>
        {!this.state.configDataLoaded && (
          <div className="flex-all width-100" style={{ flex: 1 }}>
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        )}

        {this.state.configDataLoaded && (
          <div className="App-nutrient-calculator">
            <div className="App-wrapper">
              <div className="card-deck flex-all flex-wrap">
                <CardWallet
                  pricePerUnit={allProductForCart[constants.productTypes.vf]}
                  type={constants.productTypes.vf}
                  onChangeCustomQuantity={this.props.onChangeCustomQuantity}
                  disabled={
                    this.state.productsDisabled[constants.productTypes.vf]
                  }
                  isFetching={
                    this.state.productsFetching[constants.productTypes.vf]
                  }
                  logo={vegetativeFoliar}
                  toolTip={productToolTips.vegetativeFoliar}
                  productUrl={this.state.config?.products?.[0]?.productUrl}
                  resultValue={vegFoliar}
                  items={[
                    { weight: 1, value: _allLb.vf_1lb },
                    { weight: 5, value: _allLb.vf_5lb },
                    { weight: 10, value: _allLb.vf_10lb },
                    { weight: 25, value: _allLb.vf_25lb },
                  ]}
                  handleSubmit={this.handleSubmit}
                  buttonText={
                    <>
                      <span>
                        {" "}
                        ${this.formatNumber(totalVegFoliar.toFixed(2))}{" "}
                        <br></br>
                      </span>
                      ADD VEG FOLIAR TO CART
                    </>
                  }
                />
                <CardWallet
                  pricePerUnit={allProductForCart[constants.productTypes.v1]}
                  type={constants.productTypes.v1}
                  onChangeCustomQuantity={this.props.onChangeCustomQuantity}
                  disabled={
                    this.state.productsDisabled[constants.productTypes.v1]
                  }
                  isFetching={
                    this.state.productsFetching[constants.productTypes.v1]
                  }
                  logo={v1logo}
                  toolTip={productToolTips.v1logo}
                  productUrl={this.state.config?.products?.[1]?.productUrl}
                  resultValue={v1}
                  items={[
                    { weight: 1, value: _allLb.v1_1lb },
                    { weight: 5, value: _allLb.v1_5lb },
                    { weight: 10, value: _allLb.v1_10lb },
                    { weight: 25, value: _allLb.v1_25lb },
                  ]}
                  handleSubmit={this.handleSubmitOfV1}
                  buttonText={
                    <>
                      <span>
                        {" "}
                        ${this.formatNumber(totalV1.toFixed(2))} <br></br>
                      </span>{" "}
                      ADD V1<sup>TM</sup> TO CART{" "}
                    </>
                  }
                />
                <CardWallet
                  pricePerUnit={allProductForCart[constants.productTypes.v2]}
                  type={constants.productTypes.v2}
                  onChangeCustomQuantity={this.props.onChangeCustomQuantity}
                  disabled={
                    this.state.productsDisabled[constants.productTypes.v2]
                  }
                  isFetching={
                    this.state.productsFetching[constants.productTypes.v2]
                  }
                  logo={v2logo}
                  toolTip={productToolTips.v2logo}
                  productUrl={this.state.config?.products?.[2]?.productUrl}
                  resultValue={v2}
                  items={[
                    { weight: 1, value: _allLb.v2_1lb },
                    { weight: 5, value: _allLb.v2_5lb },
                    { weight: 10, value: _allLb.v2_10lb },
                    { weight: 25, value: _allLb.v2_25lb },
                  ]}
                  handleSubmit={this.handleSubmitOfV2}
                  buttonText={
                    <>
                      <span>
                        {" "}
                        ${this.formatNumber(totalV2.toFixed(2))} <br></br>
                      </span>{" "}
                      ADD V2<sup>TM</sup> TO CART{" "}
                    </>
                  }
                />
              </div>
              {/* end of card deck */}
              <div className="card-deck flex-all flex-wrap">
                <CardWallet
                  pricePerUnit={allProductForCart[constants.productTypes.bf]}
                  type={constants.productTypes.bf}
                  onChangeCustomQuantity={this.props.onChangeCustomQuantity}
                  disabled={
                    this.state.productsDisabled[constants.productTypes.bf]
                  }
                  isFetching={
                    this.state.productsFetching[constants.productTypes.bf]
                  }
                  variant="blue"
                  logo={bloomFoliarLogo}
                  toolTip={productToolTips.bloomFoliarLogo}
                  productUrl={this.state.config?.products?.[3]?.productUrl}
                  resultValue={bloomFoliar}
                  items={[
                    { weight: 1, value: _allLb.bf_1lb },
                    { weight: 5, value: _allLb.bf_5lb },
                    { weight: 10, value: _allLb.bf_10lb },
                    { weight: 25, value: _allLb.bf_25lb },
                  ]}
                  handleSubmit={this.handleSubmitOfBF}
                  buttonText={
                    <>
                      <span>
                        {" "}
                        ${this.formatNumber(totalBloomFoliar.toFixed(2))}{" "}
                        <br></br>
                      </span>
                      ADD BLOOM FOLIAR TO CART
                    </>
                  }
                />
                <CardWallet
                  pricePerUnit={allProductForCart[constants.productTypes.b1]}
                  type={constants.productTypes.b1}
                  onChangeCustomQuantity={this.props.onChangeCustomQuantity}
                  disabled={
                    this.state.productsDisabled[constants.productTypes.b1]
                  }
                  isFetching={
                    this.state.productsFetching[constants.productTypes.b1]
                  }
                  variant="blue"
                  logo={b1logo}
                  toolTip={productToolTips.b1logo}
                  productUrl={this.state.config?.products?.[4]?.productUrl}
                  resultValue={b1}
                  items={[
                    { weight: 1, value: _allLb.b1_1lb },
                    { weight: 5, value: _allLb.b1_5lb },
                    { weight: 10, value: _allLb.b1_10lb },
                    { weight: 25, value: _allLb.b1_25lb },
                  ]}
                  handleSubmit={this.handleSubmitOfB1}
                  buttonText={
                    <>
                      <span>
                        {" "}
                        ${this.formatNumber(initialB1.toFixed(2))} <br></br>
                      </span>
                      ADD B1<sup>TM</sup> TO CART
                    </>
                  }
                />
                <CardWallet
                  pricePerUnit={allProductForCart[constants.productTypes.b2]}
                  type={constants.productTypes.b2}
                  onChangeCustomQuantity={this.props.onChangeCustomQuantity}
                  disabled={
                    this.state.productsDisabled[constants.productTypes.b2]
                  }
                  isFetching={
                    this.state.productsFetching[constants.productTypes.b2]
                  }
                  variant="blue"
                  logo={b2logo}
                  toolTip={productToolTips.b2logo}
                  productUrl={this.state.config?.products?.[5]?.productUrl}
                  resultValue={b2}
                  items={[
                    { weight: 1, value: _allLb.b2_1lb },
                    { weight: 5, value: _allLb.b2_5lb },
                    { weight: 10, value: _allLb.b2_10lb },
                    { weight: 25, value: _allLb.b2_25lb },
                  ]}
                  handleSubmit={this.handleSubmitOfB2}
                  buttonText={
                    <>
                      <span>
                        {" "}
                        ${this.formatNumber(initialB2.toFixed(2))} <br></br>
                      </span>
                      ADD B2<sup>TM</sup> TO CART
                    </>
                  }
                />
              </div>

              <div className="card-deck flex-all flex-wrap">
                <CardWallet
                  pricePerUnit={allProductForCart[constants.productTypes.ft]}
                  type={constants.productTypes.ft}
                  onChangeCustomQuantity={this.props.onChangeCustomQuantity}
                  disabled={
                    this.state.productsDisabled[constants.productTypes.ft]
                  }
                  isFetching={
                    this.state.productsFetching[constants.productTypes.ft]
                  }
                  variant="blue"
                  logo={fullTiltLogo}
                  toolTip={productToolTips.fullTiltLogo}
                  productUrl={this.state.config?.products?.[6]?.productUrl}
                  resultValue={fullTilt}
                  items={[
                    { weight: 1, value: _allLb.ft_1lb },
                    { weight: 5, value: _allLb.ft_5lb },
                    { weight: 10, value: _allLb.ft_10lb },
                    { weight: 25, value: _allLb.ft_25lb },
                  ]}
                  handleSubmit={this.handleSubmitOfFT}
                  buttonText={
                    <>
                      <span>
                        {" "}
                        ${this.formatNumber(initialFT.toFixed(2))} <br></br>
                      </span>
                      ADD FULL TILT<sup>TM</sup> TO CART
                    </>
                  }
                />
              </div>
            </div>
          </div>
        )}
        <div className="footer-nc-container">
          <div className="left-btn-nc">
            <a href={fulltiltPdfOk} target="_blank">
              <button
                type="button"
                className="btn btn-lg nutrient-btn"
                id="green-btn"
              >
                DOWNLOAD
                <br />
                FULL TILT<sup>TM</sup> SCHEDULE
              </button>
            </a>
          </div>
          <div className="right-data-nc">
            <div className="title-nc">TOTAL WATER CONSUMPTION</div>
            <div className="logo-totals">
              <div className="logo-nc-footer" style={{ display: "none" }}>
                <img src={footerGreenLogo} alt="FloraFlex" />
              </div>
              <div className="total-nc-footer">
                {this.formatNumber(this.props.nutrientResults.water)}
              </div>
              <div className="descript-total-nc">Gallons Per Grow</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const WithApolloClient = (props) => (
  <ApolloConsumer>
    {(client) => <NutrientCalculator {...props} client={client} />}
  </ApolloConsumer>
);
export default WithApolloClient;
