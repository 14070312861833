import React from "react";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import Routes from "./app/Routes";
import LayoutSplashScreen from "./app/components/LayoutSplashScreen/LayoutSplashScreen";
import { store, persistor } from "./app/redux/store.js";

export default function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        <HashRouter>
          <Routes />
        </HashRouter>
      </PersistGate>
    </Provider>
  );
}
