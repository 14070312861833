// @flow
import * as React from "react";

import CartBuildLine from "./../../../components/CartBuildLine/CartBuildLine";
import { onChangeWithLimits } from "./../../../utils/utilFunctions";

/**
 * @param {object} props Component props
 * @param {object} props.cartBuildLines
 * @param {function} props.onChangeAsideItem
 */
export default function AsideNutrients(props) {
  return (
    <div className="nutrients-aside">
      <div className="aside-item-title">
        <span className="label-aside">
            NUTRIENTS NEEDED:
        </span>
      </div>
      <div className="build-lines-container">
        {Object.entries(props.cartBuildLines).map(([key, item]) => (
          <div key={key} className="build-line-wrapper">
            <CartBuildLine
              label={item.label}
              value={item.value}
              price={item.price}
              iconLeft={item.icon}
              onChange={(event) =>
                onChangeWithLimits(event, (value) =>
                  props.onChangeAsideItem(
                    `data.cartBuildLines.${key}.value`,
                    value
                  )
                )
              }
            />
          </div>
        ))}
        <div className="build-lines-borders">
          <div className="border"></div>
          <div className="border"></div>
        </div>
      </div>
      <div className="aside-item-title">
        <span className="label-aside">SYSTEM STYLE: </span>
      </div>
      <div className="red-note">
        <span>Note: We recommend you adjust your starting EC to 0.3 v your preferred Cal and Mag.</span>
      </div>
      <div className="flex-center">
        
      </div>
    </div>
  );
}
