import React from "react";
import { useEffect } from "react";
import { canvasDim } from "./../../../utils/constants";
import { zoneTypes } from "./tiles";

/**
 * @param {object} props Component props
 * @param {Number} props.roomLength
 * @param {Number} props.roomWidth
 * @param {Number} props.plantsPerZone
 * @param {Number} props.zoneNumber
 * @param {Number} props.tierPerZoneNumber
* @param {number} props.styleSelected
 * @param {Array} props.styles
 */
export default function Room2D(props) {
  const currentStyle = props.styles[props.styleSelected];
  const [state, setState] = React.useState({
    height: canvasDim.height,
    width: canvasDim.width,
    plantsArray: [[], []],
    frameConfig: {},
    roomClassName: "",
    squareChild: ""
  });

  function onInit() {
    const style = zoneTypes[currentStyle.value];
    if(style) {
      setState({
        ...state,
        height: canvasDim.height,
        width: canvasDim.width,
        plantsArray: style.plantArrayTemplate,
        frameConfig: style.frameConfig,
        roomClassName: style.roomClassName,
        squareChild: style.squareChild
      });
    }
  }

  useEffect(() => {
    onInit();
  }, [props.styleSelected]);

  return (
    <div
      className={`room-2d-wrapper ${state.roomClassName}`}
      style={{
        // height: state.height + "px",
        width: state.width + 20 + "px",
      }}
    >
      <div className="room-2d-right-tube">
        
      </div>
      {
        [...new Array(
              props.zoneNumber < 1 ? 1 :props.zoneNumber
           )].map((item, index) => {
          const renderHalf = (down) => {
            return (
              <div
                className={`zone-2d--half ${
                  down ? "zone-2d--bottom" : "zone-2d--upper"
                }`}
              >
                {state.squareChild && state.squareChild}
                <div className="zone-2d-row-wrapper">
                  <div className="zone-2d-plant-row">
                    {state.plantsArray[0].map((tileItem, index) => {
                      return (
                        <div className={`zone-2d--tile ${tileItem.className}`}>
                          <img src={tileItem.image} className="tile-img" />
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="zone-2d-row-wrapper">
                  <div className="zone-2d-plant-row">
                    {state.plantsArray[1].map((tileItem, index) => {
                      return (
                        <div className={`zone-2d--tile ${tileItem.className}`}>
                          <img src={tileItem.image} className="tile-img" />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          };
          const renderMiddle = () => {
            return (
              <div className={`zone-2d--middle-content`}>
                <div className="zone-2d-row-wrapper">
                  <div className="zone-2d-under-tube">
                   {state.frameConfig.leftTile && state.frameConfig.leftTile}
                  </div>
                  <div className="zone-2d-plant-row-for-width">
                    {state.plantsArray[1].map((tileItem, index) => {
                      if (tileItem.frameStructureClassName) {
                        return (
                          <div
                            className={`zone-2d--tile -for-width-spacing ${tileItem.className} ${tileItem.frameStructureClassName}`}
                          >
                            
                          </div>
                        );
                      }
                      if (tileItem.linkageClassName) {
                        return (
                          <div
                            className={`zone-2d--tile -for-width-spacing ${tileItem.className} ${tileItem.linkageClassName}`}
                          >
                            <div className="tile-linkage-wrapper">
                              <div className="tile-linkage"></div>
                            </div>
                          </div>
                        );
                      }
                      
                      return (
                        <div
                          className={`zone-2d--tile -for-width-spacing ${tileItem.className}`}
                          style={{ visibility: "hidden" }}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          };
          return (
            <div key={`zone-room-2d-${index}`} className="zone-2d-square">
              <div className="zone-2d--middle">
                {renderMiddle()}
                {renderHalf()}
                {renderHalf(true)}
              </div>
            </div>
          );
        })
      }
    </div>
  );
}
