import { CELL_COUNT, CELL_WIDTH, gridStartX, gridStartY } from "./Constants";

export function generateGenericGrid({roomStartX, roomStartY, cellCountX, cellCountY}) {
  //vertical lines
  let xStart = roomStartX;
  let yStart = roomStartY;
  let xEnd = roomStartX;
  let yEnd = yStart + CELL_WIDTH * cellCountY;


  const arr = [];

  for (let i = 0; i < cellCountX + 1 /*+last edge*/; i++) {
    arr.push({
      start: {
        x: xStart,
        y: yStart,
      },
      end: {
        x: xEnd,
        y: yEnd,
      },
    });
    xStart += CELL_WIDTH;
    xEnd += CELL_WIDTH;
  }
  //hor lines
  xStart = roomStartX;
  yStart = roomStartY;
  xEnd = xStart + CELL_WIDTH * cellCountX;
  yEnd = roomStartY;
  for (let i = 0; i < cellCountY + 1 /*+last edge*/; i++) {
    arr.push({
      start: {
        x: xStart,
        y: yStart,
      },
      end: {
        x: xEnd,
        y: yEnd,
      },
    });
    yStart += CELL_WIDTH;
    yEnd += CELL_WIDTH;
  }
  return arr;
}

export function generateGridCoord() {
  //vertical lines
  let xStart = gridStartX;
  let yStart = gridStartY;
  let xEnd = gridStartX;
  let yEnd = yStart + CELL_WIDTH * CELL_COUNT;

  const arr = [];
  const gridBg = {
    x: xStart,
    y: yStart,
    width: CELL_WIDTH * CELL_COUNT,
    height: CELL_WIDTH * CELL_COUNT,
    fill: "#FFFFFF",
  };
  for (let i = 0; i < CELL_COUNT + 1 /*+last edge*/; i++) {
    arr.push({
      start: {
        x: xStart,
        y: yStart,
      },
      end: {
        x: xEnd,
        y: yEnd,
      },
    });
    xStart += CELL_WIDTH;
    xEnd += CELL_WIDTH;
  }
  //hor lines
  xStart = gridStartX;
  yStart = gridStartY;
  xEnd = xStart + CELL_WIDTH * CELL_COUNT;
  yEnd = gridStartY;
  for (let i = 0; i < CELL_COUNT + 1 /*+last edge*/; i++) {
    arr.push({
      start: {
        x: xStart,
        y: yStart,
      },
      end: {
        x: xEnd,
        y: yEnd,
      },
    });
    yStart += CELL_WIDTH;
    yEnd += CELL_WIDTH;
  }
  return {
    gridState: arr,
    gridBg,
  };
}


export function getPlatformOffset(zones) {
  return zones.reduce((acc, val) => {
    return {
      offsetX: Math.min(acc.offsetX, val.offsetX),
      offsetY: Math.min(acc.offsetY, val.offsetY)
    }
  }, {
    offsetX: 10000,
    offsetY: 10000
  })
}
