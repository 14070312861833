const initialState = {
  loader: {},
  pageConfig: {},
  popupStack: {},
  sharePopup: {}
};
const actionTypes = {
  RESET_CONFIG: "RESET_CONFIG",
  SET_LOADER: "SET_LOADER",
  PUSH_POPUP_STACK: "PUSH_POPUP_STACK",
  POP_POPUP_STACK: "POP_POPUP_STACK",
  EDIT_POPUP_STACK: "EDIT_POPUP_STACK",
  RESET_POPUP_STATE: "RESET_POPUP_STATE",
  OPEN_SHARE_ROOM_POPUP: "OPEN_SHARE_ROOM_POPUP",
  CLOSE_SHARE_ROOM_POPUP: "CLOSE_SHARE_ROOM_POPUP",
};
export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.RESET_CONFIG: {
      return initialState;
    }
    case actionTypes.SET_LOADER: {
      const loader = state.loader;
      if (!payload.loader) {
        delete loader[payload.loaderKey];
      } else {
        loader[payload.loaderKey] = payload.loader;
      }
      return {
        ...state,
        loader: {
          ...loader,
        },
      };
    }
    case actionTypes.PUSH_POPUP_STACK:
      return {
        ...state,
        popupStack: {
          ...state.popupStack,
          [payload.popup.key]: payload.popup,
        },
      };
    case actionTypes.POP_POPUP_STACK:
      delete state.popupStack[payload.key];
      return {
        ...state,
        popupStack: state.popupStack,
      };
    case actionTypes.EDIT_POPUP_STACK:
      if (state.popupStack[payload.key]) {
        return {
          ...state,
          popupStack: {
            ...state.popupStack,
            [payload.key]: {
              ...state.popupStack[payload.key],
              ...payload,
            },
          },
        };
      } else {
        return state;
      }
    case actionTypes.RESET_POPUP_STATE:
      return {
        ...state,
        popupStack: {},
      };
    case actionTypes.OPEN_SHARE_ROOM_POPUP:
      return {
        ...state,
        sharePopup: {
          ...payload.popup
        },
      };
    case actionTypes.CLOSE_SHARE_ROOM_POPUP:
      return {
        ...state,
        sharePopup: {},
      };
    default:
      return state;
  }
};
export const actions = {
  resetConfig: (payload) => ({ payload, type: actionTypes.RESET_CONFIG }),
  setLoader: (payload) => ({ payload, type: actionTypes.SET_LOADER }),
  pushPopupStack: (payload) => ({
    payload,
    type: actionTypes.PUSH_POPUP_STACK,
  }),
  popPopupStack: (payload) => ({ payload, type: actionTypes.POP_POPUP_STACK }),
  editPopupStack: (payload) => ({
    payload,
    type: actionTypes.EDIT_POPUP_STACK,
  }),
  resetPopupState: (payload) => ({
    payload,
    type: actionTypes.RESET_POPUP_STATE,
  }),
  openSharePopup: (payload) => ({
    payload,
    type: actionTypes.OPEN_SHARE_ROOM_POPUP,
  }),
  closeSharePopup: (payload) => ({
    payload,
    type: actionTypes.CLOSE_SHARE_ROOM_POPUP,
  }),
};

function firstKey(obj) {
  const firstKey = Object.keys(obj).length ? Object.keys(obj)[0] : undefined;
  if (!firstKey) {
    return {};
  }
  return obj[firstKey] || {};
}

export const selectors = {
  getLoader: (layout) => {
    return firstKey(layout.loader);
  },
  getPopup: (layout) => {
    return firstKey(layout.popupStack);
  },
};
