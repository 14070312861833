import React, { useEffect, useLayoutEffect, useState } from "react";

import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import Layout from "./layout/Layout";
import BasePage from "./Basepage";
import AuthPage from "./auth/AuthPage";
import { connect, useSelector } from "react-redux";
import { onScreenShoot, onDOMContentLoaded } from "./utils/utilFunctions";
import PurchaseKits from "./pages/PurchaseKits/PurchaseKits";
import { Popup } from "./layout/components/Popup/Popup";
import { Component } from "react";
import { ApolloConsumer } from "@apollo/client";
import { layoutActions } from "./redux/actionFunctions/layoutActions";
import { authenticaionActions } from "./redux/actionFunctions/authenticationActions";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql,
} from "@apollo/client";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "@apollo/client/link/context";
import AppContext from "./AppContext";

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedConfigured: true,
    };
    this.initGetParams();
  }

  initGetParams = () => {
    /**
     * use GET params from react router (hash) or window location
     */
    const urlParamsHash = new URL(
      `https://dummy-url.ro/${this.props.history.location.search}`
    ).searchParams;

    const urlParams = new URL(window.location.href).searchParams;

    if (!urlParamsHash.get("share_code") && !urlParamsHash.get("room_id")) {
      this.urlParamsOriginal = urlParams;
    }
    this.share_code = urlParamsHash.get("share_code") || urlParamsHash.get("room_id");
    window.getshared = () => this.share_code;
  };

  onKeyDown = (event) => {
    onScreenShoot(event, "keydown");
  };
  componentDidMount = () => {
    // if(this.props.isAuth) {
    // props.checkToken(this.props.client, this.props.user).then(() => {
    this.checkLoggedIn();
    // }).catch(() => {})
    // }
    window.addEventListener("keyup", onScreenShoot);
    // window.addEventListener("keydown", this.onKeyDown);
    window.addEventListener("DOMContentLoaded", onDOMContentLoaded);
  };

  checkLoggedIn = () => {
    window.$.ajax({
      url: `${window.BASE_URL}roombuilderconfig/customerdata/index`,
      type: "GET",
      data: {},
      dataType: "json",
      success: (data) => {
        this.setState({ loggedConfigured: true });
        if (data?.token) {
          if (this.props.user?.email !== data?.email) {
            localStorage.setItem("clientToken", data.token);
            this.props.login({
              token: data.token,
              firstname: data.firstname,
              lastname: data.lastname,
              email: data.email,
              clientId: data.id,
            });
            this.props.refreshToken();
          }
        } else {
          if (this.props.isAuth) {
            this.props.refreshToken();
          }
        }
      },
      error: (request, error) => {
        this.setState({ loggedConfigured: true });
      },
    });
  };

  handleOriginalGet = () => {
    if(this.share_code) {
      this.props.history.replace({
        pathname: "/kitbuilder",
        search: `?share_code=${this.share_code}`,
      });
      this.setState({
        basePagaKey: Date.now() + "basePage"
      })
      this.share_code = null;
    }
  };

  resetOriginalParams = () => {
    this.urlParamsOriginal = null;
  };

  componentDidUpdate = (prevProps) => {
    if(this.props.isAuth !== prevProps.isAuth && this.props.isAuth) {
      // setTimeout(() => {
      //   // this.handleOriginalGet();
      // }, 1000);
    }
  }

  render() {
    return this.state.loggedConfigured ? (
      <>
        <Popup />
        {!this.props.isAuth ? (
          <Switch>
            <Route path="/purchase-kits" component={PurchaseKits} />
            <Route
              path="/auth"
              render={(routerProps) => (
                <AuthPage
                  {...routerProps}
                  handleOriginalGet={() => {}}
                />
              )}
            />
            <Route
              path="/"
              render={(props) => {
                props.history.replace({
                  pathname: "/auth",
                  search: props.history.location.search,
                });
                return "";
              }}
            />
          </Switch>
        ) : (
          <Layout>
            <BasePage key={this.state.basePagaKey} resetOriginalParams={this.resetOriginalParams} />
          </Layout>
        )}
      </>
    ) : (
      ""
    );
  }
}

const mapStateToProps = ({ auth, user }) => ({
  isAuth: auth?.user?.token,
  user: auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  login: (authClient) =>
    authenticaionActions(dispatch).login({
      ...authClient,
    }),
  logout: (client) => authenticaionActions(dispatch).logout(client),
  checkToken: (client, user) =>
    authenticaionActions(dispatch).checkToken(client, user),
});

const RoutesClient = (props) => {
  const [state, setState] = useState({ updateKey: 0 });

  const httpLink = createHttpLink({
    uri: `${window.BASE_URL}graphql`,
    credentials: "same-origin",
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem("clientToken");
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
  return (
    <ApolloProvider client={client}>
      <AppContext.Provider
        value={{
          updateKey: state.updateKey,
          updateClientHeaders: (auth) => {
            setState({
              ...state,
              updateKey: Date.now(),
            });
          },
        }}
      >
        <Routes
          {...props}
          client={client}
          refreshToken={() => {
            setState({ updateKey: Date.now() });
          }}
        />
      </AppContext.Provider>
    </ApolloProvider>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RoutesClient));
