// @flow
import * as React from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { layoutActions } from "../../../redux/actionFunctions/layoutActions";
import "./SharePopup.scss";

import { ReactComponent as CloseIcon } from "./../../../../assets/svg/close-2.svg";
import { ReactComponent as CopyIcon } from "./../../../../assets/svg/copy.svg";
import { ReactComponent as SocialFbIcon } from "./../../../../assets/svg/social-fb.svg";
import { ReactComponent as SocialInstaIcon } from "./../../../../assets/svg/social-insta.svg";
import { ReactComponent as SocialTwIcon } from "./../../../../assets/svg/social-tw.svg";

import * as utilFunctions from "./../../../utils/utilFunctions";

/**
 * @typedef {Object} IShareRoom
 * @property {String} 2D_Image
 * @property {String} created_at
 * @property {Boolean} needsSaving
 * @property {String} original_owner_firstname
 * @property {String} original_owner_id
 * @property {String} original_owner_lastname
 * @property {Object} roomData
 * @property {Object} room_data
 * @property {Object} room_data_ok
 * @property {String} room_description
 * @property {String} room_name
 * @property {String} share_code
 * @property {String} updated_at
 */
/**
 *
 * @param {Object} props
 * @param {IShareRoom} props.room
 */
export default function SharePopup(props) {
  const dispatch = useDispatch();
  const { popup } = useSelector(({ layout }) => ({
    popup: layout.sharePopup,
  }));

  if (!popup) {
    return "";
  }

  const shareLink = `${window.BASE_URL}${props.room.share_code || ""}`;

  return (
    <div className={"modal-cstm-share"}>
      <div className="modal-cstm-container-share">
        <div className="modal-cstm-content-share">
          <div className="modal-cstm-top-share">
            <div className="generic-text-shadow latto flex-1 modal-share-title">
              SHARE YOUR BUILD
            </div>
            <div
              className="flex-all cursor-pointer modal-close"
              onClick={() => {
                layoutActions(dispatch).closeShareRoomPopup();
              }}
            >
              <CloseIcon />
            </div>
          </div>
          <div className="modal-cstm-top-bottom">
            <div className="flex-all modal-content-title latto">
              <div>#MYFLORAFLEXBUILD</div>
            </div>
            <div className="modal-content-paragraph">
              Share this FloraFlex<sup>®</sup> Room Builder link with your
              friends and followers on social media so they can see your
              masterpiece and even buy it for themselves.
            </div>
            <div className="modal-share-link-bar cursor-pointer">
              <div
                className="flex-between modal-share-link-body"
                onClick={() => {
                  /* Get the text field */
                  const inputClipboard = document.getElementById(
                    "clipboard-input-node-share"
                  );

                  /* Select the text field */
                  inputClipboard.select();
                  inputClipboard.setSelectionRange(
                    0,
                    99999
                  ); /* For mobile devices */

                  /* Copy the text inside the text field */
                  document.execCommand("copy");
                }}
              >
                <input
                  value={shareLink}
                  id="clipboard-input-node-share"
                  style={{
                    position: "absolute",
                    left: "0",
                    top: 0,
                    opacity: 0,
                  }}
                />
                <div className="flex-1 latto -line-overflow-elipsis share-link">
                  {shareLink}
                </div>
                <div className="flex-all share-link-copy-icon">
                  <CopyIcon />
                </div>
              </div>
            </div>
            <div className="flex-all">
              <div className="flex-between share-icon-list cursor-pointer">
                <div className="flex-all share-icon-item cursor-pointer">
                  <a href={`http://www.facebook.com/sharer/sharer.php?u=${shareLink}`} target="_blank" ><SocialFbIcon /></a>
                </div>
                <div className="flex-all share-icon-item cursor-pointer">
                  <SocialInstaIcon />
                </div>
                <div className="flex-all share-icon-item cursor-pointer">
                <a href={`https://twitter.com/intent/tweet?url=${shareLink}&text=My%20Flora%20Flex%20build`} target="_blank" > <SocialTwIcon /> </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
