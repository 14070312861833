import { actions as authActions } from "../reducers/authReducer";
import { gql } from "@apollo/client";

export function authenticaionActions(dispatch) {
  return {
    login: (client) => {
      const user = {
        token: client.token,
        firstname: client.firstname,
        lastname: client.lastname,
        email: client.email,
        clientId: client.clientId,
      };
      window.isLoggedWithUserNameAndPasswordRb = true;
      dispatch(
        authActions.login({
          user,
        })
      );

      window.$.ajax({
        url: `${window.BASE_URL}roombuilderconfig/login/index`,
        type: "POST",
        data: {
          ...user,
        },
        dataType: "json",
        success: function (data) {},
        error: function (request, error) {},
      });
    },
    autoLogin: (client, user) => {
      // return new Promise((resolve, reject) => {
      //   window.$.ajax({
      //     url: `${window.BASE_URL}roombuilderconfig/customerdata/index`,
      //     type: "GET",
      //     data: {},
      //     dataType: "json",
      //     success: function (data) {
      //       if (data?.token) {
              
      //       } 
      //     },
      //     error: function (request, error) {},
      //   });
      // }).catch((err) => {

      // })

    },
    checkToken: (client, user) => {
      return client
        .query({
          query: gql`
            {
              customer {
                id
                email
              }
            }
          `,
        })
        .catch((error) => {
          if (
            Array.isArray(error?.graphQLErrors) &&
            error.graphQLErrors.length &&
            error.graphQLErrors.some(
              (item) => item?.extensions?.category === "graphql-authorization"
            )
            && window.isLoggedWithUserNameAndPasswordRb !== true //avoid a unauthenticated call done too early after login
          ) {
            authenticaionActions(dispatch).logout(client);
          }
        });
    },
    logout: (client) => {
      dispatch(
        authActions.login({
          user: null,
          clientCartId: null,
          cartItems: null,
        })
      );
      return client
        .mutate({
          mutation: gql`
            mutation {
              revokeCustomerToken {
                result
              }
            }
          `,
        })
        .then((result) => {
          if (result) {
            window.$.ajax({
              url: `${window.BASE_URL}roombuilderconfig/logout/index`,
              type: "POST",
              data: {},
              dataType: "json",
              success: function (data) {},
              error: function (request, error) {},
            });
          }
        })
        .catch((error) => {
          console.log("Logout error: " + error);
        });
    },
    setClientDetails: (clientDetails, client) => {
      dispatch(
        authActions.setClientDetails({
          clientDetails,
          cartItems: null,
        })
      );
    },
    setClientCart: (clientCartId, cartItems) => {
      dispatch(
        authActions.setClientCart({
          clientCartId,
          cartItems,
        })
      );
    },
  };
}
