// @flow
import React, { useEffect } from "react";
import { Component } from "react";
import { Button, Spinner } from "react-bootstrap";
import { gql } from "@apollo/client";

import "./../../SaveRoomBuildPopup/SaveRoomBuildPopup.scss";
import popupImage from "./../../../../../assets/image/modalLogo.png";
import puncher from "./../../../../../assets/image/extras-for-build/puncher.png";
import sprayer from "./../../../../../assets/image/extras-for-build/sprayer.png";
import kitMain from "./../../../../../assets/image/extras-for-build/kitMain.png";

const extrasForBuild = [
  {
    key: "extra-build-1604671091260-0",
    label: (
      <>
        FLORA MICRO HOLE
        <br />
        PUNCHER
      </>
    ),
    price: 11.99,
    qty: 1,
    sku: "787",
    image: puncher,
  },
  {
    key: "extra-build-1604671091260-1",
    label: (
      <>
        6L BATTERY POWERED
        <br />
        FLORA SPRAYER
      </>
    ),
    price: 112.99,
    qty: 1,
    sku: "1531",
    image: sprayer,
  },
  {
    key: "extra-build-1604671091260-2",
    label: <>INCUBATOR™ &amp; 40|40 PULGS KIT</>,
    price: 17.99,
    qty: 1,
    sku: "1539",
    image: kitMain,
  },
];

class ExtraForBuild extends Component {
  constructor(props) {
    super(props);
    this.state = {
      room: null,
      cartItems: this.props.cartItems.reduce((final, current) => {
        return {
          ...final,
          [current.product.sku]: current,
        };
      }, {}),
    };
    this.props.editPopup({
      nodeAttributes: { step: "EXTRAS_FOR_BUILD", extrasForBuild: "true" },
      // logo: popupImage,
    });
  }

  onCancel = () => {
    this.props.closePopup();
  };

  addExtraItemToCart = (itemSku, index) => {
    let itemBtnId = "add-extras-item-" + index;
    let itemBtn = document.getElementById(itemBtnId);
    itemBtn.innerHTML = "<span></span>";
    this.props.client
      .mutate({
        mutation: gql`
      mutation {
        addProductsToCart(
          cartId: "${this.props.clientCartId}"
          cartItems: [
            {
                quantity: 1
                sku: "${itemSku}"
                room_id: ${
                  this.props.selectedRoom.id ? this.props.selectedRoom.id : null
                }
                room_name: ${
                  this.props.selectedRoom.room_name
                    ? `"${this.props.selectedRoom.room_name}"`
                    : null
                }
            }
          ]
        ) {
          cart {
            items {
              id
              product {
                name
                sku
              }
              quantity
            }
          }
        }
      }
      `,
      })
      .then((result) => {
        const cartItemsClient =
          result?.data?.addProductsToCart?.cart?.items || [];
        this.setState({
          cartItems: cartItemsClient.reduce((final, current) => {
            return {
              ...final,
              [current.product.sku]: current,
            };
          }, {}),
        });
        itemBtn.innerHTML = 'ADD TO CART <div class="already-in-cart"> <svg xmlns="http://www.w3.org/2000/svg" width="40.032" height="29.853" viewBox="0 0 40.032 29.853" > <path id="Path_687" data-name="Path 687" d="M13.6,94.365.586,81.354a2,2,0,0,1,0-2.831l2.831-2.831a2,2,0,0,1,2.831,0l8.765,8.764L33.785,65.684a2,2,0,0,1,2.831,0l2.831,2.831a2,2,0,0,1,0,2.831L16.428,94.365a2,2,0,0,1-2.831,0Z" transform="translate(0 -65.098)" fill="#fff"/> </svg></div>';
      })
      .catch((errorWhileAddingToCart) => {
        itemBtn.innerHTML = 'ADD TO CART <div class="already-in-cart"> <svg xmlns="http://www.w3.org/2000/svg" width="40.032" height="29.853" viewBox="0 0 40.032 29.853" > <path id="Path_687" data-name="Path 687" d="M13.6,94.365.586,81.354a2,2,0,0,1,0-2.831l2.831-2.831a2,2,0,0,1,2.831,0l8.765,8.764L33.785,65.684a2,2,0,0,1,2.831,0l2.831,2.831a2,2,0,0,1,0,2.831L16.428,94.365a2,2,0,0,1-2.831,0Z" transform="translate(0 -65.098)" fill="#fff"/> </svg></div>';
        console.log({ errorWhileAddingToCart });
      });
  };

  render() {
    return (
      <div id="extras-for-build-popup">
        <div className="modal-cstm-data">
          <div className="modal-cstm-title">EXTRAS FOR YOUR BUILD</div>
          <div className="modal-cstm-text">
            Would you like to add any of these tools to help you complete your
            grow process?
          </div>
        </div>
        {extrasForBuild.length > 0 && (
          <div className="modal-cstm-extra-products">
            {extrasForBuild.map((item, index) => {
              const isAdded = Boolean(this.state.cartItems[item.sku]);
              return (
                <div className="item-extra-prod">
                  <div className="item-extra-prod-content">
                    <div className="img-extra-prod-item">
                      <img src={item.image} alt="image" />
                    </div>
                    <div className="title-extra-prod-item">{item.label}</div>
                    <div className="price-extra-prod-item">${item.price}</div>
                    <button
                      type="button"
                      id={`add-extras-item-${index}`}
                      className="btn-extra-prod-item"
                      isAdded={isAdded === true ? "1" : "0"}
                      onClick={() => this.addExtraItemToCart(item.sku, index)}
                    >
                      ADD TO CART
                      <div className="already-in-cart">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="40.032"
                          height="29.853"
                          viewBox="0 0 40.032 29.853"
                        >
                          <path
                            id="Path_687"
                            data-name="Path 687"
                            d="M13.6,94.365.586,81.354a2,2,0,0,1,0-2.831l2.831-2.831a2,2,0,0,1,2.831,0l8.765,8.764L33.785,65.684a2,2,0,0,1,2.831,0l2.831,2.831a2,2,0,0,1,0,2.831L16.428,94.365a2,2,0,0,1-2.831,0Z"
                            transform="translate(0 -65.098)"
                            fill="#fff"
                          />
                        </svg>
                      </div>
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <div className="modal-buttons">
          <Button variant="secondary" onClick={this.props.closePopup}>
            CANCEL
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              const cartAnchorNode = document.getElementById(
                "checkout-link-rm-kit-cart"
              );
              if (cartAnchorNode) {
                cartAnchorNode.click();
              }
            }}
          >
            CHECKOUT
          </Button>
        </div>
      </div>
    );
  }
}
export default ExtraForBuild;
