import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
export const actionTypes = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  UPDATE_USER_INFO: "UPDATE_USER_INFO",
  SET_CLIENT_CART: "SET_CLIENT_CART",
  SET_CLIENT_DETAILS: "SET_CLIENT_DETAILS"
};
const initialAuthState = {
  user: undefined,
};
export const reducer = persistReducer(
  { storage: storage, key: "room-build-v1", blacklist: [] },
  (state = initialAuthState, { type, payload }) => {
    switch (type) {
      case actionTypes.LOGIN: {
        return { ...state, user: payload.user };
      }
      case actionTypes.LOGOUT: {
        return { ...initialAuthState };
      }
      case actionTypes.UPDATE_USER_INFO: {
        return {
          ...state,
          user: {
            ...state.user,
            ...payload,
          },
        };
      }
      case actionTypes.SET_CLIENT_CART: {
        return {
          ...state,
          user: {
            ...state.user,
            clientCartId: payload.clientCartId,
            cartItems: payload.cartItems,
          },
        };
      }
      case actionTypes.SET_CLIENT_DETAILS: {
        return {
          ...state,
          user: {
            ...state.user,
            ...payload.clientDetails
          },
        };
      }
      default:
        return state;
    }
  }
);
export const actions = {
  login: (payload) => {
    return { type: actionTypes.LOGIN, payload };
  },
  setClientDetails: (payload) => {
    return { type: actionTypes.SET_CLIENT_DETAILS, payload };
  },
  logout: (payload) => {
    return { type: actionTypes.LOGOUT, payload: payload || {} };
  },
  set_token_firebase: (payload) => {
    return { type: actionTypes.SET_TOKEN_FIREBASE, payload };
  },
  closeAutoLogoutPopup: () => ({ type: actionTypes.CLOSE_AUTO_LOGOUT_POPUP }),
  updateUserInfo: (payload) => {
    return { type: actionTypes.UPDATE_USER_INFO, payload };
  },
  setClientCart: (payload) => {
    return { type: actionTypes.SET_CLIENT_CART, payload };
  },
};
