import { actions } from "../reducers/layoutReducer";
import React from "react";

export function layoutActions(dispatch) {
  return {
    openLoader: (loaderKey, payload) => {
      dispatch(
        actions.setLoader({
          loaderKey,
          loader: {
            ...(payload || {}),
            open: true,
          },
        })
      );
    },
    closeLoader: (loaderKey, payload) => {
      dispatch(
        actions.setLoader({
          loaderKey,
          loader: null,
        })
      );
    },
    /**
     *
     * @param {object} payload
     * @param {string} payload.key
     */
    pushPopupStack: (payload) => {
      dispatch(
        actions.pushPopupStack({
          popup: {
            ...payload,
          },
        })
      );
    },
    popPopupStack: (payload) => {
      dispatch(
        actions.popPopupStack({
          key: payload.key,
        })
      );
    },
    editPopupStack: (payload) => {
      dispatch(
        actions.editPopupStack({
          ...payload,
        })
      );
    },
    getCurrentPopup: (popupStack) => {
      const entries = Object.entries(popupStack);
      let result = null;
      for (let i = 0; i < entries.length; i++) {
        const [key, content] = entries[i];
        if (
          content &&
          (!result || (content.priority && content.priority > result.priority))
        ) {
          result = content;
        }
      }
      return result;
    },
    openShareRoomPopup: (room) => {
      dispatch(
        actions.openSharePopup({
          popup: {
            key: Date.now(),
            room: JSON.parse(room),
          },
        })
      );
    },
    closeShareRoomPopup: () => {
      dispatch(
        actions.closeSharePopup({
          popup: {},
        })
      );
    },
    resetPopupState: () => {
      dispatch(actions.resetPopupState());
    },
  };
}
