import React from "react";
import _ from "lodash";
import { Popup } from "../../../layout/components/Popup/Popup";
// reusable components import
import Button from "../../../components/Button/Button";
import Input from "../../../components/Input/Input";
import arrowRight from "../../../../assets/image/arrow-right.png";
import arrowLeft from "../../../../assets/image/arrow-left.png";
import arrowUp from "../../../../assets/image/arrow-up.png";
import arrowDown from "../../../../assets/image/arrow-down.png";
import arrowRightActive from "../../../../assets/image/arrow-right-active.png";
import arrowLeftActive from "../../../../assets/image/arrow-left-active.png";
import arrowUpActive from "../../../../assets/image/arrow-up-active.png";
import arrowDownActive from "../../../../assets/image/arrow-down-active.png";
import questionCircle from "../../../../assets/image/question-mark-circle.png";
import { trayErrors } from "./KitRoomFunctions";
import { getPlatformOffset } from "../Canvas/Helpers";

import { layoutActions } from "../../../redux/actionFunctions/layoutActions";
import { useDispatch } from "react-redux";
import controls2d from "../../../../assets/image/tooltips/tooltip2D-simple.png";
// Was causing a build error
//import {math} from "../../../../../public/xeogl.module";

export default function KitPlatformLayout(props) {
  const [state, setState] = React.useState({
    drainage: props.platformDrainageDirection,
    rows: props.platformNumRows,
    platformsPerRow: props.platformPlatformsPerRow,
    linkLength: props.platformLinkLength,
    walkwayWidth: props.platformWalkwayWidth,
    zoneWidth: props.platformSubzoneWidth,
    scrollable: null
  });
  const [
    showControls,
    setShowControls
  ] = React.useState();
  const dispatch = useDispatch();
  const [popupState, setPopupState] = React.useState({});

  function submitRoomSize() {
    if (
      props.roomSize.roomWidth > 1000 &&
      props.roomSize.roomLength > 1000 &&
      props.roomSize.roomHeight > 1000
    ) {
      props.history.push({
        pathname: "/kitbuilder/room/retry",
        search: props.history.location.search,
      });
    } else {
      props.history.push({
        pathname: "/kitbuilder/content",
        search: props.history.location.search,
      });
    }
  }

  function changeDirectionLeft() {
    //TODO add enabling inputs on click
    window.inputsDisabled = false;
    if (isPossibleToRotate("left")) {
      window.rotationAngle = getRotationAngle(state.drainage, "left");
      setState({ ...state, drainage: "left" });
    }
  }

  function changeDirectionRight() {
    //TODO add enabling inputs on click
    window.inputsDisabled = false;
    if (isPossibleToRotate("right")) {
      window.rotationAngle = getRotationAngle(state.drainage, "right");
      setState({ ...state, drainage: "right" });
    }
  }

  function changeDirectionUp() {
    //TODO add enabling inputs on click
    window.inputsDisabled = false;
    if (isPossibleToRotate("up")) {
      window.rotationAngle = getRotationAngle(state.drainage, "up");
      setState({ ...state, drainage: "up" });
    }
  }

  function changeDirectionDown() {
    //TODO add enabling inputs on click
    window.inputsDisabled = false;
    if (isPossibleToRotate("down")) {
      window.rotationAngle = getRotationAngle(state.drainage, "down");
      setState({ ...state, drainage: "down" });
    }
  }

  function decreaseRows() {
    if (
      state.rows > 2 &&
      window.inputsDisabled === false &&
      isPossibleToRemoveRow()
    ) {
      setState({ ...state, rows: state.rows - 2 });
      removeRows();
    }
  }

  function increaseRows() {
    if (
      window.maxRows !== true &&
      window.inputsDisabled === false &&
      isPossibleToAddRow()
    ) {
      setState({ ...state, rows: state.rows + 2 });
      addRows();
    }
  }

  function decreasePlatforms() {
    if (
      state.platformsPerRow > 1 &&
      window.inputsDisabled === false &&
      isPossibleToDecreasePlatforms()
    ) {
      setState({ ...state, platformsPerRow: state.platformsPerRow - 1 });
    }
  }

  function increasePlatforms() {
    if (
      window.maxPlatforms !== true &&
      window.inputsDisabled === false &&
      isPossibleToIncreasePlatforms()
    ) {
      setState({ ...state, platformsPerRow: state.platformsPerRow + 1 });
    }
  }

  function getPrevLinkLength() {
    return state.linkLength === 6 ? 3 : state.linkLength - 1;
  }

  function decreaseLinkLength() {
    if (isPossibleToDecreaseLinkLength()) {
      setState({ ...state, linkLength: getPrevLinkLength() });
    }
  }

  function getNextLinkLength() {
    return state.linkLength === 4 ? 4 : state.linkLength + 1;
  }

  function increaseLinkLength() {
    if (
      window.maxLinkLength !== true &&
      window.inputsDisabled === false &&
      isPossibleToIncreaseLinkLength()
    ) {
      setState({
        ...state,
        linkLength: getNextLinkLength(),
      });
    }
  }

  function decreaseWalkwayWidth() {
    if (isPossibleToReduceWalkwayWidth()) {
      setState({
        ...state,
        walkwayWidth: state.walkwayWidth > 2 ? state.walkwayWidth - 1 : 2,
      });
    }
  }

  function increaseWalkwayWidth() {
    if (window.maxWalkwayWidth !== true && window.inputsDisabled === false && isPossibleToIncreaseWalkwayWidth()) {
      setState({
        ...state,
        walkwayWidth: state.walkwayWidth < 4 ? state.walkwayWidth + 1 : 4,
      });
    }
  }

  function decreaseZoneWidth() {
    if (isPossibleToReduceZoneWidth()) {
      setState({
        ...state,
        zoneWidth: state.zoneWidth > 1 ? state.zoneWidth - 1 : 1,
      });
    }
  }

  function increaseZoneWidth() {
    if (window.zoneMaxWidth !== true && window.inputsDisabled === false && isPossibleToIncreaseZoneWidth()) {
      setState({
        ...state,
        zoneWidth: state.zoneWidth < 2 ? state.zoneWidth + 1 : 2,
      });
    }
  }

  React.useEffect(() => {
    // remove those hacks
    // use redux!
    window.linkLength = state.linkLength;
    window.walkwayWidth = state.walkwayWidth;
    window.platformsPerRow = state.platformsPerRow;
    window.zoneWidth = state.zoneWidth;
    window.drainage = state.drainage;

    props.setPlatForm({
      platformDrainageDirection: state.drainage,
      platformNumRows: state.rows,
      platformPlatformsPerRow: state.platformsPerRow,
      platformLinkLength: state.linkLength,
      platformWalkwayWidth: state.walkwayWidth,
      platformSubzoneWidth: state.zoneWidth,
    });
  }, [
    state.drainage,
    state.rows,
    state.platformsPerRow,
    state.linkLength,
    state.walkwayWidth,
    state.zoneWidth,
  ]);

  React.useEffect(() => {
    flipPlatform(window.rotationAngle);
    recalculateZones();
  }, [state && state.drainage]);

  React.useEffect(() => {
    recalculateZones();
  }, [state && state.zoneWidth]);

  React.useEffect(() => {
    recalculateZones();
  }, [
    state && state.platformsPerRow,
    state && state.platFormSize,
    state && state.walkwayWidth,
    state && state.linkLength,
  ]);

  function isPossibleToAddRow() {
    let platform = getPlatform();

    if (!platform) {
      return true;
    }

    let newRowWidth =
      window.platFormSize * 2 + state.zoneWidth + state.walkwayWidth;

    if (state.drainage === "down" || state.drainage === "up") {
      if (platform.widthVal + newRowWidth > props.roomSize.roomWidth) {
        return false;
      }
    }

    if (state.drainage === "left" || state.drainage === "right") {
      if (platform.lengthVal + newRowWidth > props.roomSize.roomLength) {
        return false;
      }
    }

    return true;
  }

  function isPossibleToRemoveRow() {
    return props.zones.filter((item) => item.label === "ROW").length > 2;
  }

  function isPossibleToIncreaseLinkLength() {
    let newLength =
      getNextLinkLength() * window.platformsPerRow + window.platFormSize;

    if (state.drainage === "down" || state.drainage === "up") {
      if (newLength > props.roomSize.roomLength) {
        return false;
      }
    }

    if (state.drainage === "left" || state.drainage === "right") {
      if (newLength > props.roomSize.roomWidth) {
        return false;
      }
    }

    return state.linkLength < 4;
  }

  function isPossibleToDecreaseLinkLength() {
    return state.linkLength > 1;
  }

  function isPossibleToIncreasePlatforms() {
    let newLength =
      window.linkLength * (window.platformsPerRow + 1) + window.platFormSize;

    if (state.drainage === "down" || state.drainage === "up") {
      if (newLength > props.roomSize.roomLength) {
        return false;
      }
    }

    if (state.drainage === "left" || state.drainage === "right") {
      if (newLength > props.roomSize.roomWidth) {
        return false;
      }
    }

    return true;
  }

  function isPossibleToDecreasePlatforms() {
    return window.platformsPerRow > 1;
  }

  function isPossibleToRotate(direction) {
    let platform = getPlatform();
    if (!platform) {
      return true;
    }
    let angle = getRotationAngle(window.drainage, direction);

    if (angle === 0 || angle === 180 || angle === -180) {
      return true;
    }

    return (
      getPlatform().widthVal <= props.roomSize.roomLength &&
      getPlatform().lengthVal <= props.roomSize.roomWidth
    );
  }

  function isPossibleToReduceWalkwayWidth() {
    return state.walkwayWidth > 2;
  }

  function isPossibleToIncreaseWalkwayWidth() {
    let rowCount = (state.rows / 2);

    let newPlatformWidth =
      (rowCount - 1) * (state.walkwayWidth + 1) + rowCount * state.zoneWidth + state.rows * window.platFormSize;
    if (state.drainage === "down" || state.drainage === "up") {
      if (newPlatformWidth > props.roomSize.roomWidth) {
        return false;
      }
    }

    if (state.drainage === "left" || state.drainage === "right") {
      if (newPlatformWidth > props.roomSize.roomLength) {
        return false;
      }
    }

    return state.walkwayWidth < 4;
  }

  function isPossibleToReduceZoneWidth() {
    return state.zoneWidth > 1;
  }

  function isPossibleToIncreaseZoneWidth() {
    let rowCount = (state.rows / 2);

    let newPlatformWidth =
      (rowCount - 1) * state.walkwayWidth + rowCount * (state.zoneWidth + 1) + state.rows * window.platFormSize;
    if (state.drainage === "down" || state.drainage === "up") {
      if (newPlatformWidth > props.roomSize.roomWidth) {
        return false;
      }
    }

    if (state.drainage === "left" || state.drainage === "right") {
      if (newPlatformWidth > props.roomSize.roomLength) {
        return false;
      }
    }

    return state.zoneWidth < 2;
  }

  function recalculateZones() {
    if (!props.zones.length) return;

    let platform = getPlatform();
    let walkway = getWalkway();

    if (
      state.drainage === "down" ||
      state.drainage === "up" ||
      state.drainage === "none"
    ) {
      const ROW_WIDTHVAL = window.platFormSize;
      const ROW_LENGTHVAL =
        window.linkLength * window.platformsPerRow + window.platFormSize;

      const getRowWidth = () => {
        return ROW_WIDTHVAL;
      };

      const getRowLength = () => {
        return ROW_LENGTHVAL;
      };

      const getRowOffset = (index) => {
        return (
          platform.offsetX +
          (getRowWidth() * 2 + (state.zoneWidth + state.walkwayWidth)) *
            (index / 2)
        );
      };

      const getSubzoneOffset = (index) => {
        return (
          platform.offsetX +
          getRowWidth() +
          (state.zoneWidth + state.walkwayWidth + getRowWidth() * 2) * index
        );
      };

      props.zones
        .filter((item) => item.label === "ROW")
        .map((row, index) => {
          if (index % 2 === 0) {
            row.offsetX = getRowOffset(index);
          } else {
            row.offsetX =
              getRowOffset(index - 1) + state.zoneWidth + getRowWidth();
          }
          row.offsetY = platform.offsetY;

          row.widhVal = getRowWidth();
          row.lengthVal = getRowLength();
        });

      props.zones
        .filter((item) => item.label === "SUBZONE")
        .map((subzone, index) => {
          subzone.offsetX = getSubzoneOffset(index);
          subzone.offsetY = platform.offsetY;

          subzone.lengthVal = getRowLength();
          subzone.widthVal = state.zoneWidth;
        });

      const rowLengthVal = props.zones.filter((item) => item.label === "ROW")[0]
        .lengthVal;
      const platformParams = props.zones
        .filter((item) => item.physical)
        .reduce(
          (acc, val) => {
            return {
              widthVal: val.widthVal + acc.widthVal,
              lengthVal: rowLengthVal,
            };
          },
          { widthVal: 0, lengthVal: 0 }
        );

      platform.widthVal =
        platformParams.widthVal +
        state.walkwayWidth *
          (props.zones.filter((item) => item.label === "ROW").length / 2 - 1);
      platform.lengthVal = platformParams.lengthVal;

      props.zones
        .filter((item) => item.label === "DRAINAGE")
        .map((drainage, index) => {
          drainage.offsetX = platform.offsetX;
          if (state.drainage === "up") {
            drainage.offsetY = platform.offsetY - 1;
          } else {
            drainage.offsetY = platform.offsetY + platform.lengthVal;
          }
          drainage.widthVal = platform.widthVal + state.walkwayWidth;
          drainage.lengthVal = 1;
        });

      walkway.widthVal = platform.widthVal + state.walkwayWidth * 2;
      walkway.lengthVal = platform.lengthVal + state.walkwayWidth * 2;

      walkway.offsetX = platform.offsetX - state.walkwayWidth;
      walkway.offsetY = platform.offsetY - state.walkwayWidth;
    }

    if (state.drainage === "right" || state.drainage === "left") {
      const ROW_WIDTHVAL = window.platFormSize;
      const ROW_LENGTHVAL =
        window.linkLength * window.platformsPerRow + window.platFormSize;

      const getRowWidth = () => {
        return ROW_WIDTHVAL;
      };

      const getRowLength = () => {
        return ROW_LENGTHVAL;
      };

      const getRowOffset = (index) => {
        return (
          platform.offsetY +
          (getRowWidth() * 2 + (state.zoneWidth + state.walkwayWidth)) *
            (index / 2)
        );
      };

      const getSubzoneOffset = (index) => {
        return (
          platform.offsetY +
          getRowWidth() +
          (state.zoneWidth + state.walkwayWidth + getRowWidth() * 2) * index
        );
      };

      props.zones
        .filter((item) => item.label === "ROW")
        .map((row, index) => {
          if (index % 2 == 0) {
            row.offsetY = getRowOffset(index);
          } else {
            row.offsetY =
              getRowOffset(index - 1) + state.zoneWidth + getRowWidth();
          }
          row.offsetX = platform.offsetX;
          row.widthVal = getRowLength();
          row.lengthVal = getRowWidth();
        });

      props.zones
        .filter((item) => item.label === "SUBZONE")
        .map((subzone, index) => {
          subzone.offsetY = getSubzoneOffset(index);
          subzone.offsetX = platform.offsetX;
          subzone.widthVal = getRowLength();
          subzone.lengthVal = state.zoneWidth;
        });

      const rowWidthVal = getRowLength();
      const platformParams = props.zones
        .filter((item) => item.physical)
        .reduce(
          (acc, val) => {
            return {
              widthVal: rowWidthVal,
              lengthVal: val.lengthVal + acc.lengthVal,
            };
          },
          { widthVal: 0, lengthVal: 0 }
        );

      platform.lengthVal =
        platformParams.lengthVal +
        state.walkwayWidth *
          (props.zones.filter((item) => item.label === "ROW").length / 2 - 1);
      platform.widthVal = platformParams.widthVal;

      props.zones
        .filter((item) => item.label === "DRAINAGE")
        .map((drainage, index) => {
          if (state.drainage === "left") {
            drainage.offsetX = platform.offsetX - 1;
          } else {
            drainage.offsetX = platform.offsetX + platform.widthVal;
          }
          drainage.offsetY = platform.offsetY - state.walkwayWidth;
          drainage.widthVal = 1;
          drainage.lengthVal = platform.lengthVal + state.walkwayWidth;
        });

      walkway.widthVal = platform.widthVal + state.walkwayWidth * 2;
      walkway.lengthVal = platform.lengthVal + state.walkwayWidth * 2;

      walkway.offsetX = platform.offsetX - state.walkwayWidth;
      walkway.offsetY = platform.offsetY - state.walkwayWidth;
    }

    if (window.wasCentered === false) {
      window.wasCentered = true;
      centerPlatform(
        platform.offsetX < 0 ||
          platform.offsetX + platform.widthVal > props.roomSize.roomWidth,
        platform.offsetY < 0 ||
          platform.offsetY + platform.lengthVal > props.roomSize.roomLength
      );
      recalculateZones();
    }

    window.rowCount = state.rows / 2;
    window.offsetX = platform.offsetX;
    window.offsetY = platform.offsetY;

    window.wasCentered = false;

    props.updateZones(props.zones.slice(), {
      platformDrainageDirection: state.drainage,
      platformNumRows: state.rows,
      platformPlatformsPerRow: state.platformsPerRow,
      platformLinkLength: state.linkLength,
      platformWalkwayWidth: state.walkwayWidth,
      platformSubzoneWidth: state.zoneWidth,
    });
  }

  function getRotationAngle(oldPosition, newPosition) {
    const angles = {
      right: 90,
      left: -90,
      up: 180,
      down: 0,
      none: 0,
    };

    return angles[newPosition] - angles[oldPosition];
  }

  function flipPlatform(angle) {
    if (angle === 0) return;


    props.zones.map((zone, index) => {
      if (angle === -90 || angle === 90 || angle === 270 || angle === -270) {
        let newWidth = zone.lengthVal;
        let newLength = zone.widthVal;

        zone.widthVal = newWidth;
        zone.lengthVal = newLength;
      }
    });

    centerPlatform(true, true);
  }

  function centerPlatform(isCenterHorizontally, isCenterVertically) {
    let platform = getPlatform();

    if (isCenterHorizontally)
      platform.offsetX = (props.roomSize.roomWidth - platform.widthVal) / 2;
    if (isCenterVertically)
      platform.offsetY = (props.roomSize.roomLength - platform.lengthVal) / 2;
  }

  function getPlatform() {
    return props.zones.filter((item) => item.label === "PLATFORM")[0];
  }

  function getWalkway() {
    return props.zones.filter((item) => item.label === "WALKWAY")[0];
  }

  function addRows() {
    let zonesLength = props.zones.length;
    let rows = props.zones.filter((item) => item.label === "ROW");

    let rowPrototype = rows[0];
    let newRow1 = { ...rowPrototype };
    newRow1.id = zonesLength;
    let newRow2 = { ...rowPrototype };
    newRow2.id = zonesLength + 1;

    let subzones = props.zones.filter((item) => item.label === "SUBZONE");
    let zonePrototype = subzones[0];
    let newZone = { ...zonePrototype };
    newZone.id = zonesLength + 2;

    props.zones.push(newRow1, newRow2, newZone);

    recalculateZones();
  }

  function removeRows() {
    props.zones.splice(-3);
    recalculateZones();
  }

  function openPopup(payload) {
    let key = Date.now();
    layoutActions(dispatch).pushPopupStack({
      key,
      title: "",
      callbackNo: (closePopup) => {
        closePopup();
      },
      ...payload,
    });
  }

  function getTooltipMargin() {
    return -state.scrollable?.scrollTop * 2 + 'px';
  }

  return (
    <div className="step-content kit-room-content kit-room-container-medium platform-controls" >
      <h1 className="step-title">
        Design Your <br /> Platform Layout
      </h1>
      <div className="flex-all container-or-medium-wrapper" ref={ref => state.scrollable = ref}>
        <h2 className="step-subtitle">Drainage Direction</h2>
        <p className="text-center">
          Pick the drainage direction for all of your platform rows
        </p>
        <div className="arrow-row">
          <div
            className={`arrow-button ${
              isPossibleToRotate("left") ? "" : "disabled"
            }`}
          >
            <img
              onClick={changeDirectionLeft}
              src={state.drainage == "left" ? arrowLeftActive : arrowLeft}
              alt="arrow"
              height="80px"
            />
          </div>
          <div
            className={`arrow-button ${
              isPossibleToRotate("right") ? "" : "disabled"
            }`}
          >
            <img
              onClick={changeDirectionRight}
              src={state.drainage == "right" ? arrowRightActive : arrowRight}
              alt="arrow"
              height="80px"
            />
          </div>
        </div>
        <div className="arrow-row">
          <div
            className={`arrow-button ${
              isPossibleToRotate("up") ? "" : "disabled"
            }`}
          >
            <img
              onClick={changeDirectionUp}
              src={state.drainage == "up" ? arrowUpActive : arrowUp}
              alt="arrow"
              width="80px"
            />
          </div>
          <div
            className={`arrow-button ${
              isPossibleToRotate("down") ? "" : "disabled"
            }`}
          >
            <img
              onClick={changeDirectionDown}
              src={state.drainage == "down" ? arrowDownActive : arrowDown}
              alt="arrow"
              width="80px"
            />
          </div>
        </div>
        <p className="text-center">
          Changing drainage direction will reset any values selected below.
        </p>
        <div className="divider"></div>

        <div
          className={`step-row ${
            window.maxRows || window.inputsDisabled ? "step-row__disabled" : ""
          }`}
        >
          <div className="description-tsi">
            Rows
            <span>Even Numbers</span>
          </div>
          <div className="input-dimension-tsi has-controls">
            <div
              className={`reduce-length ${
                isPossibleToRemoveRow() ? "" : "disabled"
              }`}
              onClick={decreaseRows}
            >
              -
            </div>
            <input
              readOnly={true}
              type="text"
              name="length-tray"
              className="length-tray"
              disabled={window.maxRows || window.inputsDisabled ? true : false}
              placeholder="2"
              value={state.rows}
            />
            <div
              className={`raise-length ${
                isPossibleToAddRow() ? "" : "disabled"
              }`}
              onClick={increaseRows}
            >
              +
            </div>
          </div>

          <div className="tooltip-control-question-button">
            <Button
              text="?"
              variant="control question-button"
              onMouseOver={() => setShowControls('tooltip_rows')}
              onMouseOut={() => setShowControls(null)}
            />
          </div>
          {showControls === 'tooltip_rows' && (
            <div className="abs-tooltip-text" id="tooltip_rows" style={{marginTop: getTooltipMargin()}}>
              This is the number of connected rows of PotPro™ Platforms. These
              must be added in pairs.
            </div>
          )}
        </div>
        <div
          className={`step-row ${
            window.maxPlatforms || window.inputsDisabled
              ? "step-row__disabled"
              : ""
          }`}
        >
          <div className="description-tsi">Platforms Per Row</div>
          <div className="input-dimension-tsi has-controls">
            <div
              className={`reduce-length ${
                isPossibleToDecreasePlatforms() ? "" : "disabled"
              }`}
              onClick={decreasePlatforms}
            >
              -
            </div>
            <input
              readOnly={true}
              type="text"
              name="length-tray"
              className="length-tray"
              placeholder="2"
              disabled={
                window.maxPlatforms || window.inputsDisabled ? true : false
              }
              value={state.platformsPerRow}
            />
            <div
              className={`raise-length ${
                isPossibleToIncreasePlatforms() ? "" : "disabled"
              }`}
              onClick={increasePlatforms}
            >
              +
            </div>
          </div>
          <div className="tooltip-control-question-button">
            <Button
              text="?"
              variant="control question-button"
              onMouseOver={() => setShowControls('tooltip_platforms')}
              onMouseOut={() => setShowControls(null)}
            />
          </div>
          {showControls === 'tooltip_platforms' && (
            <div className="abs-tooltip-text" id="tooltip_platforms" style={{marginTop: getTooltipMargin()}}>
              This is the number of connected PotPro™ Platforms per row. This is also the number of plants in each row.
            </div>
          )}
        </div>
        <div className="divider"></div>

        <div
          className={`step-row ${
            window.maxLinkLength || window.inputsDisabled
              ? "step-row__disabled"
              : ""
          }`}
        >
          <div className="description-tsi">
            Potpro Link Length
            <span>1,2,3 or 4FT</span>
          </div>
          <div className="input-dimension-tsi has-controls">
            <div
              className={`reduce-length ${
                isPossibleToDecreaseLinkLength() ? "" : "disabled"
              }`}
              onClick={decreaseLinkLength}
            >
              -
            </div>
            <input
              readOnly={true}
              type="text"
              name="length-tray"
              className="length-tray"
              placeholder="1ft"
              disabled={
                window.maxLinkLength || window.inputsDisabled ? true : false
              }
              value={state.linkLength}
            />
            <div
              className={`reduce-length ${
                isPossibleToIncreaseLinkLength() ? "" : "disabled"
              }`}
              onClick={increaseLinkLength}
            >
              +
            </div>
          </div>
          <div className="tooltip-control-question-button">
            <Button
              text="?"
              variant="control question-button"
              onMouseOver={() => setShowControls('tooltip_link_length')}
              onMouseOut={() => setShowControls(null)}
            />
          </div>
          {showControls === 'tooltip_link_length' && (
            <div className="abs-tooltip-text" id="tooltip_link_length" style={{marginTop: getTooltipMargin()}}>
              PotPro™ Links are precut lengths of 16-17mm tubing. These Links connect your Platforms in each row. Your selected length determines the distance between the connected Platforms. This is also the distance between individual plants.
            </div>
          )}
        </div>
        <div
          className={`step-row ${
            window.maxWalkwayWidth || window.inputsDisabled
              ? "step-row__disabled"
              : ""
          }`}
        >
          <div className="description-tsi">
            Walkway Width
            <span>2, 3 OR 4FT</span>
          </div>
          <div className="input-dimension-tsi has-controls">
            <div
              className={`reduce-length ${
                isPossibleToReduceWalkwayWidth() ? "" : "disabled"
              }`}
              onClick={decreaseWalkwayWidth}
            >
              -
            </div>
            <input
              readOnly={true}
              type="text"
              name="length-tray"
              className="length-tray"
              placeholder="2ft"
              disabled={
                window.maxWalkwayWidth || window.inputsDisabled ? true : false
              }
              value={state.walkwayWidth}
            />
            <div
              className={`raise-length ${
                isPossibleToIncreaseWalkwayWidth() ? "" : "disabled"
              }`}
              onClick={increaseWalkwayWidth}
            >
              +
            </div>
          </div>
          <div className="tooltip-control-question-button">
            <Button
              text="?"
              variant="control question-button"
              onMouseOver={() => setShowControls('tooltip_walkway')}
              onMouseOut={() => setShowControls(null)}
            />
          </div>
          {showControls === 'tooltip_walkway' && (
            <div className="abs-tooltip-text" id="tooltip_walkway" style={{marginTop: getTooltipMargin()}}>
              Walkway Width is the spacing between pairs of rows. This space allows you to fully access and service your plants. Walkway Width is also the space around your whole Platform Area, giving you space between your plants and the walls of your room.
            </div>
          )}
        </div>

        <div
          className={`step-row ${
            window.maxZoneWidth || window.inputsDisabled
              ? "step-row__disabled"
              : ""
          }`}
        >
          <div className="description-tsi">
            Sub-Zone Width
            <span>1 or 2FT</span>
          </div>
          <div className="input-dimension-tsi has-controls">
            <div
              className={`reduce-length ${
                isPossibleToReduceZoneWidth() ? "" : "disabled"
              }`}
              onClick={decreaseZoneWidth}
            >
              -
            </div>
            <input
              readOnly={true}
              type="text"
              name="length-tray"
              className="length-tray"
              placeholder="1ft"
              disabled={
                window.maxZoneWidth || window.inputsDisabled ? true : false
              }
              value={state.zoneWidth}
            />
            <div
              className={`raise-length ${
                isPossibleToIncreaseZoneWidth() ? "" : "disabled"
              }`}
              onClick={increaseZoneWidth}
            >
              +
            </div>
          </div>
          <div className="tooltip-control-question-button">
            <Button
              text="?"
              variant="control question-button"
              onMouseOver={() => setShowControls('tooltip_subzone')}
              onMouseOut={() => setShowControls(null)}
            />
          </div>
          {showControls === 'tooltip_subzone' && (
            <div className="abs-tooltip-text" id="tooltip_subzone" style={{marginTop: getTooltipMargin()}}>
              Sub-Zone Width is spacing inside your Sub-Zone. This is the inner distance between two rows.
            </div>
          )}
        </div>
      </div>

      <div className="kit-room-control">
        <Button
          text="Back"
          variant="secondary"
          onClick={() => {
            props.history.replace({
              pathname: "/kitbuilder/container-or-medium",
              search: props.history.location.search,
            });
          }}
        />
        <Button
          text="Continue"
          //className={continueDisabled ? "disabled" : ""}
          variant="primary-new"
          onClick={() => {
            if (
              window.offsetX < window.walkwayWidth ||
              window.offsetY < window.walkwayWidth
            ) {
              openPopup({
                content: {
                  title: "WALKWAY AREA INTERFERING WITH WALL",
                  text:
                    "Your walkway area is currently interfering with the room wall. This reduces your walkway width. You can proceed but this may limit accessibility to all of your plants. Would you like to continue with this area placement?",
                },
                buttonYes: "Continue",
                buttonNo: "No",
                className: "save-popup-",
                callbackYes: (closePopup) => {
                  closePopup();
                  // setTimeout(() => {
                  //   setPopupState({
                  //     popupKey: Date.now(),
                  //     open: true
                  //   });
                  // }, 600);
                  props.history.push({
                    pathname: "/kitbuilder/zoning-style",
                    search: props.history.location.search,
                  });
                },
              });
            } else {
              props.history.push({
                pathname: "/kitbuilder/zoning-style",
                search: props.history.location.search,
              });
            }
          }}
        />
      </div>
    </div>
  );
}
