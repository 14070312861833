import { CELL_WIDTH } from "../Constants";

export default class TrayModel {
  getTrayData = (props) => {
    const btnWidth = 45;
    const btnHeight = 45;

    let btnY = 0;
    let btnX = 0;

    let trayY = 0;

    if (props.variant === "right" || 1) {
      btnY = props.y //+ props.bodyHeight - btnHeight;
      btnX = props.x + props.bodyWidth + 10;
      trayY = props.y;
    } else {
      btnY = props.y - btnHeight - 10;
      btnX = props.x;

      trayY = props.y;
    }

    const textY = trayY + Math.floor(props.bodyHeight / 2) - 15;

    return {
      btnWidth,
      btnHeight,
      textY,
      btnY,
      btnX,
      trayY,
    };
  };
  generateImagesArr = ({trayWidth, trayHeight, x, y, padding}) => {
    const images = [];
    let startY = y;
    const imgWidth = CELL_WIDTH * 4;
    const colCount = Math.ceil(trayWidth / imgWidth);
    const rowCount = Math.ceil(trayHeight / imgWidth);

    for (let i = 0; i < rowCount; i++) {
      let startX = x;
      for (let ii = 0; ii < colCount; ii++) {
        const item = {
          width: imgWidth,
          height: imgWidth,
          x: startX,
          y: startY,
        };
        if (padding) {
          item.width = item.width - padding * 2;
          item.height = item.height - padding * 2;
          item.x = item.x + padding;
          item.y = item.y + padding;
        }
        images.push(item);
        startX += imgWidth;
      }
      startY += imgWidth;
    }
    return {images};
  };
}
