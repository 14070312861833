import React, { useEffect } from "react";
import Button from "../../../components/Button/Button";
import RoomLayout from "./KitRoomLayout";
import additionalIcon from "../../../../assets/image/additional-plus.png";
import xIcon from "../../../../assets/image/xIcon.png";
import arrowTop from "../../../../assets/image/arrowTop.png";
import * as roomFunctions from "./KitRoomFunctions";
import { useDispatch } from "react-redux";

export default function KitTraySize(props) {
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    measureSystem: "FT",
    traysList: props.trays,
    maxLength: props.roomSize.roomLength || 48,
  });

  function onChangeTrays(newTrays) {
    const error = roomFunctions.getTrayError({
      ...props.roomSize,
      trays: newTrays,
    });
    if (error) {
      props.openPopup({
        content: error.message,
      });
    } else {
      props.setTrays(newTrays);
    }
  }
  function decreaseLength(index) {
    onChangeTrays(
      props.trays.map((item, _index) => {
        if (_index === index) {
          let lengthVal = item.lengthVal;
          if (lengthVal != "") {
            if (parseInt(lengthVal) > 4) {
              lengthVal = parseInt(lengthVal) - 4;
            }
          } else {
            lengthVal = 4;
          }
          return {
            ...item,
            lengthVal,
          };
        }
        return item;
      })
    );
  }

  function increaseLength(thisitem, index) {
    let maxLength = 48;
    let lengthVal = thisitem.lengthVal;
    //to be checked
    if (thisitem.xPosition == true) {
      maxLength = props.roomSize.roomLength;
    } else {
      maxLength = props.roomSize.roomWidth;
    }
    if (lengthVal != "") {
      if (parseInt(lengthVal) + 4 <= maxLength) {
        lengthVal = parseInt(lengthVal) + 4;
      }
    } else {
      lengthVal = 4;
    }

    const { offsetX, offsetY } = roomFunctions.getValidatedOffset(
      {
        ...thisitem,
        lengthVal,
      },
      props.roomSize.roomWidth,
      props.roomSize.roomLength
    );

    onChangeTrays(
      props.trays.map((item, _index) => {
        if (_index === index) {
          return {
            ...item,
            lengthVal,
            offsetX,
            offsetY,
          };
        }
        return item;
      })
    );
  }

  function duplicateTray(item, index) {
    let alpha = "a";
    const label =
      "TRAY " +
      String.fromCharCode(
        alpha.charCodeAt(0) + parseInt(props.trays.length)
      ).toUpperCase();

    onChangeTrays([
      ...props.trays,
      {
        id: props.trays.length + 1,
        label,
        widthVal: item.widthVal,
        lengthVal: item.lengthVal,
        boxStatus: true,
        xPosition: item.xPosition,
        isRotated: item.isRotated,
        ...roomFunctions.defaultTrayOffset(
          item.widthVal,
          item.lengthVal,
          props.roomSize.roomLength,
          props.roomSize.roomWidth,
          item.isRotated
        ),
      },
    ]);
  }
  function generateAdditionalTray() {
    let alpha = "a";
    let boxStatus = false;
    if (props.trays.length == 0) {
      boxStatus = true;
    }
    const label =
      "TRAY " +
      String.fromCharCode(
        alpha.charCodeAt(0) + parseInt(props.trays.length)
      ).toUpperCase();

    const widthVal = 4;
    const lengthVal = 4;
    const isRotated = true;
    return {
      id: props.trays.length + 1,
      label,
      widthVal,
      lengthVal,
      boxStatus: true,
      xPosition: false,
      isRotated,
      ...roomFunctions.defaultTrayOffset(
        widthVal,
        lengthVal,
        props.roomSize.roomLength,
        props.roomSize.roomWidth,
        isRotated
      ),
    };
  }
  function addAdditionalTray() {
    const tray = generateAdditionalTray();
    onChangeTrays([...props.trays, tray]);
  }

  function removeTray(index) {
    let trays = props.trays;
    trays.splice(index, 1);

    onChangeTrays(trays);
  }

  function collapseTray(index) {
    onChangeTrays(
      props.trays.map((item, _index) => {
        if (_index === index) {
          return {
            ...item,
            boxStatus: !props.trays[index].boxStatus,
          };
        }
        return item;
      })
    );
  }

  useEffect(() => {
    if (
      !props.trays.length &&
      !roomFunctions.roomSizeError(props.roomSize) &&
      !roomFunctions.getTrayError({
        ...props.roomSize,
        trays: [generateAdditionalTray()],
      })
    ) {
      addAdditionalTray();
    }
  }, []);

  const errorOverlaping = props.errorOverlaping;
  const continueDisabled = props.trays.length === 0 || errorOverlaping;

  return (
    <div className="step-content kit-room-content">
      <h1 className="step-title">
        WHAT ARE YOUR <br />
        TRAY DIMENSIONS?
      </h1>
      <div className="inside-left-content">
        <div className="trays-selected">
          {props.trays.map((item, index) => {
            return (
              <div
                className="tray-selected-item"
                index={index}
                active={item.boxStatus == true ? "1" : "0"}
                key={`tray-form-item---${index}`}
              >
                <div className="tray-item-buttons">
                  <div
                    className="show-hide"
                    onClick={() => collapseTray(index)}
                  >
                    <img src={arrowTop} alt="arrow" />
                  </div>
                  <div
                    className="remove-item"
                    onClick={() => removeTray(index)}
                  >
                    <img src={xIcon} alt="remove" />
                  </div>
                </div>
                <div className="tray-selected-item-content-simple">
                  <div className="top-tsi">
                    <div className="name">{item.label}</div>
                  </div>
                  <div className="simple-dimensions">
                    {item.widthVal + state.measureSystem.toLocaleLowerCase()} -{" "}
                    {item.lengthVal + state.measureSystem.toLocaleLowerCase()}
                  </div>
                </div>
                <div className="tray-selected-item-content">
                  <div className="top-tsi">
                    <div className="name">{item.label}</div>
                  </div>
                  <div className="dimensions-tsi">
                    <div className="dimension-tsi">
                      <div className="description-tsi">WIDTH</div>
                      <div className="input-dimension-tsi">
                        <input
                          type="text"
                          name="width-tray"
                          className="width-tray"
                          value={
                            item.widthVal +
                            state.measureSystem.toLocaleLowerCase()
                          }
                          readOnly
                        />
                      </div>
                      <span>DEFAULT WIDTH</span>
                    </div>
                    <div className="dimension-tsi has-controls">
                      <div className="description-tsi">LENGTH</div>
                      <div className="input-dimension-tsi has-controls">
                        <div
                          className="reduce-length"
                          onClick={() => decreaseLength(index)}
                        >
                          -
                        </div>
                        <input
                          type="text"
                          name="length-tray"
                          className="length-tray"
                          value={
                            item.lengthVal +
                            state.measureSystem.toLocaleLowerCase()
                          }
                          readOnly
                          onChange={() => {
                            onChangeTrays(props.trays);
                          }}
                        />
                        <div
                          className="raise-length"
                          onClick={() => increaseLength(item, index)}
                        >
                          +
                        </div>
                      </div>
                      <span>IN UNITS OF 4FT</span>
                    </div>
                  </div>
                  <div
                    className="duplicate"
                    onClick={() => duplicateTray(item, index)}
                  >
                    DUPLICATE TRAY
                    <span className="duplicate-tray">
                      <img src={additionalIcon} />
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="additional-tray">
          <div className="step-title">ADD ADDITIONAL TRAY?</div>
          <Button text="" variant="add" onClick={() => addAdditionalTray()} />
        </div>
      </div>

      {/* <Button text="Next" onClick={() => submitRoomSize()} /> */}
      <div className="kit-room-control">
        <Button
          text="Back"
          variant="secondary"
          onClick={() => {
            props.history.replace({
              pathname: "/kitbuilder/grow-area",
              search: props.history.location.search,
            });
          }}
        />
        <Button
          text="Continue"
          variant="primary-new"
          className={continueDisabled ? "disabled" : ""}
          onClick={() => {
            if (errorOverlaping) {
              props.openPopup({
                content: errorOverlaping.message,
              });
              return;
            }
            if (continueDisabled) {
              return;
            }
            const executeThis = () => {
              props.history.push({
                pathname: "/kitbuilder/container-or-medium",
                search: props.history.location.search,
              });
            };
            
            if (props.warningTrayDist) {
              props.openPopup({
                ...props.warningTrayDist,
                content: props.warningTrayDist.message,
                callbackYes: (closePopup) => {
                  closePopup();
                  props.ignoreTraySpacing();
                  closePopup();
                  executeThis();
                },
                buttoOkText: null,
              });
            } else {
              executeThis();
            }
          }}
        />
      </div>
    </div>
  );
}
