import React, { Component } from "react";
import TrayModel from "../Models/Tray";
import { Group, Rect, Text, Transformer } from "react-konva";

export default class ZoneCanvas extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.model = new TrayModel();
    this.groupRef = React.createRef();
  }

  componentDidMount = () => {
    window._info2 = () => {
      console.log(this.props, this.groupRef);
    };
  };

  getFillColor = () => {
    return this.props.type === "ROW" ? "#cccccc" : "white"
  }

  getTextX = () => {
    return this.props.type === "SUBZONE" ? this.props.x - 25 : this.props.x
  }

  getTextY = () => {
    if (this.props.platformDrainageDirection === 'left' || this.props.platformDrainageDirection === 'right') {
      return this.props.type === "SUBZONE" ? this.props.y + this.props.bodyHeight / 2 - 10 : this.props.y + this.props.bodyHeight - 45
    }

    return this.props.type === "SUBZONE" ? this.props.y + this.props.bodyHeight - 100 : this.props.y + this.props.bodyHeight - 45
  }

  getTextWidth = () => {
    return this.props.type === "SUBZONE" ? this.props.bodyWidth + 50 : this.props.bodyWidth
  }

  render() {

    return (
      <>
        <Rect
          width={this.props.bodyWidth}
          height={this.props.bodyHeight}
          x={this.props.x}
          y={this.props.y}
          fill={this.getFillColor()}
          strokeEnabled={true}
          strokeScaleEnabled={true}
          stroke="#707070"
          strokeWidth={1}
          id={this.props.id}
          zIndex="999"
        />
        <Text
          x={this.getTextX()}
          y={this.getTextY()}
          width={this.getTextWidth()}
          height={this.props.bodyHeight}
          fill="black"
          text={this.props.type}
          fontSize={21}
          lineHeight={1}
          fontFamily="Lato Bold"
          align="center"
          verticalAlign="center"
          transform={`translate(${this.props.y + this.props.bodyHeight - 45}, ${this.props.x}) rotate(${180})`}
          wrap="none"
        />
      </>
    );
  }
}
