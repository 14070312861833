import * as React from "react";
import "./Input.scss";

/**
 * @param {object} props Component props
 * @param {string | undefined} props.variant - "outline", "min"
 * @param {string | undefined} props.className
 * @param {string | undefined} props.placeholder
 * @param {string | undefined} props.type
 * @param {number | undefined} props.min
 * @param {number | undefined} props.max
 * @param {function} props.onEnter
 * @param {function} props.onChange
 */
export default function Input(props) {

  function reduceValue(){
    let prevValue = parseFloat(props.value);
    let decrement = props.step || 1;
    if(props.min){
      if(prevValue - decrement >= props.min){
        props.onChange({
          target: {
            value: prevValue - decrement
          }});
      }
    }else{
      props.onChange({
        target: {
          value: prevValue - decrement
        }});
    }
    
  }

  function addValue(){
    let prevValue = parseFloat(props.value);
    let increment = props.step || 1;
    if(props.max){
      if(prevValue + increment < props.max){
        props.onChange({
          target: {
            value: prevValue + increment
          }});
      }
    }else{
      props.onChange({
        target: {
          value: prevValue + increment
        }});
    }
  }

  return (
    <div className="input-group">
      {props.numericControl && props.type == 'number' &&
      <button 
      className="minus"
      onClick={() => reduceValue()}
      >
        -
      </button>}
      <input
        onKeyUp={(event) => {
          if (
            (event.keyCode === 13 || event.key === 13) &&
            typeof props.onEnter === "function"
          ) {
            props.onEnter(event);
          }
        }}
        onChange={(event) => {
          if (typeof props.onChange === "function") {
            props.onChange(event);
          }
        }}
        className={
          "custom-input variant-" +
          (props.variant || "") +
          (props.className ? " " + props.className : "")
        }
        type={props.type || "text"}
        min={props.min}
        max={props.max}
        value={props.value}
        placeholder={props.placeholder}
      ></input>
      {props.numericControl && props.type == 'number' && 
      <button 
      className="plus"
      onClick={() => addValue()}
      >
        +
      </button>}
    </div>
  );
}
