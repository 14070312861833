// @flow
import Axios from "axios";
import * as React from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import ExtraKits from "./ExtraKits";
import PreMadeKits from "./PreMadeKits";
import { layoutActions } from "../../redux/actionFunctions/layoutActions";
import { gql, useApolloClient } from "@apollo/client";
import { purchaseErrors } from "./purchaseKitFunctions";

export default function PurchaseKits(props) {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    plantNumber: "",
    growPot: undefined,
    allPlantItems: {},
    upsells: [],
    allNumbers: [],
  });

  const [clientData, setClientData] = React.useState({ client_cart_id: "", cartItemsClient: [] });

  /**
   * used for triggering an action after loader was rendered (to avoid setLoader off render trigger before setLoader on in cas request is faster than render )
   */
  const [loaderState, setLoaderState] = React.useState({
    key: undefined,
    action: undefined,
    callback: undefined,
  });

  function getData() {
    if (loaderState.open && loaderState.action === "getData") {
      return;
    }
    setLoaderState({
      key: Date.now(),
      open: true,
      action: "getData",
      callback: () => {
        Axios.get("/rbapi/getKits.php")
          .then(({ data }) => {
            const computeArray = (item, index) => ({
              ...item,
              label: item.name,
              key: `${item.name}-${index}-${Date.now()}`,
              image: item.image || item.img,
              quantity: item.quantity || item.qty,
            });
            const allPlantItems =
              data?.num_plants && typeof data?.num_plants === "object"
                ? Object.entries(data.num_plants).reduce(
                    (final, [number, value]) => {
                      if (Array.isArray(value)) {
                        return {
                          ...final,
                          [number]: value.map(computeArray),
                        };
                      }
                      return final;
                    },
                    {}
                  )
                : {};
            const upsells = Array.isArray(data?.upsells)
              ? data.upsells.map(computeArray)
              : [];

            let plantNumber = "";
            if (Object.entries(allPlantItems).length) {
              plantNumber =
                Object.keys(allPlantItems).find((number) => number === "6") ||
                Object.keys(allPlantItems)[0];
            }
            setState({
              ...state,
              plantNumber,
              upsells,
              allPlantItems,
              allNumbers: Object.entries(allPlantItems).map(
                ([number, items]) => ({
                  label: number,
                  value: number,
                })
              ),
            });
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setLoaderState({
              open: false,
            });
          });
      },
    });
  }
  function openPopup(payload) {
    let key;
    if (payload.error_code) {
      key = payload.error_code;
    } else {
      key = Date.now();
    }
    layoutActions(dispatch).pushPopupStack({
      key,
      title: "",
      buttoOkText: "Ok",
      callbackNo: (closePopup) => {
        closePopup();
      },
      ...payload,
    });
  }

  function getEmptyCart() {
    window.$.ajax({
      url: `${window.BASE_URL}roombuilderconfig/customerdata/customercart`,
      type: "GET",
      data: {},
      dataType: "json",
      success: function (data) {
        if (data?.client_cart_id) {
          setClientData({
            client_cart_id: data.client_cart_id,
            key: Date.now(),
          });
        }
      },
      error: function (request, error) {
        console.log(error);
      },
    });
  }

  function addProductsToCart(items) {
    return client
      .mutate({
        mutation: gql`
        mutation {
          addProductsToCart(
            cartId: "${clientData.client_cart_id}"
            cartItems: [
              ${items.reduce(
                (final, current) =>
                  final +
                  " " +
                  `
                      {
                        quantity: ${current.quantity}
                        sku: "${current.sku}"
                        room_name: ""
                        room_id: 79
                      }`,
                ""
              )}
            ]
          ) {
            cart {
              items {
                id
                product {
                  name
                  sku
                }
                quantity
              }
            }
          }
        }
        `,
      })
      .then((result) => {
        const cartItemsClient =
          result?.data?.addProductsToCart?.cart?.items || [];
        if (Array.isArray(result?.errors) && result?.errors.length > 0) {
          openPopup({
            content: purchaseErrors.GENERIC_ERROR.message,
          });
          return new Promise((resolve, reject) => reject(result));
        }
        setClientData({
          ...clientData,
          cartItemsClient: Array.isArray(cartItemsClient) ? cartItemsClient : clientData.cartItemsClient
        })
        return new Promise((resolve, reject) => resolve(cartItemsClient));
      })
      .catch(() => {});
  }

  function getClientCart() {
    return client
      .query({
        query: gql`
        {
          cart(cart_id: "${clientData.client_cart_id}") {
            items {
              id
              product {
                name
                sku
              }
              quantity
            }
          }
        }
      `,
      })
      .then((result) => {
        const cartItemsClient = result?.data?.cart?.items || [];
        setClientData({
          ...clientData,
          cartItemsClient,
        });
        return new Promise((resolve, reject) => resolve(cartItemsClient));
      })
      .catch(() => {});
  }

  React.useLayoutEffect(() => {
    getData();
    getEmptyCart();
    window.devSetEmptyCart = (value) => {
      setClientData({
        ...clientData,
        client_cart_id: value,
        key: Date.now()
      })
    }
  }, []);

  React.useEffect(() => {
    if (clientData.key) {
      getClientCart();
    }
  }, [clientData.key]);

  React.useEffect(() => {
    if (
      loaderState.action &&
      loaderState.key &&
      typeof loaderState.callback === "function"
    ) {
      loaderState.callback();
    }
  }, [loaderState.key]);

  return (
    <Switch>
      <Route
        path="/purchase-kits/plant"
        render={(routerProps) => (
          <PreMadeKits
            {...routerProps}
            setPurchaseKit={({ plantNumber, growPot }) => {
              setState({ ...state, plantNumber, growPot });
            }}
            plantNumber={state.plantNumber}
            growPot={state.growPot}
            growItems={state.allPlantItems[state.plantNumber] || []}
            allNumbers={state.allNumbers}
            loading={loaderState.open && loaderState.action === "getData"}
            openPopup={openPopup}
          />
        )}
      />
      {state.growPot && (
        <Route
          path="/purchase-kits/extra"
          render={(routerProps) => (
            <ExtraKits
              {...routerProps}
              loading={loaderState.open && loaderState.action === "getData"}
              upsells={state.upsells}
              openPopup={openPopup}
              growPot={state.growPot}
              growItems={state.allPlantItems[state.plantNumber] || []}
              addProductsToCart={addProductsToCart}
              cartItemsClient={clientData.cartItemsClient}
            />
          )}
        />
      )}
      <Route
        path="/purchase-kits"
        render={() => <Redirect to="/purchase-kits/plant" />}
      />
    </Switch>
  );
}
