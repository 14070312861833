// @flow
import * as React from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { layoutActions } from "../../../redux/actionFunctions/layoutActions";
import PortalContainer from "./../../../components/PortalContainer/PortalContainer";
import "./Popup.scss";

import modalTopBg from "./../../../../assets/image/modalTopBg.png";
import modalLogo from "./../../../../assets/image/modalLogo.png";

export function Popup(props) {
  const dispatch = useDispatch();
  const { popup } = useSelector(({ layout }) => ({
    popup: layoutActions().getCurrentPopup(layout.popupStack),
  }));

  if (!popup) {
    return "";
  }
  function closePopup() {
    layoutActions(dispatch).popPopupStack({ ...popup });
  }
  return (
    <div
      className={"modal-cstm" + (popup.className ? " " + popup.className : "")}
      {...(popup.nodeAttributes ? popup.nodeAttributes : {})}
    >
      <div className="modal-cstm-container">
        <div className="modal-cstm-content">
          <div className="modal-cstm-top">
            <img src={modalTopBg} alt="FloraFlex" />
          </div>
          <div className="modal-cstm-logo">
            <img src={popup.logo || modalLogo} alt="FloraFlex" />
          </div>
          {/* POPUP TITLE AND TEXT ....................................................*/}
          {(popup.content.title || popup.content.text) && (
            <div className="modal-cstm-data">
              <div className="modal-cstm-title">
                  {(popup.content.title == "" ? popup.content.text : popup.content.title)}
              </div>
              <div className="modal-cstm-text">
              {(popup.content.title != "" ? popup.content.text : "")}
              </div>
            </div>
          )}
          {/* POPUP CHILD STANDALONE INNER NODE (optional)....................................................*/}
          {popup.content.node && <> {popup.content.node} </>}
          {/* POPUP BUTTON CONTROLS ....................................................*/}
          {(popup.buttoOkText || popup.buttonNo || popup.buttonYes) && (
            <div className="modal-buttons">
              {popup.buttoOkText && (
                <Button variant="primary" onClick={closePopup}>
                  {" "}
                  {popup.buttoOkText}
                </Button>
              )}
              {popup.buttonNo && (
                <Button
                  variant="secondary"
                  onClick={() => {
                    const callbackNo = popup.callbackNo;
                    if (typeof callbackNo === "function") {
                      callbackNo(closePopup);
                    } else {
                    }
                    closePopup();
                  }}
                >
                  {popup.buttonNo}
                </Button>
              )}
              {popup.buttonYes && (
                <Button
                  variant="primary"
                  onClick={() => {
                    const callbackYes = popup.callbackYes;
                    if (typeof callbackYes === "function") {
                      callbackYes(closePopup);
                    } else {
                      closePopup();
                    }
                  }}
                >
                  {" "}
                  {popup.buttonYes}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
