// @flow
import * as React from "react";

export default function Room3D(props) {
  function init() {
    // if (window.xeogl) {
    //   const xeogl = window.xeogl;
    //   const src = window.location.origin + "/room3d/scene.gltf";
    //   var model = new xeogl.GLTFModel({
    //     id: "xeogl-room3d",
    //     src,
    //     transform: new xeogl.Scale({
    //       xyz: [0.01, 0.01, 0.01],
    //     }),
    //   });

    //   var camera = model.scene.camera;

    //   camera.eye = [-180.21, 248.69, -262.17];
    //   camera.look = [-79.57, -23.08, 2.36];
    //   camera.up = [0.24, 0.72, 0.64];

    //   var model = new xeogl.GLTFModel({
    //     id: "xeogl-room3d",
    //     src,
    //   });

    //   model.on("loaded", function () {
    //     // When model loaded, highlight its furniture meshes and fit to view

    //     var meshIds = [
    //       "office#79.0",
    //       "office#31.0",
    //       "office#105.0",
    //       "office#8.0",
    //       "office#65.0",
    //       "office#65.0", //....
    //     ];

    //     for (var i = 0; i < meshIds.length; i++) {
    //       var id = meshIds[i];
    //       var mesh = model.meshes[id];

    //       mesh.highlight = true;
    //     }

    //     var cameraFlight = new xeogl.CameraFlightAnimation();
    //     cameraFlight.flyTo(model);
    //   });

    //   // Control camera with mouse and touch
    //   var cameraControl = new xeogl.CameraControl();
    // }
  }
  React.useEffect(() => {
    init();
  }, []);
  return <div id="xeogl-room3d"></div>;
}
