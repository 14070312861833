// @flow
import React, { useContext, useEffect } from "react";

import "./AuthPage.scss";
import { authenticaionActions } from "../redux/actionFunctions/authenticationActions";
import { useDispatch } from "react-redux";
import { gql, ApolloConsumer } from "@apollo/client";
import { Popup } from "./../layout/components/Popup/Popup";
import { layoutActions } from "../redux/actionFunctions/layoutActions";
import Checkbox from "./../components/Checkbox/Checkbox";

import fullFloraLogo from "../../assets/image/fullDashboardLogo.png";
import eyePassword from "../../assets/image/eye-gray.png";
import { authErrors, phoneNumberIsValid } from "./authFunctions";
import AppContext from "../AppContext";
import ForgotPassword from "./ForgotPassword";
import imageA from "../../assets/image/auth/RB_5Gal.jpg";
import imageB from "../../assets/image/auth/RB_10Gal.jpg";
import imageC from "../../assets/image/auth/RB_Matrix.jpg";
import imageD from "../../assets/image/auth/RB_PotPro.jpg";
import imageE from "../../assets/image/auth/RB_QuickFill.jpg";
import imageF from "../../assets/image/auth/RB_Rockwool.jpg";
import cartFunctions from "../pages/KitBuilder/KitRoom/KitCartFunctions";

const imagesArray = [imageA, imageB, imageC, imageD, imageE, imageF];

let timeout = 0;

export function AuthPage(props) {
  const dispatch = useDispatch();
  const appContext = useContext(AppContext);
  const [state, setState] = React.useState({
    loginTab: true,
    registerTab: false,

    emailLogin: "",
    passwordLogin: "",
    loadingLogin: false,

    firstNameRegister: "",
    lastNameRegister: "",
    emailRegister: "",
    phoneRegister: "",
    passwordRegister: "",
    passwordConfirmRegister: "",
    termsRegister: false,
    disabledRegister: false,
    loadingRegister: false,
    inputLogin: true,
    inputRegister: true,
    inputRegisterConfirm: true,
    registerBtnActive: false,
    bgImage: imagesArray[Math.floor(Math.random() * imagesArray.length)],

    appVersion: "v1.00",
  });

  /**
   * used for triggering an action after loader was rendered (to avoid setLoader off render trigger before setLoader on in cas request is faster than render )
   */
  const [loaderState, setLoaderState] = React.useState({
    key: undefined,
    action: undefined,
    callback: undefined,
  });

  function setActiveLogin() {
    setState({
      ...state,
      loginTab: true,
      registerTab: false,
    });
  }
  function setActiveRegister() {
    setState({
      ...state,
      loginTab: false,
      registerTab: true,
    });
  }

  function login(emailLogin = null, passwordLogin = null) {
    let email = document.querySelectorAll('input[name="email"]');
    let password = document.querySelectorAll('input[name="password"]');

    let email_login = emailLogin || state.emailLogin;
    let password_login = passwordLogin || state.passwordLogin;

    let errorToShow;
    errorToShow =
      !email_login ||
      !new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email_login)
        ? authErrors.LOGIN_FAIL
        : undefined;

    if (!errorToShow && password_login == "") {
      errorToShow = authErrors.LOGIN_FAIL;
    }

    if (!errorToShow) {
      password[0].style.border = "none";
      email[0].style.border = "none";
      props.client
        .mutate({
          mutation: gql`
          mutation {
            generateCustomerToken(email: "${email_login}", password: "${password_login}") {
              token
            }
          }
        `,
        })
        .then((result) => {
          const authToken = result?.data?.generateCustomerToken?.token;

          //set Local storage
          if (authToken !== null) {
            localStorage.setItem("clientToken", authToken);

            appContext.updateClientHeaders(authToken);

            props.client
              .query({
                query: gql`
                  {
                    customer {
                      id
                      firstname
                      lastname
                      email
                    }
                  }
                `,
              })
              .then((result) => {
                const authClient = result?.data?.customer;
                const elseCase = () => {
                  setLoaderState({
                    open: false,
                  });
                  openPopup({
                    content: {
                      title: (
                        <>
                          {" "}
                          INCORRECT <br />
                          EMAIL OR PASSWORD{" "}
                        </>
                      ),
                      text: (
                        <>
                          <div className="missing-req-fields">
                            We cannot find an account with the entered email or
                            password. Please try again or register a new
                            account.
                          </div>
                        </>
                      ),
                    },
                  });
                  setState({
                    ...state,
                    loadingLogin: false,
                  });
                }
                if (authClient) {
                  setTimeout(() => {
                    cartFunctions(props.client)
                      .getClientCart()
                      .then((resultCart) => {
                        const cartItems =
                          resultCart?.data?.customerCart?.items || [];
                        const clientCartId =
                          resultCart?.data?.customerCart?.id || "";
                        authenticaionActions(dispatch).setClientCart(
                          clientCartId,
                          cartItems
                        );
                        /**
                         * add the original get params (share_code or room_id) and act on them
                         */
                        props.handleOriginalGet();

                        authenticaionActions(dispatch).login({
                          ...authClient,
                          clientId: authClient.id,
                          token: authToken,
                        });
                        
                      })
                      .catch((error) => {
                        if (
                          Array.isArray(error?.graphQLErrors) &&
                          error.graphQLErrors.length &&
                          error.graphQLErrors.some(
                            (item) =>
                              item?.extensions?.category ===
                              "graphql-authorization"
                          )
                        ) {
                          elseCase();
                        }
                      });
                  
                  }, 1000);
                } else {
                  elseCase();
                }
              })
              .catch((error) => {
                // console.log("ERROR WHILE GETTING USER DATA", { error })
                setLoaderState({
                  open: false,
                });
                openPopup({
                  content: {
                    title: (
                      <>
                        {" "}
                        INCORRECT <br />
                        EMAIL OR PASSWORD
                      </>
                    ),
                    text: (
                      <>
                        <div className="missing-req-fields">
                          We cannot find an account with the entered email or
                          password. Please try again or register a new account.
                        </div>
                      </>
                    ),
                  },
                });
                setState({
                  ...state,
                  loadingLogin: false,
                });
              });
          } else {
            setLoaderState({
              open: false,
            });
            openPopup({
              content: {
                title: (
                  <>
                    {" "}
                    INCORRECT <br />
                    EMAIL OR PASSWORD{" "}
                  </>
                ),
                text: (
                  <>
                    <div className="missing-req-fields">
                      We cannot find an account with the entered email or
                      password. Please try again or register a new account.
                    </div>
                  </>
                ),
              },
            });
            setState({
              ...state,
              loadingLogin: false,
            });
          }
        })
        .catch((error) => {
          console.log("ERROR WHILE TRYING TO AUTHENTICATE USER", { error });
          setLoaderState({
            open: false,
          });

          if (error?.message === "Failed to fetch") {
            openPopup({
              content: {
                title: `Network Error`,
                text: (
                  <>
                    <div className="missing-req-fields">Failed to fetch</div>
                  </>
                ),
              },
            });
          } else {
            openPopup({
              content: {
                title: (
                  <>
                    {" "}
                    INCORRECT <br />
                    EMAIL OR PASSWORD
                  </>
                ),
                text: (
                  <>
                    <div className="missing-req-fields">
                      We cannot find an account with the entered email or
                      password. Please try again or register a new account.
                    </div>
                  </>
                ),
              },
            });
          }

          setState({
            ...state,
            loadingLogin: false,
          });
        });
    } else {
      setLoaderState({
        open: false,
      });
      openPopup({
        content: {
          title: errorToShow.message.title ? (
            errorToShow.message.title
          ) : (
            <>
              {" "}
              INCORRECT <br />
              EMAIL OR PASSWORD
            </>
          ),
          text: errorToShow.message.text ? (
            errorToShow.message.text
          ) : (
            <>
              <div className="missing-req-fields">
                We cannot find an account with the entered email or password.
                Please try again or register a new account.
              </div>
            </>
          ),
        },
      });
      setState({
        ...state,
        loadingLogin: false,
      });
      if (
        email_login !== "" &&
        new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email_login)
      ) {
        email[0].style.border = "none";
      } else {
        email[0].style.border = "1px solid red";
      }
      if (password_login !== "") {
        password[0].style.border = "none";
      } else {
        password[0].style.border = "1px solid red";
      }
    }
  }

  function loginPrepare(emailLogin = null, passwordLogin = null) {
    if (loaderState.open && loaderState.action === "loginAction") {
      return;
    }
    setLoaderState({
      key: Date.now(),
      open: true,
      action: "loginAction",
      callback: () => {
        login(emailLogin, passwordLogin);
      },
    });
  }

  function register() {
    setState({
      ...state,
      loadingRegister: true,
      disabledRegister: true,
    });

    let firstName = document.querySelectorAll('input[name="firstName"]');
    let lastName = document.querySelectorAll('input[name="lastName"]');
    let emailRegister = document.querySelectorAll(
      'input[name="emailRegister"]'
    );
    let phoneRegister = document.querySelectorAll(
      'input[name="phoneRegister"]'
    );
    let passwordRegister = document.querySelectorAll(
      'input[name="passwordRegister"]'
    );
    let passwordConfirmRegister = document.querySelectorAll(
      'input[name="passwordConfirmRegister"]'
    );
    let termsAgree = document.querySelectorAll(
      ".register-from .checkbox-input span"
    );

    let errorToShow;

    /**
     * display an error based on the order they are on screen
     */
    /**
     * first name, last name
     */
    if (!state.firstNameRegister || !state.lastNameRegister) {
      errorToShow = authErrors.REQUIRED_FIELDS;
    }
    /**
     * email
     */
    if (!errorToShow) {
      errorToShow =
        !state.emailRegister ||
        !new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
          state.emailRegister
        )
          ? authErrors.INVALID_EMAIL
          : undefined;
    }

    /**
     * phone number - validate optional phone number if there's one
     */
    if (
      !errorToShow &&
      state.phoneRegister &&
      state.phoneRegister.length > 0 &&
      !phoneNumberIsValid(state.phoneRegister)
    ) {
      errorToShow = authErrors.INVALID_PHONE;
    }
    /**
     * password then confirm password
     */
    if (
      !errorToShow &&
      state.passwordRegister !== state.passwordConfirmRegister
    ) {
      errorToShow = authErrors.PASSWORD_MATCH;
    }

    if (
      !errorToShow &&
      state.firstNameRegister !== "" &&
      state.lastNameRegister !== "" &&
      state.passwordRegister !== "" &&
      state.passwordConfirmRegister !== "" &&
      state.termsRegister === true
    ) {
      setState({
        ...state,
        registerBtnActive: true,
      });
      firstName[0].style.border = "none";
      lastName[0].style.border = "none";
      emailRegister[0].style.border = "none";
      passwordRegister[0].style.border = "none";
      passwordConfirmRegister[0].style.border = "none";
      termsAgree[0].style.border = "none";
      props.client
        .mutate({
          mutation: gql`
            mutation {
              createCustomerV2(
                input: {
                  firstname: "${state.firstNameRegister}"
                  lastname: "${state.lastNameRegister}"
                  email: "${state.emailRegister}"
                  password: "${state.passwordRegister}"
                  is_subscribed: true
                }
              ) {
                customer {
                  firstname
                  lastname
                  email
                  is_subscribed
                }
              }
            }
            `,
        })
        .then((result) => {
          /*  openPopup({
              content: {
                title: "",
                text: "The account was successfully created!",
              },
            }); */
          // Login the user upon registration success

          loginPrepare(state.emailRegister, state.passwordRegister);

          setState({
            ...state,
            loginTab: false,
            registerTab: true,
            loadingRegister: true,
            disabledRegister: true,
            registerBtnActive: false,
          });
        })
        .catch((error) => {
          console.log("ERROR WHILE CREATING NEW USER", { error });
          let error_message = error?.message;
          setState({
            ...state,
            loadingRegister: false,
            disabledRegister: false,
          });

          if (error_message.toUpperCase().indexOf("PASSWORD") !== -1) {
            openPopup({
              content: {
                title: (
                  <>
                    {" "}
                    INSUFFICIENT
                    <br />
                    PASSWORD
                  </>
                ),
                text: (
                  <>
                    For the security of your account your password must contain:
                    <br />
                    <div className="center">
                      8 characters
                      <br />1 lowercase letter
                      <br />1 uppercase letter
                      <br />1 special character(s)
                      <br />
                      OR 1 number(s)
                    </div>
                  </>
                ),
              },
            });
          } else {
            openPopup({
              content: {
                title: "",
                text: error_message
                  ? error_message
                  : "There was an error while trying to register this account, please try again later.",
              },
            });
          }
        });
    } else {
      setState({
        ...state,
        loadingRegister: false,
        disabledRegister: false,
        registerBtnActive: false,
      });
      openPopup({
        content: {
          title: errorToShow
            ? errorToShow.message.title
            : "MISSING REQUIRED FIELDS",
          text: errorToShow
            ? errorToShow.message.text
            : "At least one required field is empty. Please fill in all required fields to complete registration.",
        },
      });
      if (state.firstNameRegister !== "") {
        firstName[0].style.border = "none";
      } else {
        firstName[0].style.border = "1px solid red";
      }
      if (state.lastNameRegister !== "") {
        lastName[0].style.border = "none";
      } else {
        lastName[0].style.border = "1px solid red";
      }

      if (
        state.emailRegister !== "" &&
        new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
          state.emailRegister
        )
      ) {
        emailRegister[0].style.border = "none";
      } else {
        emailRegister[0].style.border = "1px solid red";
      }
      if (state.passwordRegister !== "") {
        passwordRegister[0].style.border = "none";
      } else {
        passwordRegister[0].style.border = "1px solid red";
      }
      if (state.passwordConfirmRegister !== "") {
        passwordConfirmRegister[0].style.border = "none";
      } else {
        passwordConfirmRegister[0].style.border = "1px solid red";
      }
      if (
        state.passwordRegister === state.passwordConfirmRegister &&
        state.passwordConfirmRegister !== "" &&
        state.passwordConfirmRegister != ""
      ) {
        passwordRegister[0].style.border = "none";
        passwordConfirmRegister[0].style.border = "none";
      } else {
        passwordRegister[0].style.border = "1px solid red";
        passwordConfirmRegister[0].style.border = "1px solid red";
      }
      if (state.termsRegister) {
        termsAgree[0].style.border = "none";
      } else {
        termsAgree[0].style.border = "1px solid red";
      }
    }
  }

  function openPopup(payload) {
    let key = Date.now();
    layoutActions(dispatch).pushPopupStack({
      key,
      title: "",
      buttoOkText: "Okay",
      callbackNo: (closePopup) => {
        closePopup();
      },
      ...payload,
    });
  }

  function submitOnEnterForm(event, source) {
    if (event.charCode == 13) {
      if (source == "login") {
        loginPrepare();
      } else if (source == "register") {
        register();
      }
    }
  }

  function cancelRegistration() {
    let firstName = document.querySelectorAll('input[name="firstName"]');
    let lastName = document.querySelectorAll('input[name="lastName"]');
    let emailRegister = document.querySelectorAll(
      'input[name="emailRegister"]'
    );
    let passwordRegister = document.querySelectorAll(
      'input[name="passwordRegister"]'
    );
    let passwordConfirmRegister = document.querySelectorAll(
      'input[name="passwordConfirmRegister"]'
    );
    let termsAgree = document.querySelectorAll(
      ".register-from .checkbox-input span"
    );

    firstName[0].style.border = "none";
    lastName[0].style.border = "none";
    emailRegister[0].style.border = "none";
    passwordRegister[0].style.border = "none";
    passwordConfirmRegister[0].style.border = "none";
    termsAgree[0].style.border = "none";

    setState({
      ...state,
      loginTab: true,
      registerTab: false,
      termsRegister: false,
      loadingRegister: false,
      disabledRegister: false,
      firstNameRegister: "",
      lastNameRegister: "",
      emailRegister: "",
      phoneRegister: "",
      passwordRegister: "",
      passwordConfirmRegister: "",
    });
  }

  function showHidePasswordLogin() {
    setState({
      ...state,
      inputLogin: !state.inputLogin,
    });
  }

  function showHidePasswordRegister() {
    setState({
      ...state,
      inputRegister: !state.inputRegister,
    });
  }

  function showHidePasswordRegisterConfirm() {
    setState({
      ...state,
      inputRegisterConfirm: !state.inputRegisterConfirm,
    });
  }

  function getErrorToShowRegister() {
    let errorToShow;
    /**
     * display an error based on the order they are on screen
     */
    /**
     * first name, last name
     */
    if (!state.firstNameRegister || !state.lastNameRegister) {
      errorToShow = authErrors.REQUIRED_FIELDS;
    }
    /**
     * email
     */
    if (!errorToShow) {
      errorToShow =
        !state.emailRegister ||
        !new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
          state.emailRegister
        )
          ? authErrors.INVALID_EMAIL
          : undefined;
    }
    /**
     * password then confirm password
     */
    if (
      !errorToShow &&
      state.passwordRegister !== state.passwordConfirmRegister
    ) {
      errorToShow = authErrors.PASSWORD_MATCH;
    }
    /* all fields check */
    if (
      state.firstNameRegister == "" ||
      state.lastNameRegister == "" ||
      state.passwordRegister == "" ||
      state.passwordConfirmRegister == "" ||
      state.termsRegister === false
    ) {
      errorToShow = authErrors.REQUIRED_FIELDS;
    }
    return errorToShow;
  }

  function openForgotPasswordForm() {
    layoutActions(dispatch).pushPopupStack({
      key: "FORGOT_PASSWORD_POPUP",
      title: "",
      callbackNo: (closePopup) => {
        closePopup();
      },
      content: {
        title: "",
        text: "",
        node: (
          <ForgotPassword
            closePopup={() => {
              layoutActions(dispatch).popPopupStack({
                key: "FORGOT_PASSWORD_POPUP",
              });
            }}
            editPopup={(payload) => {
              layoutActions(dispatch).editPopupStack({
                key: "FORGOT_PASSWORD_POPUP",
                ...payload,
              });
            }}
            client={props.client}
          />
        ),
      },
      className: "wrapper-save-room-popup-rm-your-build",
    });
  }

  useEffect(() => {
    if (
      loaderState.action &&
      loaderState.key &&
      typeof loaderState.callback === "function"
    ) {
      loaderState.callback();
    }
    return () => {
      clearTimeout(timeout);
    }
  }, [loaderState.key]);

  let errorToShowRegister;
  if (state.registerTab) {
    errorToShowRegister = getErrorToShowRegister();
  } else {
  }

  return (
    <>
      <div className="full-page-content" id="auth">
        <div
          className="left-side-auth"
          style={{ backgroundImage: `url(${state.bgImage})` }}
        >
          {/* <div className="left-content">
            <div className="logo">
              <img src={logoFlora} alt="FloraFlex" />
            </div>
            <div className="motto">
              We drive innovations in hydroponics and growing systems for
              experts.
            </div>
          </div> */}
          <div className="logo-new">
            <img src={fullFloraLogo} alt="FloraFlex" />
          </div>
          <div className="version-txt">{state.appVersion}</div>
        </div>
        <div className="right">
          <div className="right-content">
            <div className="text">
              The FloraFlex<sup>®</sup> Room Builder is FloraFlex's<sup>®</sup>{" "}
              innovation to help the intermediate or professional grower build
              out their perfect grow system.
            </div>
            <div className="text">
              For the home grower, just starting their grow experience and
              looking to grow 4, 6, or 8 plants FloraFlex<sup>®</sup> has
              prepackaged FloraFlex<sup>®</sup> Kits. Our Kits include
              everything you need to get started.
            </div>
            <div className="button-section">
              <button
                type="button"
                className="simple-btn btn-url"
                onClick={() => props.history.replace("/purchase-kits")}
              >
                PURCHASE KITS
              </button>
            </div>
            <div className="separator">OR</div>
            <div className="forms-container">
              <div className="form-tabs">
                <div
                  className="form-item-tab"
                  active={state.loginTab == true ? "1" : "0"}
                  onClick={() => setActiveLogin()}
                >
                  LOGIN
                </div>
                <div
                  className="form-item-tab"
                  active={state.registerTab == true ? "1" : "0"}
                  onClick={() => setActiveRegister()}
                >
                  REGISTER
                </div>
              </div>
              <div className="form-content">
                <div
                  className={
                    state.loginTab == true ? "login-from active" : "login-form"
                  }
                >
                  <form>
                    <div className="form-container">
                      <input
                        name="email"
                        type="text"
                        placeholder="Email address"
                        value={state.emailLogin}
                        onChange={(e) =>
                          setState({ ...state, emailLogin: e.target.value })
                        }
                        onKeyPress={(e) => submitOnEnterForm(e, "login")}
                        value={state.emailLogin}
                      />
                      <div className="input-password-group">
                        <input
                          name="password"
                          type={state.inputLogin ? "password" : "text"}
                          placeholder="Password"
                          value={state.passwordLogin}
                          onChange={(e) =>
                            setState({
                              ...state,
                              passwordLogin: e.target.value,
                            })
                          }
                          onKeyPress={(e) => submitOnEnterForm(e, "login")}
                          value={state.passwordLogin}
                        />
                        <span onClick={() => showHidePasswordLogin()}>
                          <img
                            src={eyePassword}
                            title="Show/Hide password"
                            alt=""
                          />
                        </span>
                      </div>
                    </div>
                    <div className="form-buttons">
                      <button
                        type="button"
                        className="simple-btn"
                        id="login-btn"
                        onClick={() => loginPrepare()}
                        loading={
                          loaderState.open &&
                          loaderState.action === "loginAction"
                            ? "1"
                            : "0"
                        }
                      >
                        {loaderState.open &&
                        loaderState.action === "loginAction" ? (
                          <span></span>
                        ) : (
                          "LOGIN"
                        )}
                      </button>
                      <div
                        className="simple-url"
                        onClick={() => openForgotPasswordForm()}
                      >
                        Forgot Password?
                      </div>
                    </div>
                  </form>
                </div>
                <div
                  className={
                    state.registerTab == true
                      ? "register-from active"
                      : "register-form"
                  }
                >
                  <form>
                    <div className="form-container">
                      <div className="input-double">
                        <input
                          name="firstName"
                          type="text"
                          placeholder="First name"
                          onChange={(e) => {
                            setState({
                              ...state,
                              firstNameRegister: e.target.value,
                            });
                            e.target.style.border = "none";
                          }}
                          onKeyPress={(e) => submitOnEnterForm(e, "register")}
                          value={state.firstNameRegister}
                        />
                        <input
                          name="lastName"
                          type="text"
                          placeholder="Last name"
                          onChange={(e) => {
                            setState({
                              ...state,
                              lastNameRegister: e.target.value,
                            });
                            e.target.style.border = "none";
                          }}
                          onKeyPress={(e) => submitOnEnterForm(e, "register")}
                          value={state.lastNameRegister}
                        />
                      </div>
                      <input
                        name="emailRegister"
                        type="email"
                        placeholder="Email address"
                        onKeyPress={(e) => submitOnEnterForm(e, "register")}
                        onChange={(e) => {
                          setState({ ...state, emailRegister: e.target.value });
                          e.target.style.border = "none";
                        }}
                        value={state.emailRegister}
                      />
                      {/* <input
                        name="phoneRegister"
                        type="text"
                        placeholder="Phone (Optional)"
                        onKeyPress={(e) => submitOnEnterForm(e, "register")}
                        onChange={(e) =>
                          setState({
                            ...state,
                            phoneRegister: e.target.value,
                          })
                        }
                        value={state.phoneRegister}
                      /> */}
                      <div className="input-password-group">
                        <input
                          name="passwordRegister"
                          type={state.inputRegister ? "password" : "text"}
                          placeholder="Password"
                          onKeyPress={(e) => submitOnEnterForm(e, "register")}
                          onChange={(e) => {
                            setState({
                              ...state,
                              passwordRegister: e.target.value,
                            });
                            e.target.style.border = "none";
                          }}
                          value={state.passwordRegister}
                        />
                        <span onClick={() => showHidePasswordRegister()}>
                          <img
                            src={eyePassword}
                            title="Show/Hide password"
                            alt=""
                          />
                        </span>
                      </div>
                      <div className="input-password-group">
                        <input
                          name="passwordConfirmRegister"
                          type={
                            state.inputRegisterConfirm ? "password" : "text"
                          }
                          placeholder="Confirm Password"
                          onKeyPress={(e) => submitOnEnterForm(e, "register")}
                          onChange={(e) => {
                            setState({
                              ...state,
                              passwordConfirmRegister: e.target.value,
                            });
                            e.target.style.border = "none";
                          }}
                          value={state.passwordConfirmRegister}
                        />
                        <span onClick={() => showHidePasswordRegisterConfirm()}>
                          <img
                            src={eyePassword}
                            title="Show/Hide password"
                            alt=""
                          />
                        </span>
                      </div>
                      <div className="checkbox-input">
                        <Checkbox
                          customClass="input-checkbox"
                          checked={state.termsRegister}
                          onCheck={() => {
                            setState({
                              ...state,
                              termsRegister: !state.termsRegister,
                            });
                            let termsAgree = document.querySelectorAll(
                              ".register-from .checkbox-input span"
                            );
                            termsAgree[0].style.border = "none";
                          }}
                        />
                        <div className="label-checkbox">
                          By accepting you agree to our{" "}
                          <a
                            href={`${window.BASE_URL}terms-and-conditions`}
                            target="_blank"
                          >
                            Terms and Conditions
                          </a>{" "}
                          and our{" "}
                          <a
                            href={`${window.BASE_URL}privacy-policy`}
                            target="_blank"
                          >
                            Privacy Policy
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="form-buttons">
                      <button
                        type="button"
                        className="simple-btn"
                        id="cancel-btn"
                        onClick={cancelRegistration}
                      >
                        CANCEL
                      </button>
                      <button
                        type="button"
                        className="simple-btn success-btn"
                        id="submit-account-btn"
                        onClick={() => register()}
                        disabled={state.disabledRegister}
                        status={!errorToShowRegister ? "active" : "inactive"}
                      >
                        {state.loadingRegister ? <span></span> : "SUBMIT"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup />
    </>
  );
}
const WithApolloClient = (props) => (
  <ApolloConsumer>
    {(client) => <AuthPage {...props} client={client} />}
  </ApolloConsumer>
);
export default WithApolloClient;
