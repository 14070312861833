export const listPlantsPerArea = [
  { id: 11, label: "8 PLANT PER 4X4 AREA", value: 8 },
  { id: 1, label: "12 PLANT PER 4X4 AREA", value: 12 },
  { id: 2, label: "16 PLANT PER 4X4 AREA", value: 16 },
];

export const BASE_URL = "https://ffm2.outofdust.co/";
export const CANVAS_TRIGGER_TYPES = {
  ZOOM_IN: "ZOOM_IN",
  ZOOM_OUT: "ZOOM_OUT",
  FULLSCREEN: "FULLSCREEN",
  FRAME_ZOOM: "FRAME_ZOOM",
};
export const canvasDim = {
  width: 746,
  height: 467,
};
/**
 * give access in public directory for 3d scripts
 */
window.CANVAS_TRIGGER_TYPES = CANVAS_TRIGGER_TYPES;

export const productTypes = {
  vf: "vf",
  v1: "v1",
  v2: "v2",
  bf: "bf",
  b1: "b1",
  b2: "b2",
  ft: "ft",
};
