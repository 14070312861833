export const CELL_WIDTH = 60;
export const CELL_COUNT = 1000;
export const howManyCellFitW = 30;
export const howManyCellFitH = 16;
export const gridStartX = -(
  CELL_WIDTH * Math.floor((CELL_COUNT - howManyCellFitW) / 2)
);
export const gridStartY = -(
  CELL_WIDTH * Math.floor((CELL_COUNT - howManyCellFitH) / 2)
);
export const STROKE_WIDTH = 2;
export const gridMiddleX = gridStartX + CELL_WIDTH * (CELL_COUNT / 2 - 1);
export const gridMiddleY = gridStartY + CELL_WIDTH * (CELL_COUNT / 2);

export const gridMiddleScrollPosX = Math.floor(gridMiddleX + CELL_WIDTH / 2);
export const gridMiddleScrollPosY = Math.floor(gridMiddleY + CELL_WIDTH / 2);
export const readOnlyRoomBorderWidth = 6;
export const scaleBy = 1.15;
