// @flow
import { useApolloClient } from "@apollo/client";
import html2canvas from "html2canvas";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  withRouter,
  useParams,
} from "react-router-dom";
import AppContext from "./AppContext";
import Cart from "./pages/Cart/Cart";
import Dashboard from "./pages/Dashboard/Dashboard";
import { KitBuilder } from "./pages/KitBuilder/KitBuilder";
import cartFunctions from "./pages/KitBuilder/KitRoom/KitCartFunctions";
import { authenticaionActions } from "./redux/actionFunctions/authenticationActions";
import { layoutActions } from "./redux/actionFunctions/layoutActions";

import * as roomFunctions from "./pages/KitBuilder/KitRoom/KitRoomFunctions";

let timeout = 0;

const kitBuilderInitialKey = "initial-kitbuilder";

export function Basepage(props) {
  const dispatch = useDispatch();
  const appContext = React.useContext(AppContext);
  const { token, user, clientId } = useSelector(({ auth }) => ({
    token: auth?.user?.token,
    user: auth?.user || {},
    clientId: auth?.user?.clientId,
  }));
  const client = useApolloClient();

  const [kitbuilderManager, setKitbuilderManager] = React.useState({
    key: kitBuilderInitialKey,
  });

  React.useEffect(() => {
    cartFunctions(client)
      .getClientCart()
      .then((resultCart) => {
        const cartItems = resultCart?.data?.customerCart?.items || [];
        const clientCartId = resultCart?.data?.customerCart?.id || "";
        authenticaionActions(dispatch).setClientCart(clientCartId, cartItems);
      })
      .catch((error) => {
        if (
          Array.isArray(error?.graphQLErrors) &&
          error.graphQLErrors.length &&
          error.graphQLErrors.some(
            (item) => item?.extensions?.category === "graphql-authorization"
          )
        ) {
          if (
            window.isLoggedWithUserNameAndPasswordRb !== true //avoid a unauthenticated call done too early after login)
          ) {
            timeout = setTimeout(() => {
              cartFunctions(client)
                .getClientCart()
                .then((resultCart) => {
                  const cartItems = resultCart?.data?.customerCart?.items || [];
                  const clientCartId = resultCart?.data?.customerCart?.id || "";
                  authenticaionActions(dispatch).setClientCart(
                    clientCartId,
                    cartItems
                  );
                })
                .catch(() => {});
            }, 1000);
          } else {
            authenticaionActions(dispatch).logout(client);
          }
        }
      });
    /**
     * gets user data like email, name and other if they are changed
     */
    cartFunctions(client)
      .getClient()
      .then((authClient) => {
        authenticaionActions(dispatch).setClientDetails(
          {
            firstname: authClient.firstname,
            lastname: authClient.lastname,
            email: authClient.email,
            clientId: authClient.id,
          },
          client
        );
      })
      .catch((error) => {
        if (
          Array.isArray(error?.graphQLErrors) &&
          error.graphQLErrors.length &&
          error.graphQLErrors.some(
            (item) => item?.extensions?.category === "graphql-authorization"
          ) &&
          window.isLoggedWithUserNameAndPasswordRb !== true //avoid a unauthenticated call done too early after login
        ) {
          authenticaionActions(dispatch).logout(client);
        }
      });
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  /**
   * KITBUILDER SETUP - start
   */
  function isKitBuilderPage() {
    return (
      props.history.location.pathname &&
      /^\/kitbuilder/.test(props.history.location.pathname)
    );
  }

  function loadRoomFromUrl(roomIdInitial) {
    if (roomIdInitial) {
      const stepPath = props.history.location.pathname;
      fetch(
        `${window.BASE_URL}rbapi/getRooms.php?customer_id=${clientId}&room_id=${roomIdInitial}`
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          return new Promise((resolve, reject) => reject(response));
        })
        .then((data) => {
          const id_room = data?.[0]?.id;
          if (id_room == roomIdInitial && isKitBuilderPage()) {
            props.history.replace({
              pathname: stepPath,
              state: {
                detail: {
                  ...roomFunctions.validatedRoomToEdit(data[0]),
                  stepPath: undefined, //prevent redirecting to the cart summary page by the Kitbuilder useEffect function
                  stepForceUpdate: Date.now(),
                },
              },
            });
            setKitbuilderManager({
              ...kitbuilderManager,
              key: `Kitbuilder-key-${Date.now()}`,
              configured: true,
            });
            return new Promise((resolve, reject) => resolve(data));
          } else {
            setKitbuilderManager({
              ...kitbuilderManager,
              key: `Kitbuilder-key-${Date.now()}`,
              configured: true,
            });
          }
          return new Promise((resolve, reject) => reject(data));
        })
        .catch((err) => {
          /**
           * on error (room doesn't exist) -> remove this room_id from url if is still there
           */
          if (isKitBuilderPage()) {
            let urlParams = new URL(
              `https://dummy-url.ro/${props.history.location.search}`
            ).searchParams;
            const id_room = urlParams.get("room_id");
            if (id_room === roomIdInitial) {
              props.history.replace({
                pathname: props.history.location.pathname,
                search: "",
              });
            }
          }
          setKitbuilderManager({
            ...kitbuilderManager,
            configured: true,
          });
        });
    } else {
      setKitbuilderManager({
        ...kitbuilderManager,
        configured: true,
      });
    }
  }
  function loadRoomFromShareCode(shareCode) {
    if (shareCode) {
      props.resetOriginalParams();

      const stepPath = props.history.location.pathname;
      fetch(
        `${window.BASE_URL}rbapi/getRooms.php?customer_id=${clientId}&share_code=${shareCode}`
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          return new Promise((resolve, reject) => reject(response));
        })
        .then((data) => {
            /**
             * is not the owner
             */
            const needsSaving = Number(data[0].customer_id) !== Number(clientId);
            
            let search = "";
            if (needsSaving) {
              data[0].original_owner_id = data[0].customer_id;
              data[0].room_name = `${data[0].original_owner_firstname} ${data[0].original_owner_lastname} ${data[0].room_name}`;

              delete data[0].customer_id;
              delete data[0].id;
            } else {
              /**
               * is the owner
               */
              search = `?room_id=${data[0].id}`;
            }

            data[0].needsSaving = needsSaving;

            props.history.replace({
              pathname: "/kitbuilder/recommended-builds",
              search,
              state: {
                detail: {
                  ...roomFunctions.validatedRoomToEdit(data[0]),
                  stepPath: undefined, //prevent redirecting to the cart summary page by the Kitbuilder useEffect function
                  needs_saving: needsSaving, //marks if shared room owner is the logged user
                  stepForceUpdate: Date.now(),
                },
              },
            });
            setKitbuilderManager({
              ...kitbuilderManager,
              key: `Kitbuilder-key-${Date.now()}`,
              configured: true,
            });
            return new Promise((resolve, reject) => resolve(data));
        })
        .catch((err) => {
          console.log(err);
          props.history.replace({
            pathname: props.history.pathname,
            search: ""
          });
          setKitbuilderManager({
            ...kitbuilderManager,
            configured: true,
          });
        });
    } else {
      setKitbuilderManager({
        ...kitbuilderManager,
        configured: true,
      });
    }
  }
  /**
   * load room from url if room_id is here
   * in this function <KitBuilder/> component can be reinstantiated with room data into detail router props
   */
  React.useLayoutEffect(() => {
    let urlParams = new URL(
      `https://dummy-url.ro/${props.history.location.search}`
    ).searchParams;

    if (urlParams.get("share_code")) {
      loadRoomFromShareCode(urlParams.get("share_code"));
    } else {
      if (isKitBuilderPage()) {
        loadRoomFromUrl(urlParams.get("room_id"));
      } else {
        localStorage.setItem("rb-last-room-loaded", JSON.stringify({}));
        setKitbuilderManager({
          ...kitbuilderManager,
          configured: true,
        });
      }
    }
  }, []);

  React.useLayoutEffect(() => {
    if (!isKitBuilderPage()) {
      localStorage.setItem("rb-last-room-loaded", JSON.stringify({}));
    }
  }, [props.history.location.pathname]);
  /**
   * KITBUILDER SETUP - end
   */

  //  React.useEffect(() => {
  //    if(kitBuilderInitialKey.key !== kitBuilderInitialKey) {

  //    }
  //  }, [setKitbuilderManager.key])

  return (
    <Switch>
      <Route
        path="/kitbuilder"
        render={(routerProps) =>
          kitbuilderManager.configured ? (
            <KitBuilder {...routerProps} key={kitbuilderManager.key} />
          ) : (
            ""
          )
        }
      />
      <Route path="/cart" component={Cart} />
      <Route path="/dashboard" component={Dashboard} />
      <Route
        path="/"
        render={(props) => {
          props.history.replace({
            pathname: "/dashboard",
            search: props.history.location.search,
          });
          return "";
        }}
      />
      <Redirect exact from="/" to="/dashboard" />
    </Switch>
  );
}

export default withRouter(Basepage);
