import React from "react";
import LayoutInit from "./LayoutInit";
import { useSelector } from "react-redux";

import LayoutSplashScreen from "./../components/LayoutSplashScreen/LayoutSplashScreen";
import Header from "./components/Header/Header";
import { Popup } from "./components/Popup/Popup";
import fullFloraLogo from "../../assets/image/fullDashboardLogo.png";
import SharePopup from "./components/SharePopup/SharePopup";

export default function Layout({ children }) {
  const { loader, sharePopup } = useSelector(({ layout }) => ({
    loader: [],
    sharePopup: layout.sharePopup,
  }));

  return (
    <>
      {loader.length > 0 && <LayoutSplashScreen />}
      {sharePopup.key && (
        <SharePopup {...sharePopup} key={sharePopup.key} />
      )}
      <div className="device-warning">
        <p>
          The FloraFlex<sup>&reg;</sup> Room Builder experience is optimized for
          large screens &amp; will not work as intended on this device (you may
          need to expand your browser window).
        </p>
        <div className="logo-new">
          <img src={fullFloraLogo} className="device-logo" alt="FloraFlex" />
        </div>
      </div>
      <main className="d-flex flex-column flex-root">{children}</main>
      <LayoutInit />
    </>
  );
}
