// @flow
// import * as React from "react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authenticaionActions } from "../../redux/actionFunctions/authenticationActions";
import { useSelector } from "react-redux";
import { gql, ApolloConsumer, useApolloClient } from "@apollo/client";
// import {useEffect} from "react";
import { Popup } from "./../../layout/components/Popup/Popup";
import { layoutActions } from "./../../redux/actionFunctions/layoutActions";
import { Button, Spinner } from "react-bootstrap";
import axios from "axios";

import "./Dashboard.scss";

// import greenLogo from "../../../assets/image/rb-logo-var1.png";
import greenLogo from "../../../assets/image/RoomBuilderLogoGreen.png";
import searchIcon from "../../../assets/image/searchIcon.png";
import bigXIcon from "../../../assets/image/bigXIcon.png";
import sampleBuild from "../../../assets/image/sampleBuild.png";
import duplicateBuild from "../../../assets/image/additional-plus.png";
import defaultPotImage from "../../../assets/image/containers/PotProPot.png";
import defaultIrrigationImage from "../../../assets/image/default-irrigation.jpg";
import defaultDensityImage from "../../../assets/image/recommended-builds/FloraCap_12plant.png";
import defaultPotMedium from "../../../assets/image/default-pot-medium.jpg";

import shareSvg from "../../../assets/svg/share-no-shadow.svg";

import * as roomFunctions from "./../KitBuilder/KitRoom/KitRoomFunctions";
import { parseHtmlAndOther } from "../../utils/utilFunctions";

function PopupSave(props) {
    const [popupState, setPopupState] = React.useState({
        roomName: "Copy of " + props.roomName,
        roomDescription: props.roomDescription,
        room: props.room,
        clientID: props.clientID,
        errorName: false,
        loadingNutrients: false,
    });

    function onChangeRoomName(event) {
        setPopupState({
            ...popupState,
            roomName: event.target.value,
        });
    }
    function onChangeRoomDescription(event) {
        setPopupState({
            ...popupState,
            roomDescription: event.target.value,
        });
    }

    function onSave() {
        // props.onClose();
        if (popupState.roomName !== "") {
            setPopupState({
                ...popupState,
                errorName: false,
            });
            let new_room = popupState.room.room_data;
            new_room.room_name = popupState.roomName;
            new_room.room_description = popupState.roomDescription;

            axios
                .post(`${window.BASE_URL}rbapi/duplicateRoom.php`, {
                    customer_id: popupState.clientID,
                    room_name: popupState.roomName,
                    room_description: popupState.roomDescription,
                    room_data: new_room,
                })
                .then((res) => {
                    props.onClose();
                    document.location.reload();
                })
                .catch((error) => {
                    console.log(error);
                    props.onClose();
                });
        } else {
            setPopupState({
                ...popupState,
                errorName: true,
            });
        }
    }

    function onClose() {
        props.onClose();
    }

    return (
        <>
            <div className="input-pop-cstm">
                <input
                    name="roomName"
                    type="text"
                    value={popupState.roomName}
                    onChange={onChangeRoomName}
                    placeholder="Enter room name.."
                    error={popupState.errorName ? "yes" : "no"}
                />
            </div>

            <div className="input-pop-cstm">
                <textarea
                    name="roomDescription"
                    type="text"
                    value={
                        popupState.roomDescription !== null
                            ? popupState.roomDescription
                            : ""
                    }
                    onChange={onChangeRoomDescription}
                    placeholder="Add a build description.."
                />
            </div>
            <div className="modal-buttons">
                <Button
                    variant="secondary"
                    onClick={() => {
                        onClose();
                    }}
                >
                    CANCEL
                </Button>
                <Button
                    variant="primary"
                    onClick={() => {
                        onSave();
                    }}
                >
                    {" "}
                    SAVE
                </Button>
            </div>
        </>
    );
}

let buildListOriginalData = [];

export default function Dashboard(props) {
    const dispatch = useDispatch();
    const client = useApolloClient();

    // const {id_room} = useParams();

    const [state, setState] = React.useState({
        buildHistoryListTmp: [],
        buildHistoryList: [],
        searchValue: "",
    });
    const [popupState, setPopupState] = React.useState({});
    /**
     * used for triggering an action after loader was rendered (to avoid setLoader off render trigger before setLoader on in cas request is faster than render )
     */
    const [loaderState, setLoaderState] = React.useState({
        key: undefined,
        action: undefined,
        callback: undefined,
    });

    const { clientObj, clientFullName, clientID } = useSelector(({ auth }) => {
        return {
            clientFullName:
                auth.user && auth.user.firstname && auth.user.lastname
                    ? `${auth.user.firstname} ${auth.user.lastname}`
                    : "JOHN DOE",
            clientID: auth.user && auth.user.clientId ? auth.user.clientId : 3,
            clientObj: auth.user != null ? auth.user : [],
        };
    });

    function logout() {
        authenticaionActions(dispatch).logout(client);
    }

    function duplicateRoom(room, room_name, room_description) {
        openPopup({
            content: {
                title: `Duplicating this build will create an editable copy of "${room_name}"`,
                text:
                    "After duplicating you can rename or make changes to modify your build.",
            },
            buttonYes: "DUPLICATE",
            buttonNo: "CANCEL",
            className: "save-popup-",
            callbackYes: (closePopup) => {
                closePopup();
                setTimeout(() => {
                    setPopupState({
                        popupKey: Date.now(),
                        open: true,
                        roomName: room_name,
                        roomDescription: room_description,
                        room: room,
                        clientID: clientID,
                    });
                }, 600);
            },
        });
    }
    function openPopupDuplicate() {
        layoutActions(dispatch).pushPopupStack({
            key: "SAVE_ROOM_BUILD_POPUP_DUPLICATE",
            title: "text",

            content: {
                title: `SAVE YOUR ROOM`,
                text:
                    "Name and save your build to your account to allow you to access your build details in the future in “My Builds”.",
                node: (
                    <PopupSave
                        closePopup={() =>
                            layoutActions(dispatch).popPopupStack({
                                key: "SAVE_ROOM_BUILD_POPUP_DUPLICATE",
                            })
                        }
                        roomName={popupState.roomName}
                        roomDescription={popupState.roomDescription}
                        room={popupState.room}
                        clientID={clientID}
                        onChangeRoomName={(event) => {
                            setPopupState({
                                ...popupState,
                                roomName: event.target.value,
                            });
                        }}
                        onClose={() => {
                            layoutActions(dispatch).popPopupStack({
                                key: "SAVE_ROOM_BUILD_POPUP_DUPLICATE",
                            });
                        }}
                    />
                ),
            },
            className: "wrapper-save-room-popup-rm-your",
            callbackYes: (closePopup) => {
                closePopup();
            },
            callbackNo: (closePopup) => {
                closePopup();
            },
        });
    }
    function removeRoom(room_id, index, room_name) {
        if (room_id !== undefined && room_id !== "") {
            openPopup({
                content: {
                    title: `Are you sure you want to delete "${room_name}"?`,
                    text:
                        "This build will be deleted immediately. You can’t undo this action.",
                },
                buttonYes: "DELETE",
                buttonNo: "CANCEL",
                callbackYes: (closePopup) => {
                    axios
                        .post(`${window.BASE_URL}rbapi/removeRoom.php`, {
                            room_id: room_id,
                            customer_id: clientID,
                        })
                        .then((res) => {
                            let all_rooms = state.buildHistoryList;
                            all_rooms.splice(index, 1);

                            setState({
                                ...state,
                                buildHistoryList: all_rooms,
                            });
                            /**
                             * used when redirecting to kitbuilder - edit room
                             * buildHistoryList and buildListOriginalData must correspond
                             * this is sync and has to meaning for the rendering
                             */
                            buildListOriginalData.splice(index, 1);

                            closePopup();
                        })
                        .catch((error) => {
                            console.log(error);
                            closePopup();
                        });
                },
            });
        }
    }

    function openPopup(payload) {
        let key = Date.now();
        layoutActions(dispatch).pushPopupStack({
            key,
            title: "",
            callbackNo: (closePopup) => {
                closePopup();
            },
            ...payload,
        });
    }

    function searchOnChange(value) {
        let resultRooms = state.buildHistoryList;
        if (value != "") {
            resultRooms = resultRooms.filter(
                (item) => item.label.indexOf(value) !== -1
            );
            setState({
                ...state,
                buildHistoryListTmp: resultRooms,
                searchValue: value,
            });
        } else {
            setState({
                ...state,
                buildHistoryListTmp: state.buildHistoryList,
                searchValue: value,
            });
        }
    }

    const getQuery = () => {
        if (typeof window !== "undefined") {
            return new URLSearchParams(window.location.search);
        }
        return new URLSearchParams();
    };
    const getQueryStringVal = (key) => {
        return getQuery().get(key);
    };
    function changeUrlWithoutReload() {
        // const query = getQuery();
        // // if (newVal.trim() !== "") {
        // //   query.set("id_room", "");
        // // } else {
        // // }
        // query.delete("id_room");
        // // This check is necessary if using the hook with Gatsby
        // if (typeof window !== "undefined") {
        //   const { protocol, pathname, host } = window.location;
        //   const newUrl = `${protocol}//${host}${pathname}?${query.toString()}${window.location.hash}`;
        //   window.history.pushState({}, "", newUrl);
        // }
    }

    function reorderNutrients(room) {
        setState({
            ...state,
            loadingNutrients: true,
        });
        let nutrients = room?.nutrients;
        let cartID;
        if (nutrients) {
            client
                .query({
                    query: gql`
                        {
                            customerCart {
                                id
                            }
                        }
                    `,
                })
                .then((result) => {
                    cartID = result?.data?.customerCart?.id;
                    if (cartID) {
                        client
                            .mutate({
                                mutation: gql`
              mutation {
                addProductsToCart(
                  cartId: "${cartID}"
                  cartItems: [
                    ${nutrients.reduce(
                        (final, current) =>
                            final +
                            " " +
                            `
                            {
                              quantity: ${current.quantity}
                              sku: "${current.sku}"
                            }`,
                        ""
                    )}
                  ]
                ) {
                  cart {
                    items {
                      id
                      product {
                        name
                        sku
                      }
                      quantity
                    }
                  }
                }
              }
              `,
                            })
                            .then((result) => {
                                const cartItemsClient =
                                    result?.data?.addProductsToCart?.cart
                                        ?.items;

                                const cartAnchorNode = document.getElementById(
                                    "checkout-link-nutrients-dash"
                                );
                                if (cartAnchorNode) {
                                    cartAnchorNode.click();
                                }
                                setState({
                                    ...state,
                                    loadingNutrients: false,
                                });
                            })
                            .catch((errorWhileAddingToCart) => {
                                setState({
                                    ...state,
                                    loadingNutrients: false,
                                });
                            });
                    }
                })
                .catch((error) => {
                    console.log("ERROR WHILE GETTING CART ID", { error });
                    setState({
                        ...state,
                        loadingNutrients: false,
                    });
                });
        } else {
            setState({
                ...state,
                loadingNutrients: false,
            });
        }
    }

    function calculateNumOfPlants({ trays, calculatorState }) {
        if (
            !Array.isArray(trays) ||
            !calculatorState ||
            typeof calculatorState !== "object"
        ) {
            return 0;
        }
        let fourAreas = 0;
        trays.forEach((item) => {
            if (typeof item === "object") {
                const val1 = Number(item.widthVal) || 0;
                const val2 = Number(item.lengthVal) || 0;
                const maxDimension = val1 > val2 ? val1 : val2;
                if (maxDimension >= 4) {
                    fourAreas += Math.floor(maxDimension / 4);
                }
            }
        });

        let plant = Number(calculatorState.plant) || 0;
        /**
         * retrieve number from label (fix cases where value column was omited from db fsr and room was wrongly saved)
         */
        if (plant <= 0) {
            if (typeof calculatorState.plantLabel === "string") {
                const labelMatch = calculatorState.plantLabel.match(/([\d]+)/);
                if (
                    Array.isArray(labelMatch) &&
                    labelMatch.length > 0 &&
                    labelMatch[0] &&
                    Number(labelMatch[0])
                ) {
                    plant = Number(labelMatch[0]);
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
        }

        return fourAreas * (Number(plant) || 1);
    }

    useEffect(() => {
        if (popupState.popupKey) openPopupDuplicate();
    }, [popupState.popupKey]);

    function getData() {
        if (loaderState.open && loaderState.action === "getData") {
            return;
        }
        setLoaderState({
            key: Date.now(),
            open: true,
            action: "getData",
            callback: () => {
                fetch(
                    `${window.BASE_URL}rbapi/getRooms.php?customer_id=${clientID}`
                )
                    .then((response) => {
                        if (response.ok) {
                            return response.json();
                        }
                    })
                    .then((data) => {
                        let urlParams = new URL(window.location).searchParams;
                        const id_room = urlParams.get("room_id");
                        const query = getQuery();

                        // query.delete("room_id");

                        // This check is necessary if using the hook with Gatsby
                        if (typeof window !== "undefined") {
                            const {
                                protocol,
                                pathname,
                                host,
                            } = window.location;
                            const newUrl = `${protocol}//${host}${pathname}?${query.toString()}${
                                window.location.hash
                            }`;
                            window.history.pushState({}, "", newUrl);
                        }

                        if (data.length > 0) {
                            let alpha = "a";
                            let historyList = [];
                            data.forEach(function (item, index) {
                                // if(room_id_url)

                                let traysList = [];
                                let dateBuild = item.created_at
                                    ? item.created_at
                                          .substr(0, 11)
                                          .replaceAll("-", "/")
                                    : "";
                                if (
                                    dateBuild &&
                                    dateBuild.split("/").length > 2
                                ) {
                                    dateBuild = dateBuild.split("/");
                                    if (typeof dateBuild[2] === "string") {
                                        dateBuild[2] = dateBuild[2].replace(
                                            /\s/g,
                                            ""
                                        );
                                    }
                                    dateBuild = `${dateBuild[1]}/${dateBuild[2]}/${dateBuild[0]}`;
                                }
                                if (!item.room_data) {
                                    return;
                                }

                                let trays = Array.isArray(item.room_data.trays)
                                    ? item.room_data.trays
                                    : [];

                                Object.keys(trays).forEach(function (key) {
                                    traysList.push({
                                        key: String.fromCharCode(
                                            alpha.charCodeAt(0) + parseInt(key)
                                        ).toUpperCase(),
                                        trayValue:
                                            trays[key].tray_width +
                                            " X " +
                                            trays[key].tray_length,
                                    });
                                });

                                let uom = "FT";
                                let uom_tmp = item?.room_data?.uom;
                                let roomLengthDisplay =
                                    item?.room_data?.room_size?.length || 0;
                                let roomWidthDisplay =
                                    item?.room_data?.room_size?.width || 0;

                                if (uom_tmp != null && uom_tmp == "meters") {
                                    uom = "M";
                                    roomLengthDisplay =
                                        item?.room_data?._reactObjs?.roomSize
                                            ?.roomLengthVal;
                                    roomWidthDisplay =
                                        item?.room_data?._reactObjs?.roomSize
                                            ?.roomWidthVal;
                                }
                                const roomDimensions =
                                    roomLengthDisplay && roomWidthDisplay
                                        ? `${roomWidthDisplay} X ${roomLengthDisplay} ${uom}`
                                        : "";
                                const _reactObjs =
                                    item?.room_data?._reactObjs &&
                                    typeof item?.room_data?._reactObjs ===
                                        "object"
                                        ? item?.room_data?._reactObjs
                                        : {};

                                const growAreaId =
                                    Number(_reactObjs?.growArea?.id) || 0;
                                /**
                                 * @typedef {Object} IPlatform
                                 * @property {String} platformDrainageDirection
                                 * @property {Number} platformLinkLength
                                 * @property {Number} platformNumRows
                                 * @property {Number} platformPlatformsPerRow
                                 * @property {Number} platformSubzoneWidth
                                 * @property {Number} platformWalkwayWidth
                                 * @property {Number} platformXcoord
                                 * @property {Number} platformYcoord
                                 */
                                const platformDataAux =
                                    typeof _reactObjs.platformData ===
                                        "object" && _reactObjs.platformData
                                        ? _reactObjs.platformData
                                        : {};
                                const platformData = {
                                    platformLinkLength:
                                        Number(
                                            platformDataAux.platformLinkLength
                                        ) || 1,
                                    platformNumRows:
                                        Number(
                                            platformDataAux.platformNumRows
                                        ) || 1,
                                    platformPlatformsPerRow:
                                        Number(
                                            platformDataAux.platformPlatformsPerRow
                                        ) || 1,
                                    platformSubzoneWidth:
                                        Number(
                                            platformDataAux.platformSubzoneWidth
                                        ) || 1,
                                    platformWalkwayWidth:
                                        Number(
                                            platformDataAux.platformWalkwayWidth
                                        ) || 1,
                                    platformXcoord:
                                        Number(
                                            platformDataAux.platformXcoord
                                        ) || 1,
                                    platformYcoord:
                                        Number(
                                            platformDataAux.platformYcoord
                                        ) || 1,
                                };

                                const traysDisplayValue = traysList.map(
                                    (trayItem, indexTray) => {
                                        let traysHtml = "";
                                        if (indexTray != traysList.length - 1) {
                                            traysHtml +=
                                                trayItem.key +
                                                ": " +
                                                trayItem.trayValue +
                                                ", ";
                                        } else {
                                            traysHtml +=
                                                trayItem.key +
                                                ": " +
                                                trayItem.trayValue;
                                        }
                                        return (
                                            <span
                                                key={`${trayItem.label}-tray-${indexTray}`}
                                            >
                                                {traysHtml}
                                            </span>
                                        );
                                    }
                                );

                                const totalPlantFlow1 =
                                    traysList.length === 0
                                        ? 0
                                        : calculateNumOfPlants(_reactObjs) ||
                                          item?.room_data?.numOfPlants ||
                                          1;

                                const totalPlantFlow2 =
                                    platformData.platformPlatformsPerRow *
                                    platformData.platformNumRows;

                                const irrigationStyle =
                                    typeof item?.room_data?.irrigation ===
                                    "string"
                                        ? item?.room_data?.irrigation
                                        : "";

                                const densityForDisplay = roomFunctions.generateDisplayDensity(
                                    item?.room_data
                                );

                                const plants4x4Image = item?.room_data
                                    ?.densityImage
                                    ? item?.room_data?.densityImage
                                    : defaultDensityImage;

                                const irrigationStyleImage =
                                    item?.room_data?.irrigationImage ||
                                    defaultIrrigationImage;

                                const potOrMediumImage = item?.room_data
                                    ?.chosenPot?.image
                                    ? item?.room_data?.chosenPot?.image
                                    : "";

                                const potOrMedium =
                                    item?.room_data?.chosenPot?.label;

                                const room3dResult =
                                    clientID === 5903 || clientID === 5912
                                        ? ""
                                        : `${window.BASE_URL}rooms/${
                                              item?.id
                                          }/3D/image.png?v=${
                                              item.updated_at
                                          }${Date.now()}`;

                                /**
                                 * columns
                                 */
                                let columns;
                                if (growAreaId === 1) {
                                    columns = {
                                        2: {
                                            label: `ROWS`,
                                            value: (
                                                <div className="values-item-row-build">
                                                    <div className="value-trays">
                                                        {
                                                            platformData.platformNumRows
                                                        }
                                                    </div>
                                                </div>
                                            ),
                                        },
                                        1: {
                                            value: totalPlantFlow2,
                                        },
                                        4: {
                                            label: `POTPRO LINK LENGTH`,
                                            value: (
                                                <div className="values-item-row-build -line-overflow-elipsis">{`${platformData.platformLinkLength}${uom}`}</div>
                                            ),
                                        },
                                        5: {
                                            label: `SUB-ZONE WIDTH`,
                                            value: (
                                                <div className="values-item-row-build -line-overflow-elipsis">{`${platformData.platformSubzoneWidth}${uom}`}</div>
                                            ),
                                        },
                                    };
                                } else {
                                    columns = {
                                        2: {
                                            label: `TRAY SIZE(S)`,
                                            value: (
                                                <div className="values-item-row-build">
                                                    <span className="counter-trays">
                                                        {traysList.length}
                                                    </span>
                                                    <div className="value-trays">
                                                        {traysDisplayValue}
                                                    </div>
                                                </div>
                                            ),
                                        },
                                        1: {
                                            value: totalPlantFlow1,
                                        },
                                        4: {
                                            label: `IRRIGATION STYLE`,
                                            value: (
                                                <>
                                                    <div className="values-item-row-build -line-overflow-elipsis">
                                                        {irrigationStyle
                                                            ? parseHtmlAndOther(
                                                                  irrigationStyle.replace(
                                                                      "®",
                                                                      "<sup>®</sup>"
                                                                  )
                                                              )
                                                            : "-"}
                                                    </div>
                                                    <div className="image-item-row-build">
                                                        <img
                                                            style={{
                                                                visibility: irrigationStyle
                                                                    ? ""
                                                                    : "hidden",
                                                            }}
                                                            src={
                                                                irrigationStyleImage
                                                            }
                                                            alt={
                                                                irrigationStyle
                                                            }
                                                        />
                                                    </div>
                                                </>
                                            ),
                                        },
                                        5: {
                                            label: `PLANTS PER 4X4`,
                                            value: (
                                                <>
                                                    <div className="values-item-row-build">
                                                        {densityForDisplay ||
                                                            "-"}
                                                    </div>
                                                    <div className="image-item-row-build">
                                                        <img
                                                            style={{
                                                                visibility: densityForDisplay
                                                                    ? ""
                                                                    : "hidden",
                                                            }}
                                                            src={plants4x4Image}
                                                            alt="{item.densityForDisplay}"
                                                        />
                                                    </div>
                                                </>
                                            ),
                                        },
                                    };
                                }

                                historyList.push({
                                    key: item?.id,
                                    id: item?.id,
                                    label: item?.room_name,
                                    originalOwner: item.original_owner_id
                                        ? `${
                                              item.original_owner_firstname ||
                                              ""
                                          } ${
                                              item.original_owner_lastname || ""
                                          }`
                                        : "",
                                    description: item?.room_description,
                                    roomDimensions,
                                    totalPlantFlow1,
                                    traysList,
                                    potOrMedium,
                                    potOrMediumImage,
                                    irrigationStyle,
                                    irrigationStyleImage,
                                    plants4x4Image,
                                    dateBuild: dateBuild,
                                    room3dResult,
                                    cleanItem: item,
                                    nutrients: item?.room_data?.nutrients,
                                    buildComponents:
                                        item?.room_data?.build_components,
                                    densityForDisplay,
                                    traysDisplayValue,
                                    platformData,
                                    growAreaId,
                                    columns,
                                });
                                if (id_room === item?.id) {
                                    props.history.push({
                                        pathname: "/kitbuilder/room-dimensions",
                                        state: {
                                            detail: {
                                                ...roomFunctions.validatedRoomToEdit(
                                                    item
                                                ),
                                            },
                                        },
                                    });
                                }
                            });

                            setState({
                                ...state,
                                buildHistoryList: historyList,
                                buildHistoryListTmp: historyList,
                            });
                            /**
                             * used when redirecting to kitbuilder - edit room
                             * buildHistoryList and buildListOriginalData must correspond
                             * this is sync and has to meaning for the rendering
                             */
                            buildListOriginalData = data;
                        }
                    })
                    .catch((err) => {
                        console.log(
                            "Error while fetching build components",
                            err
                        );
                    })
                    .finally(() => {
                        setLoaderState({
                            open: false,
                        });
                    });
            },
        });
    }

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (
            loaderState.action &&
            loaderState.key &&
            typeof loaderState.callback === "function"
        ) {
            loaderState.callback();
        }
    }, [loaderState.key]);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const room_id_url = urlParams.get("room_id");

    return (
        <>
            <div className="dashboard-simple-page">
                <div className="header-dashboard">
                    <div className="header-button-group">
                        <div className="header-button">
                            <a
                                className="header-base-url"
                                href={`${window.BASE_URL}`}
                            >
                                FLORAFLEX.COM
                            </a>
                        </div>
                        <div className="header-button" onClick={() => logout()}>
                            LOGOUT
                        </div>
                    </div>
                </div>
                <div className="simple-page-content">
                    <div className="page-hero-top">
                        <div className="page-hero-logo">
                            <img src={greenLogo} alt="FloraFlex" />
                        </div>
                        <div className="page-hero-welcome generic-text-shadow">
                            WELCOME BACK, {clientFullName}.<br />
                            WHAT WOULD YOU LIKE TO DO?
                        </div>
                    </div>
                    <div className="dashboard-bar generic-box-shadow position-relative">
                        <div className="item-dash-bar">NEW BUILD</div>

                        <div className="item-dash-bar">MY BUILDS</div>
                        <div className="item-dash-bar" search="true">
                            <span className="generic-drop-shadow">
                                <img src={searchIcon} alt="search" />
                            </span>
                            <input
                                type="text"
                                name="dash-search-input"
                                placeholder="SEARCH BUILDS..."
                                value={state.searchValue}
                                onChange={(event) =>
                                    searchOnChange(event.target.value)
                                }
                            />
                            {/* <div className="position-absolute --left-pos --top-pos width-100 height-100 generic-box-shadow"></div> */}
                        </div>
                    </div>
                    <div className="dash-page-content">
                        <div className="left-dash-page-content">
                            <div className="new-build-container">
                                <div className="title-dash">
                                    START A NEW BUILD
                                </div>
                                <div
                                    className="new-build-btn"
                                    onClick={() =>
                                        props.history.push(
                                            "/kitbuilder/room-size"
                                        )
                                    }
                                ></div>
                            </div>
                        </div>
                        <div className="right-dash-page-content">
                            <div className="build-history-items">
                                {state.buildHistoryListTmp.map(
                                    (item, index) => {
                                        return (
                                            <div
                                                className="build-h-item"
                                                key={item.key}
                                            >
                                                <div className="left-build-h">
                                                    <div className="flex-start title-dash cursor-pointer">
                                                        <div
                                                            className="flex-1 -line-overflow-elipsis white-space-nowrap"
                                                            onClick={() => {
                                                                props.history.push(
                                                                    {
                                                                        pathname:
                                                                            "/kitbuilder/room-dimensions",
                                                                        state: {
                                                                            detail: {
                                                                                ...roomFunctions.validatedRoomToEdit(
                                                                                    item.cleanItem
                                                                                ),
                                                                            },
                                                                        },
                                                                    }
                                                                );
                                                            }}
                                                            title={item.id}
                                                        >
                                                            {" "}
                                                            {item.label}{" "}
                                                        </div>
                                                        {item.originalOwner && (
                                                            <div className="room-original-creator">
                                                                DESIGNER:{" "}
                                                                {
                                                                    item.originalOwner
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="build-h-item-details">
                                                        <div
                                                            className="row-build-h"
                                                            style={{
                                                                paddingBottom:
                                                                    "15px",
                                                            }}
                                                        >
                                                            <div className="item-row-build-h">
                                                                <div className="name-item-row-build">
                                                                    ROOM
                                                                    DIMENSIONS
                                                                </div>
                                                                <div className="values-item-row-build">
                                                                    {item.roomDimensions ||
                                                                        "-"}
                                                                </div>
                                                            </div>
                                                            <div className="item-row-build-h">
                                                                <div className="name-item-row-build">
                                                                    TOTAL PLANTS
                                                                </div>
                                                                <div className="values-item-row-build">
                                                                    {
                                                                        item
                                                                            .columns[1]
                                                                            .value
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="item-row-build-h last-row-build">
                                                                <div className="name-item-row-build">
                                                                    {
                                                                        item
                                                                            .columns[2]
                                                                            .label
                                                                    }
                                                                </div>
                                                                {
                                                                    item
                                                                        .columns[2]
                                                                        .value
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="row-build-h">
                                                            <div className="item-row-build-h">
                                                                <div className="name-item-row-build">
                                                                    POT/MEDIUM
                                                                </div>
                                                                <div className="values-item-row-build">
                                                                    {item.potOrMedium ||
                                                                        "-"}
                                                                </div>
                                                                <div className="image-item-row-build">
                                                                    {item.potOrMediumImage !==
                                                                        "" && (
                                                                        <img
                                                                            style={{
                                                                                visibility: item.potOrMedium
                                                                                    ? ""
                                                                                    : "hidden",
                                                                            }}
                                                                            src={
                                                                                item.potOrMediumImage
                                                                            }
                                                                            alt={
                                                                                item.potOrMedium
                                                                            }
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="item-row-build-h">
                                                                <div className="name-item-row-build">
                                                                    {
                                                                        item
                                                                            .columns[4]
                                                                            .label
                                                                    }
                                                                </div>
                                                                {
                                                                    item
                                                                        .columns[4]
                                                                        .value
                                                                }
                                                            </div>
                                                            <div className="item-row-build-h last-row-build">
                                                                <div className="name-item-row-build">
                                                                    {
                                                                        item
                                                                            .columns[5]
                                                                            .label
                                                                    }
                                                                </div>
                                                                {
                                                                    item
                                                                        .columns[5]
                                                                        .value
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="bottom-section-build-h">
                                                            <button
                                                                type="button"
                                                                className="buy-room-btn"
                                                                onClick={() => {
                                                                    props.history.push(
                                                                        {
                                                                            pathname:
                                                                                "/kitbuilder/room-dimensions",
                                                                            state: {
                                                                                detail: {
                                                                                    ...roomFunctions.validatedRoomToEdit(
                                                                                        item.cleanItem
                                                                                    ),
                                                                                },
                                                                            },
                                                                        }
                                                                    );
                                                                }}
                                                            >
                                                                VIEW ROOM
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="reorder-btn"
                                                                onClick={() =>
                                                                    reorderNutrients(
                                                                        item
                                                                    )
                                                                }
                                                            >
                                                                {state.loadingNutrients ? (
                                                                    <span></span>
                                                                ) : (
                                                                    "REORDER NUTRIENTS"
                                                                )}
                                                            </button>
                                                            <div className="date-build-h">
                                                                {item.dateBuild}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="right-build-h">
                                                    <div className="flex-end image-render-build-item">
                                                        {item.room3dResult !==
                                                        "" ? (
                                                            <img
                                                                src={
                                                                    item.room3dResult
                                                                }
                                                                alt="build"
                                                            />
                                                        ) : (
                                                            ""
                                                        )}
                                                    </div>
                                                    <div className="controls-img-rendered">
                                                        <div
                                                            className="flex-all cursor-pointer"
                                                            style={{
                                                                "margin-right":
                                                                    "20px",
                                                            }}
                                                            onClick={() => {
                                                                layoutActions(
                                                                    dispatch
                                                                ).openShareRoomPopup(
                                                                    JSON.stringify(
                                                                        item.cleanItem
                                                                    )
                                                                );
                                                            }}
                                                        >
                                                            <img
                                                                src={shareSvg}
                                                            />{" "}
                                                        </div>
                                                        <a
                                                            href={`${window.BASE_URL}roombuilder_pdf/index.php?room_id=${item.id}`}
                                                            target="_blank"
                                                        >
                                                            <span className="export-img-build">
                                                                EXPORT
                                                            </span>
                                                        </a>
                                                        <span
                                                            className="duplicate-img-build"
                                                            onClick={() =>
                                                                duplicateRoom(
                                                                    item.cleanItem,
                                                                    item.label,
                                                                    item.description
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src={
                                                                    duplicateBuild
                                                                }
                                                                alt="duplicate"
                                                            />
                                                        </span>
                                                        <span
                                                            className="remove-img-build"
                                                            onClick={() =>
                                                                removeRoom(
                                                                    item.id,
                                                                    index,
                                                                    item.label
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src={bigXIcon}
                                                                alt="remove"
                                                            />
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                )}

                                {!(
                                    loaderState.open &&
                                    loaderState.action === "getData"
                                ) &&
                                    state.buildHistoryListTmp.length === 0 && (
                                        <>
                                            <div className="empty-dash-hist">
                                                There are currently no builds...
                                            </div>
                                        </>
                                    )}
                            </div>
                            {loaderState.open &&
                                loaderState.action === "getData" && (
                                    <div
                                        className="flex-all width-100 page-spinner"
                                        style={{ flex: 1 }}
                                    >
                                        <Spinner
                                            animation="border"
                                            role="status"
                                        >
                                            <span className="sr-only">
                                                Loading...
                                            </span>
                                        </Spinner>
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
            </div>
            <a
                id="checkout-link-nutrients-dash"
                href={`${window.BASE_URL}checkout/cart`}
                target="_blank"
                style={{ display: "none" }}
            />
            <Popup />
        </>
    );
}
